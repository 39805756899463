import Slider from 'rc-slider';
import { ReactElement, useEffect, useState } from 'react';
import classes from './SeekBar.module.scss';

type Props = {
    currentTime: number,
    duration: number,
    onMouseDown: (e: any) => void,
    onMouseUp: (e: any) => void,
    onChange: (e: any) => void,
}

const SeekBar = (props: Props): ReactElement => {
    return (
        <>
            <Slider
                min={0}
                max={props.duration}
                value={props.currentTime}
                onBeforeChange={props.onMouseDown}
                onChange={props.onChange}
                onAfterChange={props.onMouseUp} />
        </>
    )
};

export default SeekBar;