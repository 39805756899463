import React, { PropsWithChildren, ReactElement } from 'react';
import logo from './../../assets/logo.svg';
import classes from './AdminLayout.module.scss';
import { AuthContext } from '../../../../contexts/AuthContext';
import Header from '../../_shared/Header/Header';
import Footer from '../../_shared/Footer/Footer';
import AdminNavBar from './AdminNavBar/AdminNavBar';
import { Outlet } from 'react-router-dom';
import UnauthorizedContent from '../../_shared/UnauthorizedContent/UnauthorizedContent';

const AdminLayout = () => {

  const { authenticated, isAdmin } = React.useContext(AuthContext);

  return (
    <>
      <Header featuredHeader={true} />
      {(authenticated && isAdmin) ? (
        <div className={classes.adminLayout}>
          <AdminNavBar />
          <div className={`${classes.interBody} AdminWrapper`}>
            <Outlet />
          </div>
        </div>
      ) : (
        <div className={`${classes.interBody} AdminWrapper unauthorized`} style={{ marginTop: "100px" }}>
          <UnauthorizedContent />
        </div>
      )}

      <Footer />
    </>
  );
}

export default AdminLayout;
