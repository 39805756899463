import { useContext } from 'react';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { PlayerContext } from '../../contexts/PlayerContext';
import { PlayerProvider } from '../../providers/PlayerProvider';
import AdminLayout from '../components/_layouts/AdminLayout/AdminLayout'
import MainLayout from '../components/_layouts/MainLayout/MainLayout';
import About from '../pages/About/About';
import AdminBlog from '../pages/Admin/AdminBlogs/AdminBlog/AdminBlog';
import AdminBlogCreate from '../pages/Admin/AdminBlogs/AdminBlogCreate/AdminBlogCreate';
import AdminBlogs from '../pages/Admin/AdminBlogs/AdminBlogs';
import AdminChartTopper from '../pages/Admin/AdminChartTopper/AdminChartTopper';
import AdminContentPages from '../pages/Admin/AdminContentPages/AdminContentPages';
import AdminDashboard from '../pages/Admin/AdminDashboard/AdminDashboard';
import AdminDiscovery from '../pages/Admin/AdminDiscovery/AdminDiscovery';
import AdminPodcastAnalytics from '../pages/Admin/AdminPodcastAnalytics/AdminPodcastAnalytics';
import AdminPodcasts from '../pages/Admin/AdminPodcasts/AdminPodcasts';
import AdminUsers from '../pages/Admin/AdminUsers/AdminUsers';
import Blog from '../pages/Blog/Blog';
import Blogs from '../pages/Blogs/Blogs';
import Contact from '../pages/Contact/Contact';
import Discover from '../pages/Discover/Discover';
import Episode from '../pages/Episode/Episode';
import ForPodcasters from '../pages/ForPodcasters/ForPodcasters';
import Home from '../pages/Home/Home';
import Legal from '../pages/Legal/Legal';
import MyPodcast from '../pages/MyPodcast/MyPodcast';
import Podcast from '../pages/Podcast/Podcast';
import Settings from '../pages/Settings/Settings';

export default () => {

    const { isPlayerOpen } = useContext(PlayerContext);

    const NotFound = () => {
        return (
            <>
                <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">
                    <h4>Page Not Found</h4>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={`${isPlayerOpen ? 'player-open' : ''} d-flex flex-grow-1 flex-column`}>

                <Routes>
                    {/* Main Routes */}
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home />} />
                        <Route path='podcast/:uid' element={<Podcast />} />
                        <Route path='episode/:uid' element={<Episode />} />
                        <Route path='my-podcasts' element={<MyPodcast />} />
                        <Route path='discover' element={<Discover />} />
                        <Route path='settings' element={<Settings />} />

                        <Route path='about-faq' element={<About />} />
                        <Route path='for-podcasters' element={<ForPodcasters />} />
                        <Route path='contact' element={<Contact />} />
                        <Route path='blogs' element={<Blogs />} />
                        <Route path='blog/:id/:title' element={<Blog />} />
                        <Route path='legal' element={<Legal />} />
                    </Route>

                    {/* Admin Routes */}
                    <Route path='/admin' element={<AdminLayout />}>
                        <Route index element={<AdminDashboard />} />
                        <Route path='discovery' element={<AdminDiscovery />} />
                        <Route path='chart-topper' element={<AdminChartTopper />} />
                        <Route path='podcasts' element={<AdminPodcasts />} />
                        <Route path='podcast/:uid' element={<AdminPodcastAnalytics />} />
                        <Route path='users' element={<AdminUsers />} />
                        <Route path='blogs' element={<AdminBlogs />} />
                        <Route path='blogs/create' element={<AdminBlogCreate />} />
                        <Route path='blog/:id' element={<AdminBlog />} />
                        <Route path='pages' element={<AdminContentPages />} />
                    </Route>

                    {/* Page not found */}
                    <Route path="*" element={<MainLayout />} >
                        <Route index element={<NotFound />} />
                    </Route>

                </Routes>
            </div>
        </>
    )

}
