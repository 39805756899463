import { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import ContentService from '../../../../../services/content.service';
import ContentEditor from '../../../../components/_shared/ContentEditor/ContentEditor';
import classes from './PageEditSection.module.scss';
import { toast } from 'react-toastify';


const PageEditSection = (props: { page: string, content?: any }) => {

    const [content, setContent] = useState();

    const save = async () => {
        const response = await ContentService.save({ type: props.page, content: content || "" })
        toast("Content updated successfully!", { type: 'success' });
    }

    return (
        <>
            <div className={`interPageWhite ps-md-0`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column flex-grow-1">
                        <ContentEditor defaultValue={props.content?.content} onContentChange={setContent} />

                        <button className='btn btn-lg btn-secondary w-25 align-self-end' onClick={save}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PageEditSection;