import React, { ReactElement, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { AuthContext, AuthModalViews } from '../../../../../contexts/AuthContext';
import { LoadingContext } from '../../../../../contexts/LoadingContext';
import AuthService from '../../../../../services/auth.service';
import classes from './LoginModal.module.scss';

type Props = {
    data: {
        handleClose: () => void
    }
}

const LoginModal = (props: Props): ReactElement => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const { showLoader } = useContext(LoadingContext);

    const { handleClose } = props.data;
    const {
        setAuthModalView,
        setToken,
        setRefreshToken
    } = React.useContext(AuthContext);

    const onSubmit = async (data: any) => {

        const authorized = await AuthService.login(data);
        if (!authorized || (authorized && !authorized.idToken)) {
            setError('password', { type: 'invalidPassword' });
            return;
        }

        showLoader(true);
        setToken(authorized.idToken);
        setRefreshToken(authorized.refreshToken);
        handleClose();
        toast("You have successfully logged in.", { type: 'success' });
        showLoader(false);
    }

    return (
        <div className={classes.loginModal}>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">

                    <div className="modal-header">
                        <div className="modal-title h5">Login</div>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                        </button>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <input
                                type="email"
                                placeholder="Enter email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: ""
                                    }
                                })}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && errors.email.type === "required" && <span>This field is required</span>}
                                {errors.email && errors.email.type === "pattern" && <span>Entered value does not match email format</span>}
                            </Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <input
                                type="password"
                                placeholder="Password"
                                {...register('password', { required: true })}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`} />

                            <Form.Control.Feedback type="invalid">
                                {errors.password && errors.password.type === "required" && <span>This field is required</span>}
                                {errors.password && errors.password.type === "invalidPassword" && <span>Invalid email or password!</span>}
                            </Form.Control.Feedback>

                        </Form.Group>

                        <div className='modal-footer pt-0'>
                            <Button variant="secondary" type='submit'>
                                Login
                            </Button>
                            <p className="fw-300">New to Intercast.fm?  <a onClick={() => setAuthModalView(AuthModalViews.REGISTER)} className="fw-500 interText-link">Sign-up</a></p>
                            <p className="fw-300">Forgot your password? <a onClick={() => setAuthModalView(AuthModalViews.FORGOT)} className="fw-500 interText-link">Reset Password</a></p>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
};

export default LoginModal;