import ReactHtmlParser from 'react-html-parser';
import classes from './BlogView.module.scss';

type BlogViewProps = {
    blog: any
}

const BlogView = ({ blog }: BlogViewProps) => {
    return (
        <>
            <div className={classes.blogView}>
                {ReactHtmlParser(blog.content)}
            </div>
        </>
    )
};

export default BlogView;