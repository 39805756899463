import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import CustomLink from '../../../components/_shared/CustomLink/CustomLink';
import classes from './AdminBlogs.module.scss';
import { useNavigate } from 'react-router-dom';
import BlogService from '../../../../services/blog.service';
import { LoadingContext } from '../../../../contexts/LoadingContext';
import { toast } from 'react-toastify';
import { Trash } from 'react-bootstrap-icons';

const AdminBlogs = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const { showLoader } = useContext(LoadingContext);

    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState<any[]>([]);
    const [showAddModal, setShowAddModal] = useState(false);

    const [deletingBlogId, setDeletingBlogId] = useState<number | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const redirectTo = (path: string) => {
        navigate(path);
    }

    const fetchBlogs = async () => {
        showLoader(true);
        const blogsList = await BlogService.getAll();
        setBlogs(blogsList);
        showLoader(false);
        setLoading(false);
    }

    const onSubmit = async (data: any) => {
        showLoader(true);
        const blog = await BlogService.save(data);
        redirectTo(`/admin/blog/${blog.id}`);
    }

    const publishBlog = async (blog: any, isPublished = true) => {
        blog.isPublished = isPublished;
        await BlogService.update(blog);
        toast(isPublished ? "Blog published successfully!" : "Blog unpublished successfully!", { type: 'success' });
        fetchBlogs();
    }

    const openDeleteConfirmation = (deleteBlogId: number) => {
        setDeletingBlogId(deleteBlogId);
        setShowDeleteModal(true)
    }

    const deleteBlog = async () => {
        showLoader(true);
        await BlogService.delete(deletingBlogId);
        setDeletingBlogId(null);
        setShowDeleteModal(false);
        fetchBlogs();
        showLoader(false);
    }

    useEffect(() => {
        if (loading) { return; }
        fetchBlogs();
    }, []);

    return (
        <>
            <div className={`interPageWhite ps-md-0 ${classes.blogs}`}>

                <div className='d-flex'>
                    <h3>Blogs</h3>
                    <Button variant="secondary" type='submit' className={classes.addButton} onClick={() => setShowAddModal(true)}>
                        Create New
                    </Button>
                </div>

                {blogs.map((blog: any, index: number) => (
                    <div className="row pt-4" key={index}>
                        <div className="col-md-4 col-lg-3">
                            <h4 className="interBodySubTitle">{!blog.isPublished && <span>[DRAFT] </span>}{blog.title}</h4>
                            {blog.author && <h5 className="interTextGray fw-normal">By {blog.author}</h5>}
                        </div>
                        <div className="col-md-8 col-lg-9 mb-4">
                            <p className="interTextGray">"{blog.description}"</p>
                            <CustomLink to={`/admin/blog/${blog.id}`} activeClass={classes.leftNavItemActive}>
                                <button className="btn btn-success btn-h-28">View/Edit Article</button>
                            </CustomLink>
                            {!blog.isPublished && <button className={`btn btn-secondary  btn-h-28`} style={{ marginLeft: "10px" }} onClick={() => publishBlog(blog)}>Publish</button>}
                            {blog.isPublished && <button className={`btn btn-secondary  btn-h-28`} style={{ marginLeft: "10px" }} onClick={() => publishBlog(blog, false)}>Unpublish</button>}


                            <button className={`btn btn-danger btn-h-28`} style={{ marginLeft: "20px" }} onClick={() => openDeleteConfirmation(blog.id)}><Trash /></button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Add Modal */}
            <Modal show={showAddModal} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="interDarkModal">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="modal-header">
                                    <div className="modal-title h5">CREATE NEW BLOG</div>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowAddModal(false)}>
                                    </button>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicTitle">
                                    <input type="text" placeholder="Title" className={`form-control ${errors.title ? 'is-invalid' : ''}`} {...register('title', {
                                        required: true
                                    })} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title && errors.title.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicAuthor">
                                    <input type="text" placeholder="Author" className={`form-control ${errors.author ? 'is-invalid' : ''}`} {...register('author')} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.author && errors.author.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicDescription">
                                    <textarea rows={4} placeholder="Description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} {...register('description', {
                                        required: true
                                    })}></textarea>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description && errors.description.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='modal-footer pt-0'>
                                    <div className='d-flex w-100'>
                                        <Button variant="secondary" type='submit' style={{ marginLeft: "auto" }}>
                                            Save & Continue...
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>

            {/* Delete confirm modal */}
            <Modal show={showDeleteModal} >
                <Modal.Header >
                    <Modal.Title>Delete Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this blog?</Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-light' onClick={(event) => {
                        setShowDeleteModal(false);
                    }}>
                        No
                    </button>
                    <button className='btn btn-danger' onClick={(event) => deleteBlog()}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
};

export default AdminBlogs;