import axios from "axios"

const BlogService = {
    save: async (formData: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/blog`, formData, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    update: async (formData: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.put(`/api/v1/blog/${formData.id}`, formData, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    getById: async (id: any) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.get(`/api/v1/blog/${id}`, config);
        return response.data;
    },

    getAll: async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.get(`/api/v1/blog/all`, config);
        return response.data;
    },

    getPublished: async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.get(`/api/v1/blog`, config);
        return response.data;
    },

    delete: async (id: any) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.delete(`/api/v1/blog/${id}`, config);
        return response.data;
    }

}

export default BlogService;