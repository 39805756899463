import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../../../contexts/AuthContext';
import ImageService from '../../../../../services/image.service';
import BackButton from '../../../../components/_shared/BackButton/BackButton';
import classes from './AdminPodcastDetailSection.module.scss';

type Props = {
    podcast: any
}

const AdminPodcastDetailSection = (props: Props): ReactElement => {

    const { authenticated } = React.useContext(AuthContext);

    const palletTypes = ["Vibrant", "LightVibrant", "DarkVibrant", "Muted", "LightMuted", "DarkMuted"];

    const { podcast } = props;

    const prevIdRef = useRef<string>();
    const [matchedColor, setMatchedColor] = useState();

    const fetchColor = async () => {
        let pallets = await ImageService.getMatchedColors(podcast.imageUrl);
        pallets = [pallets[palletTypes[0]], pallets[palletTypes[1]], pallets[palletTypes[2]]];
        const maxPopulatedPallet = Object.keys(pallets).map((key: string) => pallets[key]).reduce((prev, current) => (prev.population > current.population) ? prev : current)
        setMatchedColor(maxPopulatedPallet.rgb.join());
    }

    useEffect(() => {
        if (prevIdRef.current === podcast.id) { return; }
        fetchColor();
        prevIdRef.current = podcast.id;
    }, [podcast.id]);

    return (
        <>
            <BackButton to={`/admin/podcasts`} className={'text-muted'} />
            <div className={classes.podcastDetailSection} style={{ 'borderColor': matchedColor ? `rgb(${matchedColor})` : '' }}>
                <div className="bg-white  min-w-160 mobile-m-w-59">
                    <img src={podcast.imageUrl} width={160} loading="lazy" />
                </div>
                <div className={classes.podcastDetailSectionText}>
                    <h2 className="line-clamp-2 podcastDetailTitle">{podcast.title}</h2>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <p className="interTextSecondary flex-grow-1 mb-8 line-clamp-2">{podcast.author?.name}</p>
                            <p className="interTextSecondary flex-grow-1 mb-8 line-clamp-2">2034 Subscribers</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AdminPodcastDetailSection;