import React from 'react';

export type IPlayerContext = {
    playing: boolean;
    setPlaying: (playing: boolean) => void;

    muted: boolean;
    setMuted: (playing: boolean) => void;

    isPlayerOpen: boolean;
    setIsPlayerOpen: (isPlayerOpen: boolean) => void;

    currentPlayList: any[];
    setCurrentPlayList: (currentPlayList: any[]) => void;

    currentEpisode: any;
    setCurrentEpisode: (currentEpisode: any) => void;

    clearPlayer: () => void;

};

export const PlayerContext = React.createContext<IPlayerContext>({
    playing: true,
    setPlaying: () => { },
    muted: true,
    setMuted: () => { },
    isPlayerOpen: false,
    setIsPlayerOpen: () => { },
    currentPlayList: [],
    setCurrentPlayList: () => { },
    currentEpisode: null,
    setCurrentEpisode: () => { },
    clearPlayer: () => { },
});