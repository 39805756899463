import React, { ReactElement, useContext, useEffect, useState } from 'react';
import classes from './MyPodcast.module.scss';
import { LoadingContext } from '../../../contexts/LoadingContext';
import PodcastService from '../../../services/podcast.service';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { GeneralActionTypes } from '../../../store/actions/generalAction';
import { useDispatch } from 'react-redux';
import { useStoreHook } from '../../../store/hooks';
import BackButton from '../../components/_shared/BackButton/BackButton';
import { debounce } from 'lodash';

const MyPodcast = (): ReactElement => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showLoader } = useContext(LoadingContext);
    const { authenticated } = useContext(AuthContext);
    const { subscribedPodcastList } = useStoreHook();
    const [loading, setLoading] = useState(true);

    const [myPodcastPage, setMyPodcastPage] = useState<number>(1);
    const [myPodcasts, setMyPodcasts] = useState<any[]>([]);
    const [myUnplayedPodcasts, setMyUnplayedPodcasts] = useState<any[]>([]);
    const [paginating, setPaginating] = useState(false);

    const fetchData = async () => {
        showLoader(true);
        const myPodacstList = await PodcastService.getSubscribedPodcasts(myPodcastPage);
        const myUnplayedPodacstList = await PodcastService.getSubscribedUnplayedPodcasts(myPodcastPage);
        setMyPodcasts(myPodacstList);
        setMyUnplayedPodcasts(myUnplayedPodacstList);

        if (myPodacstList.length > 0) {
            setMyPodcastPage(myPodcastPage + 1)
        }

        setLoading(false);
        setPaginating(false);
        showLoader(false);
    }

    const pagingSubscribedPodcasts = async () => {
        setPaginating(true);
        showLoader(true);
        const myPodacstList = await PodcastService.getSubscribedPodcasts(myPodcastPage);
        if (myPodacstList.length > 0) {
            setMyPodcastPage(myPodcastPage + 1)
        }
        setMyPodcasts([...myPodcasts, ...myPodacstList]);
        setPaginating(false);
        showLoader(false);
    }

    const onSelectPodcast = (podcast: any) => {
        navigate(`/podcast/${podcast.id}`);
    }

    const toggleSubscribe = async (podcast: any) => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }
        dispatch({ type: GeneralActionTypes.SUBSCRIBE_PODCAST, payload: podcast.id });
    }

    const onScrollCheck = () => {
        const cal = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        const d = debounce(async () => {
            if (parseInt(cal.toString()) === 0 && !loading && !paginating) {
                await pagingSubscribedPodcasts();
            }
        }, 500);
        d();
    }

    const handleRemove = (id: string) => {
        const newMyUnplayedPodcasts = myUnplayedPodcasts.filter((item: any) => item.id !== id);
        const newMyPodcasts = myPodcasts.filter((item: any) => item.id !== id);
        setMyUnplayedPodcasts(newMyUnplayedPodcasts);
        setMyPodcasts(newMyPodcasts);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", onScrollCheck);
        return () => {
            window.removeEventListener("scroll", onScrollCheck);
        };
    });

    return (
        <>
            <div className={classes.myPodcast}>
                <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">

                    <BackButton to='/' />
                    {myUnplayedPodcasts.length > 0 && (
                        <>
                            <h3 className="interBodyTitle mb-4">My Podcasts With Unplayed Episodes</h3>
                            <div className="row">
                                {myUnplayedPodcasts.map((podcast: any, i: number) => (
                                    <div key={i} className="col-lg-4 col-6" onClick={(es: any) => {
                                        es.stopPropagation();
                                        onSelectPodcast(podcast);
                                    }}>
                                        <div className="interPodcastThumbnail">
                                            <div className={`bg-white ${classes.imageContainer}`}>
                                                <img className="img-fluid" src={podcast.imageUrl} loading="lazy" />
                                                <div className={`interPodcastThumbnail--status ${!subscribedPodcastList[podcast.id] ? 'success' : null}`} onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    toggleSubscribe(podcast);
                                                    handleRemove(podcast.id);
                                                }}>{subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}</div>
                                            </div>
                                            <div className="interPodcastThumbnail--details">
                                                <p className={`mb-0 line-clamp-1 ${classes.lastEpisodeDate}`}>
                                                    <span>
                                                        {podcast?.episodes?.data?.length > 0 ? podcast?.episodes?.data[0].title : ""}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <h3 className="interBodyTitle mb-4">All My Podcasts</h3>

                    <div className="row opacity-55">
                        {myPodcasts.map((podcast: any, i: number) => (
                            <div key={i} className="col-lg-4 col-6" onClick={(ee: any) => {
                                ee.stopPropagation();
                                onSelectPodcast(podcast);
                            }}>
                                <div className="interPodcastThumbnail">
                                    <div className={`bg-white ${classes.imageContainer}`}>
                                        <img className="img-fluid" src={podcast.imageUrl} loading="lazy" />
                                        <div className={`interPodcastThumbnail--status ${!subscribedPodcastList[podcast.id] ? 'success' : null}`} onClick={(e: any) => {
                                            e.stopPropagation();
                                            toggleSubscribe(podcast);
                                            handleRemove(podcast.id);
                                        }}>{subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}</div>
                                    </div>
                                    <div className="interPodcastThumbnail--details">
                                        <p className={`mb-0 line-clamp-1 ${classes.lastEpisodeDate}`}>
                                            <span>
                                                {podcast?.episodes?.data?.length > 0 ? podcast?.episodes?.data[0].title : ""}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
};

export default MyPodcast;