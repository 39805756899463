import classes from './AdminDiscovery.module.scss';
import cover from './../../../../assets/images/collection-box.jpg';
import AddDiscoverPodcast from './AddDiscoverPodcast/AddDiscoverPodcast';
import { useContext, useEffect, useState } from 'react';
import AddDiscoveryCollection from './AddDiscoveryCollection/AddDiscoveryCollection';
import CollectionService from '../../../../services/collection.service';
import { LoadingContext } from '../../../../contexts/LoadingContext';


const AdminDiscovery = () => {

    const { showLoader } = useContext(LoadingContext);

    const [featuredCollections, setFeaturedCollections] = useState<any[]>([]);
    const [collections, setCollections] = useState<any[]>([]);
    const [showAddFeatured, setShowAddFeatured] = useState(false);
    const [showAddCollection, setShowAddCollection] = useState(false);
    const [loading, setLoading] = useState(false);

    const afterAdd = (collection: any) => {
        if (collection.isFeatured) {
            const newCollList: any[] = [...featuredCollections];
            const index = newCollList.indexOf((h: any) => h.id == collection.id);
            if (index === -1) {
                newCollList.push(collection);
            } else {
                newCollList[index] = collection;
            }
            setFeaturedCollections(newCollList)
        } else {
            const newCollList: any[] = [...collections];
            const index = newCollList.indexOf((h: any) => h.id == collection.id);
            if (index === -1) {
                newCollList.push(collection);
            } else {
                newCollList[index] = collection;
            }
            setCollections(newCollList)
        }
    }

    const fetchCollections = async () => {
        setLoading(true);
        showLoader(true);
        const res = await CollectionService.getAll();
        const fea = res.filter((c: any) => c.isFeatured);
        const col = res.filter((c: any) => !c.isFeatured);
        setFeaturedCollections(fea)
        setCollections(col)
        setLoading(false);
        showLoader(false);
    }

    useEffect(() => {
        if (loading) { return; }
        fetchCollections();
    }, []);

    return (
        <>
            <div className={`interPageWhite ps-md-0 ${classes.discovery}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column mx-w-80 flex-grow-1">
                        <h3 className="interBodyTitle Inactive mt-3 mt-md-5 mb-4">Featured Collections</h3>

                        {featuredCollections.map((collection: any, i: number) => (
                            <AddDiscoveryCollection key={i} maxPodcastCount={5} collection={collection} featured={true} afterDelete={() => fetchCollections()} />
                        ))}
                        {showAddFeatured && <AddDiscoveryCollection maxPodcastCount={5} featured={true} afterAdd={afterAdd} cancel={() => setShowAddFeatured(false)} />}
                        {!showAddFeatured && (
                            <div>
                                <div className="interbox interbox--sm my-4" onClick={() => setShowAddFeatured(!showAddFeatured)}>
                                    <span className="interbox--plus">+</span>
                                </div>
                            </div>
                        )}

                        <h3 className="interBodyTitle Inactive mt-3 mb-4">Collections</h3>

                        {collections.map((collection: any, i: number) => (
                            <AddDiscoveryCollection maxPodcastCount={6} key={i} collection={collection} featured={false} afterDelete={() => fetchCollections()} />
                        ))}
                        {showAddCollection && <AddDiscoveryCollection maxPodcastCount={6} featured={false} afterAdd={afterAdd} cancel={() => setShowAddCollection(false)} />}
                        {!showAddCollection && (
                            <div>
                                <div className="interbox interbox--sm my-4" onClick={() => setShowAddCollection(!showAddCollection)}> <span className="interbox--plus">+</span> </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>

        </>
    )
};

export default AdminDiscovery;