import axios from "axios"

const AuthService = {
    login: async (formData: any) => {
        try {
            const response = await axios.post(`/api/v1/auth/signin`, formData);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    register: async (formData: any): Promise<{ success: boolean, data: any }> => {
        try {
            const response = await axios.post(`/api/v1/auth/signup`, formData);
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, data: error.response };
        }
    },

    reset: async (formData: any) => {
        try {
            const response = await axios.post(`/api/v1/auth/forgot-password`, formData);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to Reset password");
        }
    },

    update: async (formData: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/auth/password`, formData, config);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Failed to Reset password");
        }
    },

    refreshToken: async (refreshToken: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/auth/token`, {
                refreshToken
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to Refresh token");
        }
    },

    logout: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/auth/signout`, {}, config);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return response.data;
        } catch (error) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            console.error(error);
            throw new Error("Failed to Refresh token");
        }
    },

    changePassword: async (data: any) => {
        try {
            const response = await axios.post(`/api/v1/auth/forgot-password/verify`, data);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to change password");
        }
    }
}

export default AuthService;