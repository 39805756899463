import { useContext, useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { endOfWeek, format } from 'date-fns'
import { ACTIVE_USER_FILTER, TOP_PODCAST_PERIOD_FILTER, TOP_PODCAST_TYPE_FILTER } from '../../../../../const/adminFilters';
import classes from './AdminPodcastActiveUserChart.module.scss';
import { ChartDataType } from '../../../../../common/commonTypes';
import { getLast11Years, getLastMonths, getLast5Weeks, getLastDays, NumberFormatter } from '../../../../utils/utils';
import Select from 'react-select';
import AnalyticsService from '../../../../../services/analytics.service';
import { LoadingContext } from '../../../../../contexts/LoadingContext';

type Props = {
    uid: string,
    data: any[],
    afterDataSet: (data: ChartDataType[]) => void
}

const AdminPodcastActiveUserChart = ({ uid, data, afterDataSet }: Props) => {

    const typeFilterOptions = [
        { value: TOP_PODCAST_TYPE_FILTER.LISTENS, label: 'Listens' },
        { value: TOP_PODCAST_TYPE_FILTER.INTERACTION, label: 'Interactions' },
        { value: TOP_PODCAST_TYPE_FILTER.SHARES, label: 'Shares' },
        { value: TOP_PODCAST_TYPE_FILTER.SUBSCRIBERS, label: 'Subscribers' }
    ];

    const dayFilterOptions = [
        { value: TOP_PODCAST_PERIOD_FILTER.WEEK, label: 'Week' },
        { value: TOP_PODCAST_PERIOD_FILTER.MONTH, label: 'Month' },
        { value: TOP_PODCAST_PERIOD_FILTER.YEAR, label: 'Year' },
        { value: TOP_PODCAST_PERIOD_FILTER.ALL, label: 'All Time' }
    ];

    const { showLoader } = useContext(LoadingContext);
    const [initiated, setInitiated] = useState(false);
    const [chartData, setChartData] = useState<ChartDataType[]>([]);
    const [typeFilterValue, setTypeFilterValue] = useState(TOP_PODCAST_TYPE_FILTER.LISTENS);
    const [dateFilterValue, setDateFilterValue] = useState(TOP_PODCAST_PERIOD_FILTER.WEEK);

    const getCountFromData = (analyticsData: any[], date: Date) => {
        const availableData: { date: string, count: string } = analyticsData.find((d: { date: string, count: string }) => {
            return format(date, "yyyy-MM-dd") === format(new Date(d.date), "yyyy-MM-dd");
        });
        return availableData ? parseInt(availableData.count) : 0;
    };

    const getDataProps = (analyticsData: any[]): ChartDataType[] => {
        switch (dateFilterValue) {
            case TOP_PODCAST_PERIOD_FILTER.WEEK:
                const last8Days = getLastDays(7);
                return last8Days.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "MMM, do"),
                        value: getCountFromData(analyticsData, d)
                    }
                });
            case TOP_PODCAST_PERIOD_FILTER.MONTH:
                const last5Weeks = getLastDays(30);
                return last5Weeks.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "MMM/dd"),

                        value: getCountFromData(analyticsData, d)
                    }
                });
            case TOP_PODCAST_PERIOD_FILTER.YEAR:
                const last13Months = getLastMonths(12);
                return last13Months.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "LLL"),
                        value: getCountFromData(analyticsData, d)
                    }
                });
            case TOP_PODCAST_PERIOD_FILTER.ALL:
                const last11Years = getLast11Years();
                return last11Years.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "yyyy"),
                        value: getCountFromData(analyticsData, d)
                    }
                });

            default:
                return []
        }
    }

    const onFilterChange = async () => {
        showLoader(true);
        const analyticsDataResult = await AnalyticsService.getPoscastAnalyticsData(uid!, typeFilterValue, dateFilterValue.toLowerCase());
        processData(analyticsDataResult);
        showLoader(false);
    }

    const processData = (analyticsData: any[]) => {
        const dataPropsSet: ChartDataType[] = getDataProps(analyticsData);
        setChartData(dataPropsSet);
        afterDataSet(dataPropsSet);
    }

    useEffect(() => {
        if (initiated) { return; }
        processData(data);
        setInitiated(true);
    }, [data]);

    useEffect(() => {
        if (!initiated) { return; }
        onFilterChange();
    }, [typeFilterValue, dateFilterValue]);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.customToolTip}>
                    <p className={classes.label}>{`${label}`}</p>
                    <p className={classes.intro}>Active Users: {payload[0].value}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>

            <div className="d-flex align-items-center mt-4 mb-4">
                <h5 className="text-gray-100 text-nowrap">This podcast had</h5>

                <div className='d-flex w-100'>
                    <div className='w-50'>
                        <Select
                            placeholder="listens"
                            defaultValue={typeFilterOptions.filter(o => o.value === typeFilterValue)}
                            onChange={(f: any) => setTypeFilterValue(f.value)}
                            options={typeFilterOptions}
                            className="form-control--admin ms-2" />
                    </div>
                    <div className='w-50'>
                        <Select
                            placeholder="day"
                            defaultValue={dayFilterOptions.filter(o => o.value === dateFilterValue)}
                            onChange={(f: any) => setDateFilterValue(f.value)}
                            options={dayFilterOptions}
                            className="form-control--admin ms-2" />
                    </div>
                </div>
            </div>

            <div className={classes.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={400}
                        margin={{ top: 60, right: 60, left: 20, bottom: 20 }}
                        data={chartData}>
                        <Line type="monotone" dataKey="value" stroke="#53A7BF" activeDot={{ r: 8, }} />
                        <CartesianGrid stroke="#EBECF0" />
                        <XAxis dataKey="label" />
                        <YAxis label={{ value: 'Active Users', angle: -90, position: 'left' }} tickFormatter={NumberFormatter} />
                        <Tooltip content={<CustomTooltip />} />
                        <text x="10" y="10" dominantBaseline="hanging" fontSize="18">{dateFilterValue} Active Users</text>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default AdminPodcastActiveUserChart;
