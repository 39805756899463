import classes from './AdminUsers.module.scss';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useCallback, useContext, useEffect, useState } from 'react';
import UserService from '../../../../services/user.service';
import { formatDate } from '../../../utils/utils';
import { LoadingContext } from '../../../../contexts/LoadingContext';
import { debounce } from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStoreHook } from '../../../../store/hooks';


const AdminUsers = () => {


    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const { showLoader } = useContext(LoadingContext);
    const { loggedUser } = useStoreHook();

    const [changePasswordUser, setChangePasswordUser] = useState<any>(null);
    const [changePasswordModal, setChangePasswordModal] = useState(false);

    const toggleAdmin = async (user: any) => {
        showLoader(true);
        if (user.Role.role === 'user') {
            await UserService.makeAdmin(user.id);
            user.Role.role = "admin";
        } else {
            await UserService.revertAdmin(user.id);
            user.Role.role = "user";
        }
        showLoader(false);
    }

    const ExpandedComponent = ({ data }: any) => (
        <>
            <div className="d-flex justify-content-between my-3">
                <p className="mb-0">Registered On: <strong>{formatDate(data.createdAt, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "dd MMMM, yyyy")}</strong></p>
                {data.id !== loggedUser.id && (
                    <div>
                        <button className="btn btn-success btn-rounded btn-h-28 mx-2" onClick={() => toggleAdmin(data)}>Make as {data.Role.role === 'user' ? 'admin' : 'user'}</button>
                        <button className="btn btn-info btn-rounded btn-h-28" onClick={() => {
                            setChangePasswordModal(true);
                            setChangePasswordUser(data);
                        }}>Change password</button>
                    </div>
                )}
            </div>
        </>
    );

    const CellComponent = (row: any) => (
        <>
            <div className='d-flex flex-row w-100'>
                <p className="mb-0">{row.firstName} {row.lastName}</p> {row.Role && <label className={`badge badge-danger ${row.Role.role === 'user' ? 'bg-info' : 'bg-danger'} ml-auto ${classes.roleBadge}`}>{row.Role.role}</label>}
            </div>
        </>
    );

    const columns: TableColumn<any>[] = [
        {
            name: 'Name',
            cell: CellComponent,
            selector: (row: any) => `${row.firstName} ${row.lastName}`,
        },
        {
            name: 'Email',
            selector: (row: any) => `${row.email}`,
        },
        {
            name: 'Email Subscribed',
            selector: (row: any) => row.isSubscribeToEmail ? 'YES' : 'NO',
        }
    ]

    const exportUsers = async () => {
        showLoader(true);
        const response = await UserService.export();
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `user-list-${new Date().getTime()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
        showLoader(false);
    }

    const fetchUsers = async (page: number, searchString?: string) => {
        showLoader(true);
        setLoading(true);
        const response = await UserService.getAllUsers(page, searchString);
        setData(response.users);
        setTotalRows(response.total);
        setLoading(false);
        showLoader(false);
    };

    const handlePageChange = (page: number) => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);

        const response = await UserService.getAllUsers(page);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const onPasswordSubmit = async (data: any) => {
        showLoader(true);
        await UserService.updateUserPassword(changePasswordUser.id, data.password);
        toast(`You have successfully updated the password of ${changePasswordUser.firstName}.`, { type: 'success' });
        setChangePasswordModal(false);
        setChangePasswordUser(null);
        reset();
        showLoader(false);
    }

    const changeHandler = (event: any) => {
        setQuery(event.target.value);
    };
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    useEffect(() => {
        fetchUsers(1, query); // fetch page 1 of users
    }, [query]);


    return (
        <>
            <div className={`interPageWhite ps-md-0 users ${classes.users}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column flex-grow-1">
                        <div className='d-flex mt-3 mt-md-5 mb-4'>
                            <h3 className="interBodyTitle Inactive">Users</h3>
                            <input type="text"
                                onChange={debouncedChangeHandler}
                                className='form-control form-control--admin default w-50 ml-auto mr-2'
                                placeholder='Search Users'
                            />
                            <button className='btn btn-secondary' onClick={exportUsers}>EXPORT</button>
                        </div>
                        <div className="d-flex flex-column">
                            <DataTable
                                columns={columns}
                                data={data}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                progressPending={loading}
                                progressComponent={<>Loading...</>}
                                pagination
                                paginationServer
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={changePasswordModal} onHide={() => {
                setChangePasswordModal(false);
                reset();
                setChangePasswordUser(null);
            }}>
                <Modal.Header closeButton>
                    <h5>Reset Password</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.shareModalContent}>
                        <p>
                            Update password of {changePasswordUser?.firstName}
                        </p>

                        <div>
                            <Form onSubmit={handleSubmit(onPasswordSubmit)}>
                                <Form.Group className="mb-3" controlId="password">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        {...register('password', { required: true })}
                                        className={`form-control form-control--admin default w-100 ${errors.password ? 'is-invalid' : ''}`} />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password && errors.password.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="secondary" type='submit'>
                                    Change Password
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default AdminUsers;