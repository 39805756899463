import axios from "axios"

const ContentService = {

    // Get podcast info
    getAll: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/content`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    getByType: async (type: string) => {
        try {
            const response = await axios.get(`/api/v1/content/type/${type}`);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    save: async (data: { type: string, content: string }) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/content`, data, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

}

export default ContentService;