import React, { useContext, useEffect, useState } from 'react';
import classes from './AdminTopPodcast.module.scss';
import Select from 'react-select';
import cover from './../../../../../assets/images/CoverArt.png';
import { TOP_PODCAST_PERIOD_FILTER, TOP_PODCAST_TYPE_FILTER } from '../../../../../const/adminFilters';
import AnalyticsService from '../../../../../services/analytics.service';
import { LoadingContext } from '../../../../../contexts/LoadingContext';

const AdminTopPodcast = () => {
    const typeFilterOptions = [
        { value: TOP_PODCAST_TYPE_FILTER.LISTENS, label: 'Listens' },
        { value: TOP_PODCAST_TYPE_FILTER.INTERACTION, label: 'Interactions' },
        { value: TOP_PODCAST_TYPE_FILTER.SHARES, label: 'Shares' },
        { value: TOP_PODCAST_TYPE_FILTER.SUBSCRIBERS, label: 'Subscribers' }
    ];

    const dayFilterOptions = [
        { value: TOP_PODCAST_PERIOD_FILTER.DAY, label: 'Day (current day)' },
        { value: TOP_PODCAST_PERIOD_FILTER.WEEK, label: 'Week' },
        { value: TOP_PODCAST_PERIOD_FILTER.MONTH, label: 'Month' },
        { value: TOP_PODCAST_PERIOD_FILTER.YEAR, label: 'Year' }
    ];

    const { showLoader } = useContext(LoadingContext);
    const [initiated, setInitiated] = useState(false);
    const [firstPodcastsList, setFirstPodcastsList] = useState<any[]>([]);
    const [secondPodcastsList, setSecondPodcastsList] = useState<any[]>([]);
    const [typeFilterValue, setTypeFilterValue] = useState(TOP_PODCAST_TYPE_FILTER.LISTENS);
    const [dateFilterValue, setDateFilterValue] = useState(TOP_PODCAST_PERIOD_FILTER.DAY);

    const fetchData = async () => {
        showLoader(true);
        const topPodcasts = await AnalyticsService.getTopPoscastData(typeFilterValue, dateFilterValue);
        bindPodcasts(topPodcasts.filter((p: any) => p.podcast !== null));
        setInitiated(true);
        showLoader(false);
    }

    const bindPodcasts = (podcasts: any[]) => {
        const middleIndex = Math.ceil(podcasts.length / 2);
        const firstHalf = podcasts.splice(0, middleIndex);
        const secondHalf = podcasts.splice(-middleIndex);
        setFirstPodcastsList(firstHalf);
        setSecondPodcastsList(secondHalf);
    }

    const PodcastItem = ({ item }: { item: any }) => {
        return (
            <>
                <div className="row text-gray-100 mt-3">
                    <div className="col-2">
                        <h5 className="me-4 fw-normal">{item.number}.</h5>
                    </div>
                    <div className="col-10">
                        <div className="d-flex align-items-center">
                            <img src={item.podcast?.imageUrl} loading="lazy" width={123} height={123} />
                            <h5 className="ms-4 fw-normal">{item.count} {(typeFilterOptions.find((f: any) => f.value === typeFilterValue))?.label}</h5>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (!initiated) {
            fetchData();
            return;
        }

        fetchData();
    }, [typeFilterValue, dateFilterValue]);



    return (
        <>

            <div className="d-flex align-items-center mt-4">
                <h5 className="text-gray-100 text-nowrap">Top 20 Podcasts by</h5>

                <div className='d-flex w-100'>
                    <div className='w-50'>
                        <Select
                            placeholder="listens"
                            defaultValue={typeFilterOptions.filter(o => o.value === typeFilterValue)}
                            onChange={(f: any) => setTypeFilterValue(f.value)}
                            options={typeFilterOptions}
                            className="form-control--admin ms-2" />
                    </div>
                    <div className='w-50'>
                        <Select
                            placeholder="day"
                            defaultValue={dayFilterOptions.filter(o => o.value === dateFilterValue)}
                            onChange={(f: any) => setDateFilterValue(f.value)}
                            options={dayFilterOptions}
                            className="form-control--admin ms-2" />
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-6">
                    {firstPodcastsList.map((p: any, index: number) => <PodcastItem key={index} item={p} />)}
                </div>
                <div className="col-md-6">
                    {secondPodcastsList.map((p: any, index: number) => <PodcastItem key={index} item={p} />)}
                </div>
            </div>
        </>
    );
};


export default AdminTopPodcast;