import React, { ReactElement, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import classes from './PasswordResetModal.module.scss';
import { useForm } from "react-hook-form";
import { AuthContext, AuthModalViews } from '../../../../../contexts/AuthContext';
import AuthService from '../../../../../services/auth.service';
import backIcon from '../../../../../assets/images/Expand_left_double.svg';
import { LoadingContext } from '../../../../../contexts/LoadingContext';
import { toast } from 'react-toastify';

type Props = {
    data: {
        handleClose: () => void
    }
}

const PasswordResetModal = (props: Props): ReactElement => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const { showLoader } = useContext(LoadingContext);
    const { handleClose } = props.data;
    const {
        setAuthModalView
    } = React.useContext(AuthContext);

    const onSubmit = async (data: any) => {
        showLoader(true);
        const reset = await AuthService.reset(data);
        handleClose();
        showLoader(false);
        toast("We have e-mailed your password reset link!", { type: 'success' });
    }
    return (
        <>
            <div className={classes.resetModal}>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="modal-header">
                            <div>
                                <div className="mb-3">
                                    <a onClick={() => setAuthModalView(AuthModalViews.LOGIN)} className="interLink text-uppercase"><img src={backIcon} alt="go back icon" />Back</a>
                                </div>
                                <div className="modal-title h5">Reset Password</div>
                            </div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                            </button>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <input
                                    type="email"
                                    placeholder="Enter email"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: ""
                                        }
                                    })}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.type === "required" && <span>This field is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span>Entered value does not match email format</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='modal-footer'>
                                <Button type='submit' variant="secondary">
                                    Reset Password
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PasswordResetModal;