import React, { ReactElement } from 'react';
import InteractiveFeed from '../../../components/_shared/InteractiveFeed/InteractiveFeed';

type Props = {
    episode: any,
    feeds: any[]
}

const FadeInSection = ({ children }: any) => {
    const domRef = React.useRef<any>();
    const [isVisible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setVisible(true);
                observer.unobserve(domRef.current);
            }
        });
        observer.observe(domRef.current);
        return () => observer.disconnect();
    }, []);

    return (<section ref={domRef} className={`fade-in-section ${isVisible ? ' is-visible' : ''}`}>{children}</section>);

}

const InteractiveFeedSection = (props: Props): ReactElement => {
    const { feeds } = props;

    return (
        <>
            <div className="d-flex flex-column loader--wrapper loader--animate">
                <div className="loader--glow loader--bg"></div>
                {feeds.map((feed: any, i: number) => (
                    <FadeInSection key={i}>
                        <InteractiveFeed feed={feed} />
                    </FadeInSection>
                ))}
            </div>
        </>
    )
};

export default InteractiveFeedSection;