import React, { ReactElement, useContext, useEffect } from 'react';
import classes from './Home.module.scss';
import logo from '../../../assets/images/logo-xl.svg';
import SearchPanel from '../../components/_shared/SearchPanel/SearchPanel';
import { useNavigate } from 'react-router-dom';
import { useStoreHook } from '../../../store/hooks';
import CustomLink from '../../components/_shared/CustomLink/CustomLink';
import { LoadingContext } from '../../../contexts/LoadingContext';

type Props = {
}

const Home = (): ReactElement => {

    const navigate = useNavigate();
    const { setSeoMeta } = useContext(LoadingContext);

    const onSelectPodcast = (podcast: any) => {
        navigate(`/podcast/${podcast.id}`);
    }

    useEffect(() => {
        // setSeoMeta(undefined);
    }, []);


    return (
        <>
            <div className={classes.interHome}>
                <img className="mw-100 align-self-center mb-5" src={logo} loading="lazy" />
                <SearchPanel onSelectPodcast={onSelectPodcast} />
                <p className='text-center text-white mt-3'>or <CustomLink to={'/discover'}><span className='interText-link text-underline'>discover something new!</span></CustomLink></p>
            </div>
        </>
    )
};

export default Home;