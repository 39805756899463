import { useContext, useEffect, useRef, useState } from 'react';
import CollectionService from '../../../../services/collection.service';
import classes from './AdminChartTopper.module.scss';
import Select from 'react-select';
import { Badge, Modal } from 'react-bootstrap';
import { LoadingContext } from '../../../../contexts/LoadingContext';
import { toast } from 'react-toastify';
import { ReactSortable } from "react-sortablejs";

const AdminChartTopper = () => {

    const { showLoader } = useContext(LoadingContext);
    const [loading, setLoading] = useState(true);
    const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
    const [unUsedCategories, setUnUsedCategories] = useState<any[]>([]);
    const [chartToppers, setChartToppers] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [dragCategory, setDragCategory] = useState<any>();
    const [confirmModal, showConfirmModal] = useState<any>(false);
    const [deletingCategory, setDeletingCategory] = useState<any>(null);
    const [movingIndex, setMovingIndex] = useState<number>(0);

    const chartToppersStateRef = useRef<any[]>();
    chartToppersStateRef.current = chartToppers;

    const getAllContents = async () => {
        showLoader(true);
        const categoryList: any[] = await CollectionService.getAllChartCategories();
        const contents = await CollectionService.getAllChartTopper();
        setChartToppers(contents);
        populateCategoryList(contents, categoryList);
        setLoading(false);
        showLoader(false);
    }

    const populateCategoryList = (contents: any[], categoryList: any[]) => {
        const selectedCategoriesList = contents.map((c: any) => c);
        setSelectedCategories(selectedCategoriesList);
        const notSelectedCategories = categoryList.filter((c: any) => selectedCategoriesList.indexOf(c.title) === -1);
        const categoryMap = notSelectedCategories.map((nsc: any) => {
            return {
                label: nsc.title,
                value: nsc.title
            }
        })
        setUnUsedCategories(categoryMap);
    }

    const onCategoryChange = (e: any) => {
        setSelectedCategory(e);
    }

    const addItem = async () => {
        showLoader(true);
        await CollectionService.addChartTopper(selectedCategory.value);
        await getAllContents();
        showLoader(false);
    }

    const sync = async () => {
        try {
            showLoader(true);
            await CollectionService.syncChartTopper();
            const contents = await CollectionService.getAllChartTopper();
            setChartToppers(contents);
            showLoader(false);
            toast("You have successfully synced the chart toppers.", { type: 'success' });
        } catch (error) {
            showLoader(false);
            toast("Failed to sync the chart toppers.", { type: 'error' });
        }
    }

    const confirmDelete = async () => {
        showLoader(true);
        await CollectionService.deleteChartTopper(deletingCategory);
        await getAllContents();
        setDeletingCategory(null);
        showConfirmModal(false);
        showLoader(false);
    }

    const removeItem = async (category: any, podcastId: any) => {
        showLoader(true);
        const resp = await CollectionService.deletePodcastFromChartTopper(category.id, podcastId);
        if (resp && resp.deletedPodcastId) {
            const fList = category.podcastList.filter((f: any) => f.id !== resp.deletedPodcastId);
            if (resp.newItem && resp.newItem.length > 0) {
                fList.push(resp.newItem[0]);
            }
            chartToppers.forEach((cat: any) => {
                if (cat.id === category.id) {
                    cat.podcastList = [...fList];
                }
            });
            setChartToppers([]);
            setTimeout(() => {
                setChartToppers([...chartToppers]);
                showLoader(false);
            }, 1);
        }
    }

    const arrayMove = (arr: any[], old_index: number, new_index: number) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    const onSortStart = (evt: any) => {
        setMovingIndex(evt.oldIndex);
    }

    const onSortEnd = async (evt: any) => {
        showLoader(true);
        let updatedArray = arrayMove(chartToppers, movingIndex, evt.newIndex);
        const orderUpdatedList: any = {};
        updatedArray = updatedArray.map((ct: any, i: number) => {
            ct.sequance = i + 1;
            orderUpdatedList[ct.id] = ct.sequance;
            return ct;
        });
        await CollectionService.updateChartTopperOrder(orderUpdatedList);
        
        setChartToppers([]);
        setTimeout(() => {
            setChartToppers([...updatedArray]);
            showLoader(false);
        }, 500);
    }

    useEffect(() => {
        getAllContents();
    }, []);

    return (
        <>
            <div className={`interPageWhite ps-md-0 adminPages ${classes.chartTopperPages}`}>
                <div className="interPageInner">
                    <h3 className="interBodyTitle Inactive mt-3 mt-md-5 mb-4">Popular Podcasts</h3>
                    {!loading && (
                        <>
                            <div className='mb-2'>

                                <div className='d-flex'>
                                    <Select
                                        className="basic-single mb-2 w-50"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        value={selectedCategory}
                                        name="color"
                                        options={unUsedCategories}
                                        onChange={onCategoryChange}
                                    />
                                    <button style={{ marginLeft: '10px' }} className='ml-2 btn btn-success btn-success--dark' onClick={addItem} >ADD</button>
                                    <button style={{ marginLeft: 'auto' }} className='btn btn-success btn-secondary' onClick={sync}>Sync Manually</button>
                                </div>

                                <div className={'d-flex'}>
                                    <ReactSortable
                                        list={selectedCategories}
                                        setList={setSelectedCategories}
                                        animation={200}
                                        onStart={onSortStart}
                                        onEnd={onSortEnd}
                                    >
                                        {selectedCategories.map((cat: any, i: number) => (
                                            <Badge
                                                key={cat.id}
                                                className={classes.sortableCategory}
                                                bg="secondary">{cat.categoryName}</Badge>
                                        ))}
                                    </ReactSortable>
                                </div>
                                <p className='text-muted text-small pl-2'>Drag and Drop for sort categories</p>
                                <hr />
                            </div>

                            {chartToppers.sort((a: any, b: any) => a.sequance - b.sequance).map((ct: any, index: number) => (
                                <div key={index}>
                                    <div className="d-flex align-items-center placeholder-green">
                                        <h5 className="text-gray-100 text-nowrap mb-0 text-underline">{ct.categoryName}</h5>
                                        <button className="btn btn-danger ml-auto" onClick={() => {
                                            showConfirmModal(true);
                                            setDeletingCategory(ct.id)
                                        }}>DELETE</button>
                                    </div>
                                    <div className="d-flex flex-wrap mt-4">
                                        {ct.podcastList.map((p: any, i: number) => (
                                            <div key={i} className="interbox">
                                                <img src={p.imageUrl} width="120" height="120" title={p.title} />
                                                <div className="interbox--remove" onClick={() => removeItem(ct, p.id)}> - </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>

            <Modal show={confirmModal} >
                <Modal.Header >
                    <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-light' onClick={(event) => {
                        showConfirmModal(false);
                        setDeletingCategory(null)
                    }}>
                        No
                    </button>
                    <button className='btn btn-danger' onClick={(event) => confirmDelete()}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default AdminChartTopper;