import React, { ReactElement, useState } from 'react';
import classes from './App.module.scss';
import { AuthProvider, AuthProviderProps } from '../providers/AuthProvider';
import AuthModal from './components/_modals/AuthModal/AuthModal';
import { AuthContext } from '../contexts/AuthContext';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Routers from './routers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PlayerProvider } from '../providers/PlayerProvider';
import { LoadingContext } from '../contexts/LoadingContext';
import { MutatingDots } from 'react-loader-spinner'
import ScrollToTop from './components/_shared/ScrollToTop/ScrollToTop';
import UserService from '../services/user.service';
import PodcastService from '../services/podcast.service';
import { useDispatch } from 'react-redux';
import { GeneralActionTypes } from '../store/actions/generalAction';
import AuthService from '../services/auth.service';

const App = ({ defaultAuthenticated }: AuthProviderProps): ReactElement => {

  const { authenticated, setLoggedUser, setToken } = React.useContext(AuthContext);
  const { loader, showLoader } = React.useContext(LoadingContext);

  const [applicationStartup, setApplicationStartup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const generalState = useSelector((state: RootState) => state.generalReducer);
  // const favouriteEpisodeList = generalState.favouriteEpisodes;
  // const subscribedPodcastList = generalState.subscribedPodcasts;

  const checkAuth = async () => {
    try {
      showLoader(true);
      const user = await UserService.getLoggedInUser();
      if (user) {
        const getLoggedUserPodcastEnrollments = await PodcastService.getLoggedUserPodcastEnrollments();
        dispatch({ type: GeneralActionTypes.SET_FAVOURITE_EPISODES, payload: getLoggedUserPodcastEnrollments.favouriteEpisodeList });
        dispatch({ type: GeneralActionTypes.SET_SUBSCRIBED_PODCASTS, payload: getLoggedUserPodcastEnrollments.subscribedPodcastList });
        dispatch({ type: GeneralActionTypes.SET_LOGGED_USER, payload: user })
        setLoggedUser(user);

        if (window.location.pathname === "/") {
          navigate("/my-podcasts")
        }
      } else {
        logout();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        toast("Your session is expired! Please login again.", { type: 'error' });
        logout();
      } else {
        toast("Something went wrong when retreive session details.", { type: 'error' });
      }
    }

    setApplicationStartup(true);
    showLoader(false);
  }

  const logout = async () => {
    await AuthService.logout();
    dispatch({ type: GeneralActionTypes.SET_FAVOURITE_EPISODES, payload: [] });
    dispatch({ type: GeneralActionTypes.SET_SUBSCRIBED_PODCASTS, payload: [] });
    dispatch({ type: GeneralActionTypes.SET_LOGGED_USER, payload: null })
    setToken("");
    navigate("/");
  }

  const handleLogin = async () => {
    await checkAuth();
  };

  const handleLogout = () => {
  };

  return (
    <AuthProvider
      onLogin={handleLogin}
      onLogout={handleLogout}
      defaultAuthenticated={defaultAuthenticated}
    >
      <ScrollToTop />
      <PlayerProvider>
        <div className={`${classes.interApp}`}>
          {(!authenticated || applicationStartup) && <Routers />}
        </div>

        {/* Render auth modals */}
        {!authenticated && <AuthModal />}

      </PlayerProvider>

      {loader && (
        <div className={classes.loader}>
          <div className='text-center'>
            <MutatingDots
              height="100"
              width="100"
              color='#50F9EB'
              secondaryColor='#34D7EE'
              ariaLabel='loading'
            />
            Loading...
          </div>
        </div>
      )}
      <ToastContainer />
    </AuthProvider>

  );
}

export default App;
