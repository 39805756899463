import axios from "axios"

const UserService = {
    update: async (formData: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.put(`/api/v1/user`, formData, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    deactivate: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.delete(`/api/v1/user`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    getById: async (userId: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/user/${userId}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    getAllUsers: async (page: number, query?: string) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.get(`/api/v1/user?page=${page}` + (query ? `&query=${query}` : ''), config);
        return response.data;
    },

    getLoggedInUser: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/user/logged-in-user`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    makeAdmin: async (userId: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/user/admin`, {
                userId
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    revertAdmin: async (userId: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/user/admin/revert`, {
                userId
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    export: async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    Accept: 'application/csv'
                }
            };
            const response = await axios.get(`/api/v1/user/export`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    updateUserPassword: async (userId: number, newPassword: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/auth/admin/password`, {
                newPassword, userId
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
}

export default UserService;