import React from 'react';

export enum AuthModalViews {
    LOGIN = 1,
    REGISTER = 2,
    FORGOT = 3,
    RESET_PASSWORD = 4
}

export type IAuthContext = {
    authenticated: boolean;
    setAuthenticated: (isAuthenticated: boolean) => void;

    isAdmin: boolean,
    setIsAdmin: (isAdmin: boolean) => void;

    authModalOpened: boolean;
    authModalView: AuthModalViews | null,
    setAuthModalOpened: (opened: boolean) => void;
    setAuthModalView: (view: AuthModalViews | null) => void;

    token: string | null;
    setToken: (token: string) => void;

    refreshToken: string | null;
    setRefreshToken: (token: string | null) => void;

    loggedUser: any;
    setLoggedUser: (user: any) => void;
};

export const AuthContext = React.createContext<IAuthContext>({
    authenticated: false,
    setAuthenticated: () => { },

    isAdmin: false,
    setIsAdmin: () => { },

    authModalOpened: false,
    authModalView: null,
    setAuthModalOpened: () => { },
    setAuthModalView: () => { },

    token: null,
    setToken: () => { },

    refreshToken: null,
    setRefreshToken: () => { },

    loggedUser: null,
    setLoggedUser: () => { }
});