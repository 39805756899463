import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import classes from './Episode.module.scss';
import EpisodeDetailSection from '../../components/EpisodeDetailSection/EpisodeDetailSection';
import { IEpisode } from '../../../interface/listen-notes';
import { useParams } from 'react-router-dom';
import PodcastStoreService from '../../../services/podcast-store.service';
import InteractiveFeedSection from './InteractiveFeedSection/InteractiveFeedSection';
import ShowNotesSection from './ShowNotesSection/ShowNotesSection';
import EpisodeService from '../../../services/episode.service';
import { PlayerContext } from '../../../contexts/PlayerContext';
import { LoadingContext } from '../../../contexts/LoadingContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

const Episode = (): ReactElement => {

    let { uid } = useParams();
    const prevIdRef = useRef<string>();

    const { authenticated } = useContext(AuthContext);
    const { setCurrentPlayList, setPlaying } = useContext(PlayerContext);
    const { showLoader, setSeoMeta } = useContext(LoadingContext);

    const [episode, setEpisode] = useState<any>({} as any);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(1);
    const [feedUrls, setFeedUrls] = useState<string[]>([]);

    const [episodeInfoLoaded, setEpisodeInfoLoaded] = useState<any>(false);
    const [episodeInfo, setEpisodeInfo] = useState<any>(null);
    const [feeds, setFeeds] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [paginating, setPaginating] = useState(false);

    const detectURLs = (text: string) => {
        const urlRegex = /(?:https?:\/\/[^"'\/\\ ]*)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/g;
        return text.match(urlRegex)?.map(u => u.toLowerCase());
    }

    const fetchEpisdodeUserData = async () => {
        if (authenticated) {
            const epInfo = await EpisodeService.fetchEpisodeData(uid!);
            if (epInfo.id) {
                setEpisodeInfo(epInfo);
            }
            setEpisodeInfoLoaded(true);
        }
    }

    const enrollEpisode = async () => {
        if (authenticated) {
            await EpisodeService.enroll({
                episodeLength: episode.length,
                episodeUId: episode.id,
                podcastUId: episode.podcast.id
            });
            await fetchEpisdodeUserData();
        }
    }

    const fetchEpisode = async () => {
        const episodeResponse = await PodcastStoreService.fetchEpisode(uid!);
        if (episodeResponse) {
            setCurrentPlayList([episodeResponse.data.episode]);
            const episode = episodeResponse.data.episode;
            setEpisode(episode);
            // setSeoMeta({
            //     title: episode.title,
            //     description: episode.description,
            //     image: episode.imageUrl
            // });
            checkForInteractiveFeeds(episodeResponse.data.episode);
        }
        setLoading(false);
    }

    const checkForInteractiveFeeds = (episode: any) => {
        let urls = detectURLs(episode.htmlDescription);
        if (!urls) {
            setCurrentTab(2);
            showLoader(false);
            return;
        }

        urls = urls.filter(u => u.indexOf("gmail.com") === -1).filter(u => u.length > 3);

        const prefix = 'http://';
        const prefixHttps = 'https://';
        let formattedUrls = urls.map((url: string) => {
            if (!url.match(/^[a-zA-Z]+:\/\//)) {
                if (urls?.indexOf(prefix + url) !== -1) {
                    url = prefix + url;
                } else if (urls?.indexOf(prefixHttps + url) !== -1) {
                    url = prefixHttps + url;
                } else {
                    url = prefix + url;
                }
            }
            return url;
        });
        formattedUrls = removeDuplicateUrls(formattedUrls);
        setFeedUrls(formattedUrls);
    }

    const removeDuplicateUrls = (formattedUrls: string[]) => {
        let uniqueChars: string[] = [];
        formattedUrls.forEach((c: string) => {
            if (!uniqueChars.includes(c)) {
                uniqueChars.push(c);
            }
        });
        return uniqueChars;
    }

    const makeChunk = (inputArray: any[]) => {
        const perChunk = 5;
        const result = inputArray.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
        return result;
    }

    const fetchEpisodeFeeds = async () => {
        if (paginating) { return }
        const urlChunk = makeChunk(feedUrls);
        if (!urlChunk[page]) {
            return;
        }
        setPaginating(true);
        const res = await EpisodeService.fetchFeedData(urlChunk[page], episode?.podcast?.id!, episode.id);
        if (!res) { return; }
        if (res.length > 0) {
            setFeeds([...feeds, ...res]);
        }
        setPage(page + 1);
        setPaginating(false);
        showLoader(false);
    }

    const onScrollCheck = () => {
        const cal = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        const d = debounce(async () => {
            if (parseInt(cal.toString()) === 0 && !loading && !paginating) {
                await fetchEpisodeFeeds();
            }
        }, 0);
        d();
    }

    const visitWebSite = (url: string) => {
        window.open(url);
    };

    const markAsPlayed = async () => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }

        showLoader(true);
        await EpisodeService.markAsRead(uid!, episode.length);
        await fetchEpisdodeUserData();
        showLoader(false);
    }

    useEffect(() => {
        if (feedUrls.length > 0) {
            fetchEpisodeFeeds();
        }
    }, [feedUrls]);

    useEffect(() => {
        if (prevIdRef.current === uid) {
            return;
        }

        showLoader(true);
        setLoading(true);
        fetchEpisode();
        prevIdRef.current = uid;
    }, [uid]);

    useEffect(() => {
        if (authenticated && episode.id) {
            enrollEpisode();
        }
    }, [authenticated, episode]);

    useEffect(() => {
        window.addEventListener("scroll", onScrollCheck);
        return () => {
            window.removeEventListener("scroll", onScrollCheck);
        };
    });



    return (
        <>
            {loading ? (
                <h5>Loading Episode...</h5>
            ) : (
                <div className={classes.interEpisode}>
                    <div className="mx-w-80 mb-5">
                        <EpisodeDetailSection episode={episode} isFavourite={episodeInfoLoaded && episodeInfo && episodeInfo.bookMarked} />
                    </div>

                    {(episodeInfoLoaded && (!episodeInfo || !(episodeInfo && episodeInfo.isCompleted))) && (
                        <div className={`d-flex flex-row-reverse  mx-w-80`}>
                            <button className={`btn-rounded btn-h-32 mb-2 mb-md-0 ${classes.markAllPlayedButton}`} onClick={markAsPlayed}>
                                Mark As Played
                            </button>
                        </div>
                    )}

                    <div className="d-flex flex-column-reverse flex-xl-row justify-content-between">
                        <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">
                            <div className="d-flex align-items-center mb-4">
                                <h3 className={`interBodyTitle mb-0 ${currentTab == 2 ? 'Inactive cursor-pointer' : 'cursor-pointercurrentTab'}`} onClick={() => setCurrentTab(1)}>On This Episode</h3>
                                <div className="interSeparator mx-28"></div>
                                <h3 className={`interBodyTitle mb-0 ${currentTab == 1 ? 'Inactive cursor-pointer' : 'cursor-pointercurrentTab'}`} onClick={() => setCurrentTab(2)}>Show Notes</h3>
                            </div>

                            {currentTab == 1 && (
                                <>
                                    {feeds.length > 0 && <InteractiveFeedSection episode={episode} feeds={feeds} />}
                                    {(!loading && feeds.length === 0) && (
                                        <>
                                            <div className='interSingleEpisode d-flex flex-row p-4'>

                                                <div className="d-flex justify-content-start interSingleEpisode--inner">
                                                    <div className={`emptyspace`}></div>
                                                </div>

                                                <div className="flex-grow-1 text-start my-0">
                                                    <h5 className='text-white'>No Interactive Feed? </h5>
                                                    <p className='text-secondary interBodyTitleSecondary'>The interactive feed is generated by hyperlinks in the episode show notes. If you’re seeing this message, it’s because we did not identify any links in the episode show notes.</p>
                                                    <p className='text-secondary interBodyTitleSecondary'> Want the podcaster to make their podcast more interactive?</p>
                                                    <p className='text-secondary interBodyTitleSecondary'> Click the button below to visit their website, find their contact page, and let them know it would be helpful to you if they put links in their show notes.</p>
                                                    {episode?.podcast?.webUrl && (<button type="button" onClick={() => visitWebSite(episode?.podcast?.webUrl)} className="btn btn-rounded w-xl-100 mt-4 btn-info">Visit Podcast Website</button>)}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {(!loading && paginating) && <div className='text-center p-3 text-white'>Loading Feeds...</div>}
                                </>
                            )}
                            {currentTab == 2 && <ShowNotesSection episode={episode} />}

                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default Episode;