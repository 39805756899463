import { createAction } from "redux-actions";
import { MakeFavouriteEpisodePayload } from "../reducers/payloadTypes";

export const GeneralActionTypes = {
    SET_SUBSCRIBED_PODCASTS: "SET_SUBSCRIBED_PODCASTS",
    SET_FAVOURITE_EPISODES: "SET_FAVOURITE_EPISODES",

    MAKE_FAVOURITE_EPISODE: "MAKE_FAVOURITE_EPISODE",
    SUBSCRIBE_PODCAST: "SUBSCRIBE_PODCAST",

    SET_LOGGED_USER: "SET_LOGGED_USER",
};
export const setSubscribedPodcastsStore = createAction<{ [key: string]: boolean }>(GeneralActionTypes.SET_SUBSCRIBED_PODCASTS);
export const setFavouriteEpisoesStore = createAction<{ [key: string]: boolean }>(GeneralActionTypes.SET_FAVOURITE_EPISODES);
export const makeFavouriteEpisodeStore = createAction<MakeFavouriteEpisodePayload>(GeneralActionTypes.MAKE_FAVOURITE_EPISODE);
export const subscribePodcastStore = createAction<string>(GeneralActionTypes.SUBSCRIBE_PODCAST);
export const loggedUserStore = createAction<string>(GeneralActionTypes.SET_LOGGED_USER);