import React, { ReactElement, useContext, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Modal } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext, AuthModalViews } from '../../../../../contexts/AuthContext';
import classes from './RegisterModal.module.scss';
import AuthService from '../../../../../services/auth.service';
import { toast } from 'react-toastify';
import backIcon from '../../../../../assets/images/Expand_left_double.svg';
import axios from 'axios';
import ImageService from '../../../../../services/image.service';
import { LoadingContext } from '../../../../../contexts/LoadingContext';

type Props = {
    data: {
        handleClose: () => void
    }
}

const RegisterModal = (props: Props): ReactElement => {
    const recaptchaRef = useRef<any>()
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const { handleClose } = props.data;
    const { setAuthModalView } = useContext(AuthContext);
    const { showLoader } = useContext(LoadingContext);

    const [profileImage, setProfileImage] = useState<File | null>(null);

    const onSubmit = async (data: any) => {

        let profileImageKey = null;
        showLoader(true);
        if (profileImage) {
            profileImageKey = await uploadProfileImage(data);
        }

        const captchaToken = await recaptchaRef?.current?.getValue();
        recaptchaRef.current.reset();
        data['profileImage'] = profileImageKey;
        data['captchaToken'] = captchaToken;
        const registered = await AuthService.register(data);

        if (registered.success) {
            toast("You have successfully registered with Intercast.fm", { type: 'success' });
            setAuthModalView(AuthModalViews.LOGIN);
            showLoader(false);
            return;
        }

        if (registered.data.status === 409) {
            setError('email', { type: 'exist' });
            showLoader(false);
            return;
        }

        toast(registered.data?.data?.message || "Failed to register with Intercast.fm", { type: 'error' });
        showLoader(false);
    }

    const uploadProfileImage = async (data: any) => {
        if (profileImage) {
            const profileImageKey = `${data.email.split("@")[0]}-${new Date().getTime()}.${profileImage.name.split('.')[1]}`
            const { signedURL } = await ImageService.getSignedURL(`upload/${profileImageKey}`);

            await axios.put(signedURL, profileImage, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            return profileImageKey;
        }
    }

    return (
        <div className={classes.registerModal}>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="modal-header">
                        <div>
                            <div className="mb-3">
                                <a onClick={() => setAuthModalView(AuthModalViews.LOGIN)} className="interLink text-uppercase"><img src={backIcon} alt="go back icon" />Back</a>
                            </div>
                            <div className="modal-title h5 mb-4">Sign-up for intercast.fm</div>
                        </div>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                        </button>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row d-flex flex-column-reverse flex-md-row">
                            <div className="col-md-9">
                                <Form.Group className="mb-3" controlId="formFirstName">
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        {...register('firstName', {
                                            required: true
                                        })}
                                        className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName && errors.firstName.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formLastName">
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        {...register('lastName', {
                                            required: true
                                        })}
                                        className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastName && errors.lastName.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <input
                                        type="email"
                                        placeholder="Enter email"
                                        {...register('email', {
                                            required: true,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: ""
                                            }
                                        })}
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email && errors.email.type === "required" && <span>This field is required</span>}
                                        {errors.email && errors.email.type === "pattern" && <span>Entered value does not match email format</span>}
                                        {errors.email && errors.email.type === "exist" && <span>This email address is already exists</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formPassword">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        {...register('password', { required: true })}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`} />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password && errors.password.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formConfirmPassword">
                                    <input
                                        type="password"
                                        placeholder="Confirm Password"
                                        {...register('confirmPassword', { required: true })}
                                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword && errors.confirmPassword.type === "required" && <span>This field is required</span>}
                                        {errors.confirmPassword && errors.confirmPassword.type === "invalidPassword" && <span>Password and Confirm Passwords do not match!</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div>
                                    {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY && <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY!}
                                        size="normal"
                                    />}
                                </div>

                                <div className='modal-footer'>
                                    <Button variant="secondary" type='submit'>
                                        Sign-Up
                                    </Button>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="interImage-uploader mb-3">
                                    {profileImage ? (
                                        <>
                                            <input type="file" name="file" onChange={(event) => setProfileImage(event.target?.files?.[0] || null)} />
                                            <img src={URL.createObjectURL(profileImage)} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Profile" />
                                        </>
                                    ) : (
                                        <>
                                            <p className="mb-0">Add<br /> Photo</p>
                                            <p className="mb-0">+</p>
                                            <input type="file" name="file" onChange={(event) => setProfileImage(event.target?.files?.[0] || null)} />
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <div className={`form-check ${errors.hasAgreedToTerms ? 'is-invalid' : ''}`}>
                                        <label className="form-check-label"><input type="checkbox" {...register('hasAgreedToTerms', { required: true })}
                                            className={`form-check-input `} /> I have read the <a href="#" className="interText-link">Terms of Service agreement</a> and promise to abide by the terms outlined therein.</label>
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.hasAgreedToTerms && errors.hasAgreedToTerms.type === "required" && <span>This field is required</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="form-check">
                                        <label className="form-check-label"><input type="checkbox" className='form-check-input'  {...register('isSubscribeToEmail')} /> Please email me with informational or feature updates about Intercast.fm.</label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </div >
            </div >

        </div >
    )
};

export default RegisterModal;