import { ReactElement, useContext, useEffect, useState } from 'react';
import classes from './InteractiveFeed.module.scss';

import WebDefaultIcon from './../../../../assets/images/WebDefault.svg';
import AmazonIcon from './../../../../assets/images/AmazonLogo.svg';
import LinkedInIcon from './../../../../assets/images/LinkedIn.svg';
import TwitterIcon from './../../../../assets/images/Twitter.svg';
import InstagramIcon from './../../../../assets/images/Instagram.svg';
import FacebookIcon from './../../../../assets/images/Facebook.svg';
import YoutubeIcon from './../../../../assets/images/Youtube.svg';

import NoPreviewAvailable from './../../../../assets/images/noPreviewAvailable.png';
import { AuthContext } from '../../../../contexts/AuthContext';
import AnalyticsService from '../../../../services/analytics.service';

type Props = {
    feed: any,
    className?: string,
    trackClick?: boolean
}

const InteractiveFeed = (props: Props): ReactElement => {
    const { feed, className, trackClick = true } = props;
    const { authenticated } = useContext(AuthContext);
    const [socialUserName, setSocialUserName] = useState('');
    const logoImages: any = {
        Facebook: FacebookIcon,
        Twitter: TwitterIcon,
        Instagram: InstagramIcon,
        Amazon: AmazonIcon,
        LinkedIn: LinkedInIcon,
        YouTube: YoutubeIcon,
        Other: WebDefaultIcon
    }

    const isSocialMediaLink = (type: string) => {
        return ["Facebook", "Twitter", "Instagram", "LinkedIn"].indexOf(type) !== -1;
    }

    const getUrlSegment = (url: string) => {
        let pathName = new URL(url).pathname;
        pathName = pathName.endsWith("/") ? pathName.slice(0, -1) : pathName;
        const splitPaths = pathName.split("/") || [];
        if (splitPaths.length > 1) {
            return splitPaths[1];
        }
        const lastSegment = pathName.substring(pathName.lastIndexOf("/") + 1);
        return lastSegment;
    }

    const trackUserClick = () => {
        if (trackClick) {
            AnalyticsService.feedClickEvent(feed.id).then();
        }
    };

    useEffect(() => {
        if (feed && feed.url) {
            setSocialUserName(getUrlSegment(feed.url));
        }
    }, [feed]);

    return (
        <>
            {feed && (
                <>
                    <div className={`interSingleEpisode d-flex flex-column ${classes.interSingleEpisode} ${className || ''}`}>
                        {(feed.type == "Other" && feed.image) && (
                            <div className="interSingleEpisode--banner" style={{ backgroundImage: `url("${feed?.image}")` }}>
                            </div>
                        )}
                        <div className="d-flex justify-content-start interSingleEpisode--inner">
                            <div className={`emptyspace`}>
                                {(feed.type !== "Other" && !isSocialMediaLink(feed.type)) && (
                                    <div className={`${feed.type !== "Amazon" ? "interRounded" : ""}  d-flex align-items-center justify-content-center`} style={{ width: "108px" }}>
                                        <img className={`${feed.type !== "Amazon" ? "rounded-circle" : ""} img-fluid`} src={feed?.image} width="90" height="90" loading="lazy" />
                                    </div>
                                )}
                                {isSocialMediaLink(feed.type) && (
                                    <div className={`${classes.socialMediaCard} ${classes[feed.type.toLowerCase()]}`}></div>
                                )}
                            </div>
                            <div className="flex-grow-1 text-start my-0">
                                {isSocialMediaLink(feed.type) ? (
                                    <>
                                        <h5 className="text-white line-clamp-1">@{socialUserName} On {feed.type}</h5>
                                        <p className="text-secondary mb-2 line-clamp-1"></p>
                                    </>
                                ) : (
                                    <>
                                        <h5 className="text-white line-clamp-1">{feed.title}</h5>
                                        <p className="text-secondary mb-2 line-clamp-1"></p>
                                        <p className="text-white mb-3 line-clamp-2">{feed.description}</p>
                                    </>
                                )}

                                <a href={feed.url} target={"_blank"} onClick={trackUserClick} className={`btn btn-success btn-rounded btn-h-28 ${classes.linkLineHeight}`}>
                                    {feed.type === "Other" ? "Visit Website" : `See On ${feed.type}`}
                                </a>

                            </div>
                            <div className="pr-7 pl-36 d-flex align-items-center">
                                <img src={logoImages[feed.type]} width="60" />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
};

export default InteractiveFeed;