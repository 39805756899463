import React, { ReactElement, useEffect, useState } from 'react';
import classes from './EpisodeSection.module.scss';
import playIcon from '../../../assets/images/play-white.svg';
import pauseIcon from '../../../assets/images/pause.svg';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PlayerContext } from '../../../contexts/PlayerContext';
import FavouriteButton from '../_shared/FavouriteButton/FavouriteButton';
import { formatDate, secondsToHms } from '../../utils/utils';
import { isAfter, parse } from 'date-fns';

type Props = {
    episode: any,
    podcastEnrollment: any | null,
    podcastStoreData: any | null,
    enrollment: any | null,
    bookmarked: boolean
}

const EpisodeSection = (props: Props): ReactElement => {

    const { playing, currentEpisode } = React.useContext(PlayerContext);

    const { episode, enrollment, podcastEnrollment, podcastStoreData } = props;
    const [unplayed, setUnplayed] = useState(true);
    const [remainTime, setRemainTime] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);

    const navigate = useNavigate();

    const navigateToEpisode = (id: string) => {
        navigate(`/episode/${id}`);
    }

    const checkIsCompleted = () => {
        if (enrollment && enrollment.isCompleted) {
            setIsCompleted(true);
            return true;
        }

        const episodePublishedParsedDate = parse(episode.airDate, 'yyyy-MM-dd HH:mm:ss', new Date());
        const markReadAllOnParsedDate = parse(podcastEnrollment.markReadAllOn, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
        const isAllMarked = isAfter(markReadAllOnParsedDate, episodePublishedParsedDate);
        setIsCompleted(isAllMarked);
        return isAllMarked;
    }

    useEffect(() => {

        if (enrollment) {
            checkIsCompleted();
        }

        if (enrollment && (checkIsCompleted() || (enrollment.playedDuration && parseInt(enrollment.playedDuration) > 0))) {
            setUnplayed(false)
            setRemainTime(episode.length - parseInt(enrollment.playedDuration));
        } else {
            setUnplayed(true)
            setRemainTime(episode.length);
        }
    }, [enrollment]);

    useEffect(() => {
        if (podcastEnrollment && podcastStoreData) {
            checkIsCompleted();
        }
    }, [podcastEnrollment, podcastStoreData]);

    return (
        <>
            <div className={classes.episodeSection} onClick={(e) => {
                navigateToEpisode(episode.id)
            }}>
                <div className="me-md-5 me-2">
                    <div className="interPlayButton-wrapper">
                        <div className="interPlayButton-bg">
                            {(currentEpisode && currentEpisode.id == episode.id && playing) ? <img src={pauseIcon} /> : <img src={playIcon} className="play" width="22" />}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-item-center mt-12">
                        <FavouriteButton episode={episode} />
                    </div>
                </div>
                <div className="flex-grow-1">
                    <div className='d-flex'>
                        <h4 className="flex-grow-1 mb-2 mb-md-4 line-clamp-2">{episode.title}</h4>
                        <Button className="btn-rounded btn-h-32 mb-2 mb-md-0"
                            size="sm" variant={(unplayed && !isCompleted) ? 'secondary' : 'info'}>

                            {isCompleted ? (<>Completed</>) : (
                                <>
                                    {unplayed ? (
                                        <>Unplayed <br />{secondsToHms(remainTime)}</>
                                    ) : (
                                        <> {secondsToHms(remainTime)} < br /> remaining </>
                                    )}
                                </>
                            )}
                        </Button>
                    </div>
                    <p className="interTextSecondary line-clamp-3 mb-8">{episode.description}</p>
                    <p className="interTextGray fw-300 font-size-12">{formatDate(episode.airDate, 'yyyy-MM-dd HH:mm:ss', 'MMMM dd, Y')}
                        {episode.explicit && (
                            <>
                                <span className="mx-2">|</span><a href="#" className="interText-link text-decoration-none">Explicit Content </a>
                            </>
                        )}
                    </p>
                </div>
            </div>
        </>
    )
};

export default EpisodeSection;