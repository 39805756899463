import classes from './AdminBlogCreate.module.scss';

const AdminBlogCreate = () => {
    return (
        <>
            <div className={`interPageWhite ps-md-0 ${classes.blogCreate}`}>
                <h3>Create blog Article</h3>
            </div>
        </>
    )
};

export default AdminBlogCreate;