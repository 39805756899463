import { createRef, ReactElement, useEffect, useState } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classes from './ContentEditor.module.scss';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import axios from 'axios';
import ColorPicker from './ColorPicker/ColorPicker'
import ImageService from '../../../../services/image.service';


type Props = {
    defaultValue: any,
    onContentChange: (content: any) => void
}

const ContentEditor = (props: Props): ReactElement => {

    const editorRef = createRef<Editor>();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [editorState, setEditorState] = useState(
    //     () => EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.defaultValue || '').contentBlocks)),
    // );

    const handleEditorChange = (state: any) => {
        setEditorState(state);
        convertContentToHTML(state);
    }

    const convertContentToHTML = (curState: any) => {
        const currentContentAsHTML = draftToHtml(convertToRaw(curState.getCurrentContent()))
        props.onContentChange(currentContentAsHTML);
    }

    const focus = () => {
        let editor = editorRef.current;
        if (editor) {
            editor.focusEditor();
        }
    };

    function uploadImageCallBack(file: File) {
        return new Promise(
            async (resolve, reject) => {
                try {
                    if (!file) {
                        reject('Invalid file.');
                    }

                    const uploadedImageKey = `${new Date().getTime()}.${file.name.split('.')[1]}`
                    const { signedURL } = await ImageService.getSignedURL(`upload/blogs/${uploadedImageKey}`);
                    await axios.put(signedURL, file, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                    resolve({ data: { link: `${process.env.REACT_APP_PROFILE_IMAGE_URL}blogs/${uploadedImageKey}` } });
                } catch (error) {
                    reject("Failed to upload image");
                }
            },
        );
    }

    useEffect(() => {
        focus();
    }, []);

    useEffect(() => {
        if (props.defaultValue) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.defaultValue || '').contentBlocks)));
        }
    }, [props.defaultValue]);

    return (
        <>
            <div className={`${classes.contentEditor} ${classes.editor}`} onClick={focus}>
                <Editor ref={editorRef}
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                        options: [
                            'inline', 'link', 'colorPicker', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'image', 'remove'
                        ],
                        inline: { inDropdown: false },
                        list: { inDropdown: false },
                        textAlign: { inDropdown: false },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                        image: {
                            previewImage: true,
                            uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: false },
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/png',
                        },
                        remove: { className: undefined, component: undefined },
                        colorPicker: {
                            className: undefined,
                            component: undefined, //ColorPicker,
                            popupClassName: undefined,
                            colors: [
                                '#2f746b', '#4ae3cf', '#317372', '#3ebfae', '#31a9d2',
                                'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        }
                    }}
                    toolbarStyle={{
                        position: 'sticky',
                        top: 82,
                        zIndex: 1000,
                    }}
                />
            </div>
        </>
    )
};

export default ContentEditor;