import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classes from './PodcastDetailSection.module.scss';
import { Button } from 'react-bootstrap';
import ImageService from '../../../services/image.service';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import BackButton from '../_shared/BackButton/BackButton';
import { useDispatch } from 'react-redux';
import { GeneralActionTypes } from '../../../store/actions/generalAction';
import { useStoreHook } from '../../../store/hooks';

type Props = {
    podcast: any
}

const PodcastDetailSection = (props: Props): ReactElement => {

    const { authenticated } = React.useContext(AuthContext);

    const palletTypes = ["Vibrant", "LightVibrant", "DarkVibrant", "Muted", "LightMuted", "DarkMuted"];

    const { podcast } = props;

    const prevIdRef = useRef<string>();
    const [matchedColor, setMatchedColor] = useState();
    const dispatch = useDispatch();
    const { subscribedPodcastList } = useStoreHook();

    const fetchColor = async () => {
        let pallets = await ImageService.getMatchedColors(podcast.imageUrl);
        pallets = [pallets[palletTypes[0]], pallets[palletTypes[1]], pallets[palletTypes[2]]];
        const maxPopulatedPallet = Object.keys(pallets).map((key: string) => pallets[key]).reduce((prev, current) => (prev.population > current.population) ? prev : current)
        setMatchedColor(maxPopulatedPallet.rgb.join());
    }

    const toggleSubscribe = async () => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }
        dispatch({ type: GeneralActionTypes.SUBSCRIBE_PODCAST, payload: podcast.id });
    }

    useEffect(() => {
        if (prevIdRef.current === podcast.id) { return; }
        fetchColor();
        prevIdRef.current = podcast.id;
    }, [podcast.id]);

    return (
        <>
            <BackButton to={`/`} />
            <div className={classes.podcastDetailSection} style={{ 'borderColor': matchedColor ? `rgb(${matchedColor})` : '' }}>
                <div className="bg-white  min-w-160 mobile-m-w-59">
                    <img src={podcast.imageUrl} width={160} loading="lazy" />
                </div>
                <div className={classes.podcastDetailSectionText}>
                    <h2 className="line-clamp-2 podcastDetailTitle">{podcast.title}</h2>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <p className="interTextSecondary flex-grow-1 mb-8 line-clamp-2">{podcast.author?.name}</p>
                        </div>

                        <Button className="btn-rounded btn-h-23 mt-4px" size="sm" variant={subscribedPodcastList[podcast.id] ? 'red' : 'secondary'} onClick={toggleSubscribe}>
                            {subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PodcastDetailSection;