import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RootState } from './reducers';
// import type { RootState, AppDispatch } from './store'

// // Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export const useStoreHook = () => {

    const generalState = useSelector((state: RootState) => state.generalReducer);
    const favouriteEpisodeList = generalState.favouriteEpisodes;
    const subscribedPodcastList = generalState.subscribedPodcasts;
    const loggedUser = generalState.loggedUser;
    return { favouriteEpisodeList, subscribedPodcastList, loggedUser }

}