import { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { endOfWeek, format } from 'date-fns'
import { ACTIVE_USER_FILTER } from '../../../../../const/adminFilters';
import classes from './ActiveUserChart.module.scss';
import { ChartDataType } from '../../../../../common/commonTypes';
import { getLast11Years, getLastMonths, getLast5Weeks, getLastDays, NumberFormatter } from '../../../../utils/utils';

type Props = {
    flag: string,
    data: any[],
    afterDataSet: (data: ChartDataType[]) => void
}

const ActiveUserChart = ({ flag, data, afterDataSet }: Props) => {

    const [chartData, setChartData] = useState<ChartDataType[]>([]);

    const getCountFromData = (date: Date) => {
        const availableData: { date: string, count: string } = data.find((d: { date: string, count: string }) => {
            return format(date, "yyyy-MM-dd") === format(new Date(d.date), "yyyy-MM-dd");
        });
        return availableData ? parseInt(availableData.count) : 0;
    };

    const getDataProps = (): ChartDataType[] => {
        switch (flag) {
            case ACTIVE_USER_FILTER.DAILY:
                const last8Days = getLastDays(8);
                return last8Days.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "MMM, do"),
                        value: getCountFromData(d)
                    }
                });
            case ACTIVE_USER_FILTER.WEEKLY:
                const last5Weeks = getLast5Weeks();
                return last5Weeks.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "dd MMM") + " - " + format(endOfWeek(d, { weekStartsOn: 1 }), "dd MMM"),
                        value: getCountFromData(d)
                    }
                });
            case ACTIVE_USER_FILTER.MONTHLY:
                const last13Months = getLastMonths(13);
                return last13Months.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "LLL, yyyy"),
                        value: getCountFromData(d)
                    }
                });
            case ACTIVE_USER_FILTER.YEARLY:
                const last11Years = getLast11Years();
                return last11Years.map((d: Date) => {
                    return {
                        date: d,
                        key: format(d, "yyyy-MM-dd"),
                        label: format(d, "yyyy"),
                        value: getCountFromData(d)
                    }
                });

            default:
                return []
        }
    }

    const processData = () => {
        const dataPropsSet: ChartDataType[] = getDataProps();
        setChartData(dataPropsSet);
        afterDataSet(dataPropsSet);
    }

    useEffect(() => {
        processData();
    }, [data]);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.customToolTip}>
                    <p className={classes.label}>{`${label}`}</p>
                    <p className={classes.intro}>Active Users: {payload[0].value}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={400}
                    margin={{ top: 60, right: 60, left: 20, bottom: 20 }}
                    data={chartData}>
                    <Line type="monotone" dataKey="value" stroke="#53A7BF" activeDot={{ r: 8, }} />
                    <CartesianGrid stroke="#EBECF0" />
                    <XAxis dataKey="label" />
                    <YAxis label={{ value: 'Active Users', angle: -90, position: 'left' }} tickFormatter={NumberFormatter} />
                    <Tooltip content={<CustomTooltip />} />
                    <text x="10" y="10" dominantBaseline="hanging" fontSize="18">{flag} Active Users</text>
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default ActiveUserChart;
