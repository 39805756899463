import React, { ReactElement, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import classes from './PasswordChangeModal.module.scss';
import { useForm } from "react-hook-form";
import { AuthContext, AuthModalViews } from '../../../../../contexts/AuthContext';
import AuthService from '../../../../../services/auth.service';
import backIcon from '../../../../../assets/images/Expand_left_double.svg';
import { LoadingContext } from '../../../../../contexts/LoadingContext';
import { useSearchParams } from 'react-router-dom';

type Props = {
    data: {
        handleClose: () => void
    }
}

const PasswordChangeModal = (props: Props): ReactElement => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    const { showLoader } = useContext(LoadingContext);
    const { handleClose } = props.data;
    const {
        setAuthModalView
    } = React.useContext(AuthContext);

    const onSubmit = async (data: any) => {
        const token = searchParams.get("resetToken");
        if (token) {
            showLoader(true);
            try {
                const reset = await AuthService.changePassword({ ...data, token });
                handleClose();
                showLoader(false);
            } catch (error) {
                showLoader(false);
            }
        }
    }

    return (
        <>
            <div className={classes.resetModal}>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="modal-header">
                            <div>
                                <div className="mb-3">
                                    <a onClick={() => setAuthModalView(AuthModalViews.LOGIN)} className="interLink text-uppercase"><img src={backIcon} alt="go back icon" />Back</a>
                                </div>
                                <div className="modal-title h5">Enter New Password</div>
                            </div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                            </button>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    {...register('password', { required: true })}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`} />

                                <Form.Control.Feedback type="invalid">
                                    {errors.password && errors.password.type === "required" && <span>This field is required</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formConfirmPassword">
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    {...register('confirmPassword', { required: true })}
                                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />

                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword && errors.confirmPassword.type === "required" && <span>This field is required</span>}
                                    {errors.confirmPassword && errors.confirmPassword.type === "invalidPassword" && <span>Password and Confirm Passwords do not match!</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='modal-footer'>
                                <Button type='submit' variant="secondary">
                                    Update Password
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PasswordChangeModal;