import { ReactElement } from 'react';
import classes from './UnauthorizedContent.module.scss';

const UnauthorizedContent = (): ReactElement => {

    return (
        <>
            <div className='d-flex flex-column'>
                <h1 className={`text-center ${classes.heading}`} >401</h1>
                <h3 className={`text-center ${classes.paragraph}`}>You are not authorized to access this page.</h3>
            </div>
        </>
    )
};

export default UnauthorizedContent;