export enum ANALYTICS_ACTIONS {
    SUBSCRIBED = "SUBSCRIBED",
    UNSUBSCRIBED = "UNSUBSCRIBED",
    ENROLLED = "ENROLLED",
    BOOKMARKED = "BOOKMARKED",
    BOOKMARK_REMOVED = "BOOKMARK_REMOVED",
    PLAYED = "PLAYED",
    SHARE = "SHARE",
    VIEW = "VIEW"
}
