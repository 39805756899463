import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import logo from './../../assets/logo.svg';
import classes from './AdminNavBar.module.scss';
import { useLocation, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { AuthContext } from '../../../../../contexts/AuthContext';
import menuHome from './../../../../../assets/images/home-admin.svg';
import menuPodcast from './../../../../../assets/images/my-podcasts.svg';
import menuDiscover from './../../../../../assets/images/discovery-admin.svg';
import menuSettings from './../../../../../assets/images/user-admin.svg';
import menutoggle from './../../../../../assets/images/menu.svg';
import CustomLink from '../../../_shared/CustomLink/CustomLink';
import { ResponsiveContext } from '../../../../../contexts/ResponsiveContext';


type Props = {
}

const AdminNavBar = ({ children }: PropsWithChildren<Props>) => {

  const location = useLocation();

  const {
    authenticated,
  } = React.useContext(AuthContext);
  const { menuExpanded, setMenuExpanded } = React.useContext(ResponsiveContext);

  return (
    <>
      <div className={`${classes.leftNavBar} ${menuExpanded ? 'menu-expanded' : ''}`}>
        <h6 className="text-uppercase">Menu</h6>
        <Nav defaultActiveKey="/admin" className="flex-column flex-grow-1">
          <CustomLink to="/admin" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuHome} loading="lazy" width="18" />
            </span>Dashboard</CustomLink>
          <CustomLink to="/admin/discovery" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuDiscover} loading="lazy" width="18" />
            </span>Discovery Settings</CustomLink>

          <CustomLink to="/admin/chart-topper" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuDiscover} loading="lazy" width="18" />
            </span>Chart Toppers</CustomLink>

          <CustomLink to="/admin/podcasts" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuSettings} loading="lazy" width="18" />
            </span>Podcasts</CustomLink>
          <CustomLink to="/admin/users" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuSettings} loading="lazy" width="18" />
            </span>Users</CustomLink>
          <CustomLink to="/admin/blogs" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuSettings} loading="lazy" width="18" />
            </span>Blogs</CustomLink>
          <CustomLink to="/admin/pages" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuSettings} loading="lazy" width="18" />
            </span>Pages</CustomLink>

          <div className="mobile-only-menu">
            <CustomLink to="/about-faq" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>About/FAQ</CustomLink>
            <CustomLink to="/for-podcasters" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>For Podcasters</CustomLink>
            <CustomLink to="/contact" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>Contact</CustomLink>
            <CustomLink to="/blogs" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>Blogs</CustomLink>
            <CustomLink to="/legal" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>Legal</CustomLink>
          </div>

        </Nav>
        <div className={`${classes.leftNavBarExpandIcon} mobile-menu-icon`}>
          <img src={menutoggle} loading="lazy" width="36" onClick={() => setMenuExpanded(!menuExpanded)} />
        </div>
      </div>
    </>
  );
}

export default AdminNavBar;
