import React, { ReactElement, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import CustomLink from '../CustomLink/CustomLink';
import classes from './Footer.module.scss';

type Props = {
}

const Footer = (): ReactElement => {

    return (
        <>
            <div className={classes.interFooter}>
                <div className="container-1245 d-flex flex-column flex-md-row align-items-center">
                    <div className="flex-grow-1 d-none d-md-flex flex-column">
                        <ul>
                            <li><CustomLink to="/about-faq" activeClass={classes.activeLink}>ABOUT/FAQ</CustomLink></li>
                            <li><CustomLink to="/for-podcasters" activeClass={classes.activeLink}>FOR PODCASTERS</CustomLink></li>
                            <li><CustomLink to="/contact" activeClass={classes.activeLink}>CONTACT</CustomLink></li>
                            <li><CustomLink to="/blogs" activeClass={classes.activeLink}>BLOGS</CustomLink></li>
                            <li><CustomLink to="/legal" activeClass={classes.activeLink}>LEGAL</CustomLink></li>
                        </ul>
                    </div>
                    <div className="text-right">
                        <p className="mb-0">© Intercast.fm {new Date().getFullYear()}</p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Footer;