import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingContext } from '../../../contexts/LoadingContext';
import BlogService from '../../../services/blog.service';
import BackButton from '../../components/_shared/BackButton/BackButton';
import BlogView from '../../components/_shared/BlogView/BlogView';
import classes from './Blog.module.scss';
import { formatDate } from '../../utils/utils';

const Blog = () => {

    let { id } = useParams();
    const { showLoader } = useContext(LoadingContext);

    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState<any>({});

    const fetchBlog = async () => {
        const blogItem = await BlogService.getById(id);
        setBlog(blogItem);
        showLoader(false);
        setLoading(false);
    }

    useEffect(() => {
        if (!id || loading) { return; }
        showLoader(true);
        fetchBlog();
    }, [id]);

    return (
        <>
            <div className={`interPageWhite ${classes.blog}`}>
                <div className="row">
                    <div className="col-md-12">
                        <BackButton to='/blogs' className='text-muted' />
                        <div className="row">
                            <div className="col-md-4 col-xl-3">
                                <h4 className="interBodyTitle text-green-400 mb-4">{blog.title}</h4>
                                {blog.author && <h6 className="interTextGray fw-normal  mb-0">By {blog.author}</h6>}
                                {blog.publishedDate && <p className="interTextGray"><small>Publisihed On {formatDate(blog.publishedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", 'MMMM dd, Y')}</small></p>}
                            </div>
                            <div className="col-md-8 col-xl-9 mb-4">
                                {blog && <BlogView blog={blog} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Blog;