import { ReactElement, useContext } from 'react';
import logo from './../../../../assets/images/logo-sm.svg';
import logoxs from './../../../../assets/images/logo-xs.png';
import SearchPanel from '../SearchPanel/SearchPanel';
import classes from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import LoginButton from '../LoginButton/LoginButton';
import CustomLink from '../CustomLink/CustomLink';
import { AuthContext } from '../../../../contexts/AuthContext';

type Props = {
    featuredHeader?: boolean
}

const Header = (props: Props): ReactElement => {

    const { authenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const { featuredHeader } = props;

    const onSelectPodcast = (podcast: any) => {
        navigate(`/podcast/${podcast.id}`);
    }

    return (
        <>
            <div className={`${featuredHeader ? classes.featuredHeader : ''} ${classes.interHeader}`}>
                <div className="container-1245 d-flex align-items-center justify-content-end">
                    {featuredHeader && (
                        <>
                            <CustomLink to={authenticated ? "/my-podcasts" : "/"}>
                                <img className={classes.featuredHeaderLogo} src={logo} loading="lazy" />
                                <img className={classes.featuredHeaderLogoXs} src={logoxs} loading="lazy" />
                            </CustomLink>
                            <div className="interHeaderSearch">
                                <SearchPanel onSelectPodcast={onSelectPodcast} />
                            </div>

                        </>
                    )}
                    <div className="interHeaderLoginDetails">
                        <LoginButton />
                    </div>

                </div>

            </div>
        </>
    )
};

export default Header;