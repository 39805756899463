import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classes from './ShowNotesSection.module.scss';

type Props = {
    episode: any
}

const ShowNotesSection = (props: Props): ReactElement => {
    const { episode } = props;
    const [description, setDescription] = useState('');

    const isUrlAbsolute = (url: string) => (url.indexOf('//') === 0 ? true : url.indexOf('://') === -1 ? false : url.indexOf('.') === -1 ? false : url.indexOf('/') === -1 ? false : url.indexOf(':') > url.indexOf('/') ? false : url.indexOf('://') < url.indexOf('.') ? true : false)

    const getFormattedDescription = (text: string) => {
        const re = new RegExp('href="(.*?)"', "g");
        text = text.replace(re, (matchedCase: any) => {
            const ex = re.exec(matchedCase);
            if (ex && ex.length > 1 && !isUrlAbsolute(ex[1])) {
                let matchedUrl = ex[1];
                matchedUrl = `http://${matchedUrl}`;
                return matchedCase.replace(re, "target=\"_blank\" href=\"" + matchedUrl + "\"");
            }
            return "target=\"_blank\" " + matchedCase;
        });
        return text;
    }

    useEffect(() => {
        if (episode && episode.htmlDescription) {
            setDescription(getFormattedDescription(episode.htmlDescription));
        }
    }, [episode]);

    return (
        <>
            <div className={`${classes.noteDescription} interTextSecondary`} dangerouslySetInnerHTML={{ __html: description }} />
        </>
    )
};

export default ShowNotesSection;