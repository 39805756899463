import React, { ReactElement, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AuthContext, AuthModalViews } from '../../../../contexts/AuthContext';
import classes from './AuthModal.module.scss';
import LoginModal from './Login/LoginModal';
import PasswordChangeModal from './PasswordChangeModal/PasswordChangeModal';
import PasswordResetModal from './PasswordResetModal/PasswordResetModal';
import RegisterModal from './RegisterModal/RegisterModal';

type Props = {
}

const AuthModal = (): ReactElement => {

    const {
        authModalView,
        authModalOpened,
        setAuthModalOpened,
        setAuthModalView
    } = React.useContext(AuthContext);

    const closeAuthModal = () => {
        setAuthModalView(null);
        setAuthModalOpened(!authModalOpened);
    }

    return (
        <>
            <div className={classes.authModal}>
                <Modal show={authModalOpened} onHide={closeAuthModal} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="interDarkModal">
                    <Modal.Body>
                        {authModalView === AuthModalViews.LOGIN && <LoginModal data={{ handleClose: closeAuthModal }} />}
                        {authModalView === AuthModalViews.REGISTER && <RegisterModal data={{ handleClose: closeAuthModal }} />}
                        {authModalView === AuthModalViews.FORGOT && <PasswordResetModal data={{ handleClose: closeAuthModal }} />}
                        {authModalView === AuthModalViews.RESET_PASSWORD && <PasswordChangeModal data={{ handleClose: closeAuthModal }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
};

export default AuthModal;