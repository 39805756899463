import classes from './About.module.scss';
import backIcon from './../../../assets/images/Expand_left_double.svg';
import { useContext, useEffect, useState } from 'react';
import ContentService from '../../../services/content.service';
import CONTENT_TYPES from '../../../const/contentTypes';
import { LoadingContext } from '../../../contexts/LoadingContext';
import ReactHtmlParser from 'react-html-parser';
import BackButton from '../../components/_shared/BackButton/BackButton';

const About = () => {

    const { showLoader } = useContext(LoadingContext);
    const [loaded, setLoaded] = useState(false);

    const [about, setAbout] = useState("");
    const [faq, setFaq] = useState("");

    const fetchContent = async () => {
        showLoader(true);
        const aboutContent = await ContentService.getByType(CONTENT_TYPES.ABOUT);
        const faqContent = await ContentService.getByType(CONTENT_TYPES.FAQ);

        setAbout(aboutContent ? aboutContent.content : "")
        setFaq(faqContent ? faqContent.content : "")
        showLoader(false);
        setLoaded(true);
    }

    useEffect(() => {
        if (loaded) { return; }
        fetchContent()
    }, []);

    return (
        <>
            <div className={`interPageWhite ${classes.about}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-5">
                        <BackButton to={`/`} className="dark" />
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <h3 className="interBodyTitle active">About</h3>
                            </div>
                            <div className="col-md-8 col-lg-9  mb-4">
                                <div className="row pt-4">
                                    <div className="col-12 dynamicContent">
                                        {ReactHtmlParser(about)}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <h3 className="interBodyTitle active">FAQ</h3>
                            </div>
                            <div className="col-md-8 col-lg-9">
                                <div className="row pt-4">
                                    <div className="col-12 mb-4 dynamicContent">
                                        {ReactHtmlParser(faq)}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
};

export default About;