import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import SearchPanel from '../../../../components/_shared/SearchPanel/SearchPanel';
import classes from './AddDiscoverPodcast.module.scss';

type Props = {
    onHide: (podcast: any) => void
}

const AddDiscoverPodcast = (props: Props) => {

    const [show, setShow] = useState(true);
    const [selectedPodcast, setSelectedPodcast] = useState(null);

    const handleClose = () => {
        setShow(false);
        props.onHide(null);
    };

    const onSelectPodcast = (podcast: any) => {
        setShow(false);
        props.onHide(podcast);
    }

    return (
        <>
            <div className={`${classes.discovery}`}>
                <Modal show={show} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="interDarkModal">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="pt-1">
                        <p className="mb-2">Add a podcast</p>
                        <div className="addPodcastSearchBar ml--15">
                            <SearchPanel onSelectPodcast={onSelectPodcast} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
};

export default AddDiscoverPodcast;