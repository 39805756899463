import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Image, Modal } from 'react-bootstrap';
import { AuthContext, AuthModalViews } from '../../../../contexts/AuthContext';

import UserImageDefault from './../../../../assets/images/UserImageDefault.png';
import classes from './LoginButton.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import menuSettings from './../../../../assets/images/menu/settings.png';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import AuthService from '../../../../services/auth.service';
import { useStoreHook } from '../../../../store/hooks';
import { GeneralActionTypes } from '../../../../store/actions/generalAction';
import { useDispatch } from 'react-redux';

type Props = {
}

const UserImageToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <>
        <div className={classes.profileImage}
            ref={ref} onClick={(e: any) => {
                e.preventDefault();
                onClick(e);
            }}>
            {children}
        </div>
    </>
));

const LoginButton = (): ReactElement => {

    const { isMobileDevice } = useContext(ResponsiveContext);
    const navigate = useNavigate();
    const {
        authenticated,
        isAdmin,
        authModalOpened,
        setAuthModalOpened,
        setAuthModalView,
        setToken
    } = React.useContext(AuthContext);

    const { loggedUser } = useStoreHook();
    const dispatch = useDispatch();

    const [confirmLogoutModal, setConfirmLogoutModal] = useState(false);
    const [loggedUserImage, setLoggedUserImage] = useState(UserImageDefault);

    const openAuthModal = () => {
        setAuthModalView(AuthModalViews.LOGIN);
        setAuthModalOpened(!authModalOpened);
    }

    const logout = async () => {
        try {
            await AuthService.logout();
        } catch (error) {
        }
        dispatch({ type: GeneralActionTypes.SET_FAVOURITE_EPISODES, payload: [] });
        dispatch({ type: GeneralActionTypes.SET_SUBSCRIBED_PODCASTS, payload: [] });
        dispatch({ type: GeneralActionTypes.SET_LOGGED_USER, payload: null })
        setToken("");
        setConfirmLogoutModal(false);
        navigate("/");
    }

    const redirectTo = (path: string) => {
        navigate(path);
    }

    const onDropdownSelect = (key: string) => {
        switch (key) {
            case 'logout':
                setConfirmLogoutModal(true);
                break;
            case 'redirect-admin':
                redirectTo('/admin');
                break;
            case 'my-podcasts':
                redirectTo('/my-podcasts');
                break;
            case 'discover':
                redirectTo('/discover');
                break;
            case 'settings':
                redirectTo('/settings');
                break;
        }
    }

    useEffect(() => {
        if (loggedUser && loggedUser.profileImage) {
            setLoggedUserImage(`${process.env.REACT_APP_PROFILE_IMAGE_URL}${loggedUser.profileImage}`);
        }
    }, [loggedUser]);

    return (
        <>
            {!isMobileDevice ? (
                <div className='d-flex justify-content-end'>
                    {!authenticated ? (
                        <div>
                            <Button className="d-none d-md-block" variant={'primary'} onClick={openAuthModal}>
                                Login / Sign-up
                            </Button>
                            <Button className="d-block d-md-none min-w-auto" variant={'primary'} onClick={openAuthModal}>
                                Login
                            </Button>
                        </div>
                    ) : (
                        <Dropdown onSelect={(e: any) => onDropdownSelect(e)}>
                            <Dropdown.Toggle as={UserImageToggle} id="dropdown-custom-components">
                                <Image
                                    src={loggedUserImage} width={35} height={35}
                                    roundedCircle={true}
                                    alt="User Image" /> <span className='text-white'>{loggedUser?.firstName}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {isAdmin && (
                                    <Dropdown.Item eventKey={'redirect-admin'}>
                                        Admin Panel
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item eventKey={'my-podcasts'}>
                                    My Podcasts
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={'discover'}>
                                    Discover
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={'settings'}>
                                    Settings
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={'logout'}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            ) : (

                // Mobile Menu
                !authenticated ? (
                    <a className='cursor-pointer' onClick={openAuthModal}>
                        Login
                    </a>
                ) : (
                    <a className='cursor-pointer' onClick={() => setConfirmLogoutModal(true)}>
                        Logout
                    </a>
                )
            )}


            <Modal show={confirmLogoutModal} >
                <Modal.Header >
                    <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want sign out?</Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-light' onClick={(event) => {
                        setConfirmLogoutModal(false);
                    }}>
                        No
                    </button>
                    <button className='btn btn-danger' onClick={(event) => logout()}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>




        </>
    )
};

export default LoginButton;