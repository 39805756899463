import { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import CONTENT_TYPES from '../../../../const/contentTypes';
import ContentService from '../../../../services/content.service';
import ContentEditor from '../../../components/_shared/ContentEditor/ContentEditor';
import classes from './AdminContentPages.module.scss';
import PageEditSection from './PageEditSection/PageEditSection';

const AdminContentPages = () => {

    const pages = [
        { key: CONTENT_TYPES.ABOUT, title: 'About' },
        { key: CONTENT_TYPES.FAQ, title: 'FAQ' },
        { key: CONTENT_TYPES.FOR_PODCASTER, title: 'For Podcasters' },
        { key: CONTENT_TYPES.LEGAL, title: 'Legal' }
    ]

    const [loading, setLoading] = useState(true);
    const [contentMap, setContentMap] = useState<any>({});

    const getAllContents = async () => {
        const contents = await ContentService.getAll();
        processContent(contents);
    }

    const processContent = (contents: any[]) => {
        const data: any = {};
        contents.forEach((content: any) => {
            data[content.type] = content;
        });
        setContentMap(data);
        setLoading(false);
    }

    useEffect(() => {
        getAllContents();
    }, []);


    return (
        <>
            <div className={`interPageWhite ps-md-0 adminPages ${classes.adminPages}`}>
                <div className="interPageInner">
                    <h3 className="interBodyTitle Inactive mt-3 mt-md-5 mb-4">Content Pages</h3>

                    {!loading && (
                        <Tab.Container id="left-tabs-example" defaultActiveKey="about">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        {pages.map((page: any, index: number) => (
                                            <Nav.Item key={index}>
                                                <Nav.Link eventKey={page.key}>{page.title}</Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        {pages.map((page: any, index: number) => (
                                            <Tab.Pane key={index} eventKey={page.key}>
                                                <PageEditSection page={page.key} content={contentMap[page.key]} />
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    )}
                </div>
            </div>
        </>
    )
};

export default AdminContentPages;