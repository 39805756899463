import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import classes from './EpisodeDetailSection.module.scss';
import share from '../../../assets/images/Export.svg';
import { Button, Modal } from 'react-bootstrap';
import ImageService from '../../../services/image.service';
import BackButton from '../_shared/BackButton/BackButton';
import FavouriteButton from '../_shared/FavouriteButton/FavouriteButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Clipboard } from 'react-bootstrap-icons';
import { AuthContext } from '../../../contexts/AuthContext';
import AnalyticsService from '../../../services/analytics.service';
import { ANALYTICS_ACTIONS } from '../../../const/analytics';
import { formatDate } from '../../utils/utils';
import { toast } from 'react-toastify';

type Props = {
    episode: any,
    isFavourite: boolean
}

const EpisodeDetailSection = (props: Props): ReactElement => {

    const palletTypes = ["Vibrant", "LightVibrant", "DarkVibrant", "Muted", "LightMuted", "DarkMuted"];

    const { episode, isFavourite } = props;

    const { authenticated } = useContext(AuthContext);
    const prevIdRef = useRef<string>();
    const [matchedColor, setMatchedColor] = useState();
    const [shareModal, setShareModal] = useState(false);
    const [copyUrl] = useState(window.location.href);
    const [copied, setCopied] = useState(false);

    const fetchColor = async () => {
        let pallets = await ImageService.getMatchedColors(episode.imageUrl || episode.podcast.imageUrl);
        pallets = [pallets[palletTypes[0]], pallets[palletTypes[1]], pallets[palletTypes[2]]];
        const maxPopulatedPallet = Object.keys(pallets).map((key: string) => pallets[key]).reduce((prev, current) => (prev.population > current.population) ? prev : current)
        setMatchedColor(maxPopulatedPallet.rgb.join());
    }

    const afterCopyLink = () => {
        setCopied(true);
        if (!copied && authenticated) {
            AnalyticsService.create(ANALYTICS_ACTIONS.SHARE, episode.id, episode.podcast.id).then()
        }
    }

    useEffect(() => {
        if (shareModal) {
            setCopied(false);
        }
    }, [shareModal]);


    useEffect(() => {
        if (prevIdRef.current === episode.id) { return; }

        fetchColor();
        prevIdRef.current = episode.id;
    }, [episode.id]);

    return (
        <>
            <BackButton to={`/podcast/${episode.podcast.id}`} />
            <div className={classes.episodeDetailSection} style={{ 'borderColor': matchedColor ? `rgb(${matchedColor})` : '' }}>
                <div className="bg-white min-w-160 mobile-m-w-59">
                    <img src={episode.imageUrl || episode.podcast.imageUrl} width={160} loading="lazy" />
                </div>
                <div className={classes.episodeDetailSectionText}>
                    <h2 className="line-clamp-2 podcastDetailTitle">{episode.title}</h2>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <p className="interTextSecondary flex-grow-1 mb-8 line-clamp-2">{formatDate(episode.airDate, 'yyyy-MM-dd HH:mm:ss', 'MMMM dd, Y')}</p>
                            <FavouriteButton episode={episode} />
                        </div>

                        <Button className="btn-rounded btn-h-23  mt-4px d-flex align-items-center justify-content-center" size="sm" variant={'secondary'} onClick={() => setShareModal(true)}>
                            <span>Share</span>
                            <img src={share} alt="share icon" className="d-flex ms-2" />
                        </Button>
                    </div>
                </div>
            </div>

            <Modal show={shareModal} onHide={() => setShareModal(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.shareModalContent}>
                        <h5>Share this episode</h5>
                        <p>
                            If you like this episode, share it with your friends. <br /> They will thank you later
                        </p>

                        <div>
                            <div className={`d-flex ${classes.copyUrlContainer}`}>
                                <span className={`${classes.url} text-ellipsis`}>{copyUrl}</span>
                                <CopyToClipboard text={copyUrl}
                                    onCopy={() => afterCopyLink()}>
                                    <span className={classes.copyIcon}><Clipboard /></span>
                                </CopyToClipboard>
                            </div>
                            {copied && <div className={classes.copiedText}>Link has been copied to clipboard.</div>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default EpisodeDetailSection;