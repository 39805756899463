import classes from './Blogs.module.scss';
import backIcon from './../../../assets/images/Expand_left_double.svg';
import { useContext, useEffect, useState } from 'react';
import BlogService from '../../../services/blog.service';
import { LoadingContext } from '../../../contexts/LoadingContext';
import CustomLink from '../../components/_shared/CustomLink/CustomLink';
import BackButton from '../../components/_shared/BackButton/BackButton';

const Blogs = () => {

    const { showLoader } = useContext(LoadingContext);
    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState<any[]>([]);

    const fetchBlogs = async () => {
        const blogsList = await BlogService.getPublished();
        setBlogs(blogsList);
        showLoader(false);
        setLoading(false);
    }

    const formatTitle = (title: string) => {
        return title.trim().toLowerCase().replaceAll(' ', '-');
    }

    useEffect(() => {
        if (loading) { return; }
        showLoader(true);
        fetchBlogs();
    }, []);

    return (
        <>
            <div className={`interPageWhite ${classes.blogs}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-5">
                        <BackButton to='/' className='dark' />
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="interBodyTitle active">Blogs</h3>
                            </div>
                            <div className="col-md-12">
                                {blogs.map((blog: any, index: number) => (
                                    <div key={index} className="row pt-4">
                                        <div className="col-md-4">
                                            <h4 className="interBodySubTitle">{blog.title}</h4>
                                            {blog.author && <h6 className="interTextGray fw-normal">By {blog.author}</h6>}
                                        </div>
                                        <div className="col-md-8 mb-4">
                                            <p className="interTextGray fw-300">"{blog.description}"</p>
                                            <CustomLink to={`/blog/${blog.id}/${formatTitle(blog.title)}`} activeClass={classes.leftNavItemActive}>
                                                <button className="btn btn-success btn-h-28">Read Article</button>
                                            </CustomLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Blogs;