import classes from './AdminPodcastAnalytics.module.scss';
import cover from './../../../../assets/images/Podcast_Cover.jpg';
import chart from './../../../../assets/images/line-chart.png';
import AdminPodcastDetailSection from './AdminPodcastDetailSection/AdminPodcastDetailSection';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingContext } from '../../../../contexts/LoadingContext';
import PodcastStoreService from '../../../../services/podcast-store.service';
import AnalyticsService from '../../../../services/analytics.service';
import { TOP_PODCAST_PERIOD_FILTER, TOP_PODCAST_TYPE_FILTER } from '../../../../const/adminFilters';
import AdminPodcastActiveUserChart from './AdminPodcastActiveUserChart/AdminPodcastActiveUserChart';
import AdminPodcastFeedAnalytics from './AdminPodcastFeedAnalytics/AdminPodcastFeedAnalytics';


const AdminPodcastAnalytics = () => {

    let { uid } = useParams();
    const prevIdRef = useRef<string>();

    const { showLoader } = useContext(LoadingContext);

    const [loading, setLoading] = useState(true);
    const [podcast, setPodcast] = useState<any>({} as any);
    const [activeUserData, setActiveUserData] = useState([]);
    const [feedData, setfeedData] = useState([]);

    const fetchPodcast = async () => {

        const podcastResponse = await PodcastStoreService.fetchPodcast(uid!);
        if (podcastResponse) {
            setPodcast(podcastResponse.data.podcast);
        }

        const analyticsData = await AnalyticsService.getPoscastAnalyticsData(uid!, TOP_PODCAST_TYPE_FILTER.LISTENS, TOP_PODCAST_PERIOD_FILTER.WEEK.toLowerCase());
        const feedAnalyticsData = await AnalyticsService.getPoscastFeedAnalyticsData(uid!, TOP_PODCAST_PERIOD_FILTER.WEEK.toLowerCase());
        setActiveUserData(analyticsData);
        setfeedData(feedAnalyticsData);

        setLoading(false);
        showLoader(false);
    }

    const afterDataSet = (data: any) => {

    }

    useEffect(() => {
        if (prevIdRef.current === uid) {
            return;
        }
        showLoader(true);
        setLoading(true);
        fetchPodcast();
        prevIdRef.current = uid;
    }, [uid]);


    return (
        <>
            {loading ? (
                <h5>Loading Podcast...</h5>
            ) : (
                <div className={`interPageWhite ps-md-0 ${classes.podcasts}`}>
                    <div className="interPageInner">
                        <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-5">
                            <h3 className="interBodyTitle Inactive mt-3 mt-md-5 mb-4">Analytics</h3>

                            <AdminPodcastDetailSection podcast={podcast} />

                            <AdminPodcastActiveUserChart uid={uid!} data={activeUserData} afterDataSet={afterDataSet} />

                            <AdminPodcastFeedAnalytics uid={uid!} data={feedData} />

                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default AdminPodcastAnalytics;