import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import backButtonImage from '../../../../assets/images/Expand_left_double.svg';
import { AuthContext } from '../../../../contexts/AuthContext';

type BackButtonProps = {
    to: string,
    className?: string
}

const BackButton = ({ to, className }: BackButtonProps) => {

    let history = useNavigate();
    const { authenticated } = useContext(AuthContext);

    const navigate = () => {
        window.history.go(-1);
    }

    return (
        <>
            <div className={`mb-3`}>
                <div onClick={() => navigate()} className={`interLink text-uppercase ${className}`}>
                    <img src={backButtonImage} alt="go back icon" />Back
                </div>
            </div>
        </>
    )
};

export default BackButton;