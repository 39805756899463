import { endOfWeek, format } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { ChartDataType } from '../../../../common/commonTypes';
import { ACTIVE_USER_FILTER } from '../../../../const/adminFilters';
import { formatDate } from '../../../utils/utils';
import classes from './ChartDescription.module.scss';

type Props = {
    flag: string,
    list: ChartDataType[]
}

const ChartDescription = ({ flag, list }: Props): ReactElement => {

    const [firstList, setFirstList] = useState<ChartDataType[]>([]);
    const [secondList, setSecondList] = useState<ChartDataType[]>([]);

    const getDescriptionText = (item: ChartDataType, isFirst?: boolean) => {
        switch (flag) {
            case ACTIVE_USER_FILTER.DAILY:
                return isFirst ? 'Today' : formatDate(item.date.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "dd MMMM, yyyy");
            case ACTIVE_USER_FILTER.WEEKLY:
                return isFirst ? 'This Week' : formatDate(item.date.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "dd MMMM") + " - " + formatDate(endOfWeek(item.date, { weekStartsOn: 1 }).toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "dd MMMM");
            case ACTIVE_USER_FILTER.MONTHLY:
                return isFirst ? 'This Month' : formatDate(item.date.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "MMMM, yyyy");
            case ACTIVE_USER_FILTER.YEARLY:
                return isFirst ? 'This Year' : formatDate(item.date.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", "yyyy");
        }
        return "";
    }

    const Description = ({ item, isFirst }: { item: ChartDataType, isFirst?: boolean }) => {
        return (
            <div className={classes.descriptionText}>
                <span>{getDescriptionText(item, isFirst)} : </span>
                <span>{item.value > 0 ? (`${item.value} Active user${item.value > 1 ? 's' : ''}`) : "No active users"}</span>
            </div>
        );
    }

    useEffect(() => {
        const middleIndex = Math.ceil(list.length / 2);
        const firstHalf = list.reverse().splice(0, middleIndex);
        const secondHalf = list.reverse().splice(-middleIndex);
        setFirstList(firstHalf);
        setSecondList(secondHalf.reverse());
    }, [list]);

    return (
        <>
            <div className={`row pt-4 pb-4`}>
                <div className="col-md-6">
                    {firstList.map((item: ChartDataType, index: number) => (
                        <Description key={index} item={item} isFirst={index === 0} />
                    ))}
                </div>
                <div className="col-md-6">
                    {secondList.map((item: ChartDataType, index: number) => (
                        <Description key={index} item={item} />
                    ))}
                </div>
            </div>
        </>
    )
};

export default ChartDescription;