import axios from "axios"
import { toast } from "react-toastify";
// import { IEpisode, IListenNoteSearchResponse, IPodcast } from "../interface/pod_store";

const PodcastStoreService = {
    searchPodcasts: async (searchQuery: string) => {
        try {
            const response = await axios.get<any>(`/api/v1/pod_store/search?q=${searchQuery}`);
            return response.data;
        } catch (error) {
            console.error("Failed to search poscasts from store", error);
            toast("Failed to search podcasts! Please contact system administrator.", { type: 'error' });
            return null;
        }
    },

    fetchPodcast: async (uid: string, pageNumber: number = 0) => {
        try {
            const response = await axios.get<any>(`/api/v1/pod_store/podcast/${uid}/${pageNumber}`);
            return response.data;
        } catch (error) {
            toast("Failed to load podcast! Please contact system administrator.", { type: 'error' });
            console.error("Failed to fetch poscasts from store", error);
            return null;
        }
    },

    fetchEpisode: async (uid: string) => {
        try {
            const response = await axios.get<any>(`/api/v1/pod_store/episode/${uid}`);
            return response.data;
        } catch (error) {
            console.error("Failed to fetch episode from store", error);
            toast("Failed to load episode! Please contact system administrator.", { type: 'error' });
            return null;
        }
    }
}

export default PodcastStoreService;