import { useContext, useEffect, useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../../../../contexts/LoadingContext';
import BlogService from '../../../../../services/blog.service';
import BackButton from '../../../../components/_shared/BackButton/BackButton';
import BlogView from '../../../../components/_shared/BlogView/BlogView';
import ContentEditor from '../../../../components/_shared/ContentEditor/ContentEditor';
import classes from './AdminBlog.module.scss';

import { Pencil } from 'react-bootstrap-icons';
import { formatDate } from '../../../../utils/utils';

const AdminBlog = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const { showLoader } = useContext(LoadingContext);

    const [loading, setLoading] = useState(false);
    const [editBlog, setEditBlog] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('edit');
    const [blog, setBlog] = useState<any>();
    const [content, setContent] = useState<any>();

    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [description, setDescription] = useState("");

    const fetchBlog = async () => {
        const blogItem = await BlogService.getById(id);
        setBlog(blogItem);
        setContent(blogItem.content || "");

        setTitle(blogItem.title);
        setAuthor(blogItem.author);
        setDescription(blogItem.description);

        showLoader(false);
        setLoading(false);
    }

    const redirectTo = (path: string) => {
        navigate(path);
    }

    const bindBlogContent = async (onSave: boolean = false) => {
        return new Promise((resolve) => {
            var current = { ...blog };
            current.content = content;

            if (onSave && editBlog) {
                current.title = title;
                current.description = description;
                current.author = author;
            }

            setBlog(current);
            resolve(current);
        });
    }

    const onTabSelect = async (tabIns: any) => {
        setActiveTab(tabIns);
        if (tabIns === 'preview') {
            await bindBlogContent();
        }
    }

    const updateContent = async (isPublish = false) => {
        const updatedBlog: any = await bindBlogContent(true);
        if (isPublish) {
            updatedBlog.isPublished = true;
        }
        await BlogService.update(updatedBlog);
        toast(isPublish ? "Blog published successfully!" : "Blog content drafted!", { type: 'success' });
        if (isPublish) {
            redirectTo('/admin/blogs');
        }
    }

    useEffect(() => {
        if (!id || loading) { return; }
        showLoader(true);
        fetchBlog();
    }, [id]);

    const ButtonSection = () => {
        return (
            <>
                {blog.isPublished ? (
                    <>
                        <button className={`btn btn-success ${classes.publishBtn}`} onClick={() => updateContent(true)}>Update Content</button>
                    </>
                ) : (
                    <>
                        <button className={`btn btn-danger ${classes.publishBtn}`} onClick={() => updateContent(true)}>Publish</button>
                        <button className='btn btn-secondary' onClick={() => updateContent()}>Save Draft</button>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <div className={`interPageWhite ps-md-0 ${classes.blog}`}>
                {blog && (
                    <>
                        <div className="row">
                            <BackButton to='/admin/blogs' className='text-muted' />
                            {editBlog ? (
                                <div className="col-md-12">

                                    <div className='d-flex'>
                                        <button className='btn btn-link ml-auto' onClick={() => setEditBlog(!editBlog)}>Cancel</button>
                                    </div>

                                    <Form.Group className="mb-3" controlId="formBasicTitle">
                                        <input type="text" value={title} onChange={event => setTitle(event.target.value)} placeholder="Title" className={classes.formControl} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicAuthor">
                                        <input type="text" value={author} onChange={event => setAuthor(event.target.value)} placeholder="Author" className={classes.formControl} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicDescription">
                                        <textarea rows={4} value={description} onChange={event => setDescription(event.target.value)} placeholder="Description" className={classes.formControl} required></textarea>
                                    </Form.Group>
                                </div>
                            ) : (
                                <div className="col-md-12">
                                    <h4 className="interBodySubTitle">{!blog.isPublished && <span>[DRAFT] </span>}{blog.title} <span className='cursor-pointer' onClick={() => setEditBlog(!editBlog)}><Pencil /></span></h4>
                                    {blog.author && <h5 className="interTextGray fw-normal">By {blog.author}</h5>}
                                    {blog.publishedDate && <p className="interTextGray">Publisihed On {formatDate(blog.publishedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", 'MMMM dd, Y')}</p>}
                                    <p className="interTextGray">"{blog.description}"</p>
                                </div>
                            )}

                            <div className="col-md-12">
                                <div className='w-100 d-flex'>
                                    <ButtonSection />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className="col-md-12">
                                <Tabs activeKey={activeTab} onSelect={(k: any) => onTabSelect(k)}>
                                    <Tab eventKey="edit" title="Edit Content">
                                        <ContentEditor defaultValue={blog.content} onContentChange={setContent} />
                                    </Tab>
                                    <Tab eventKey="preview" title="Preview">
                                        <BlogView blog={blog} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className='w-100 d-flex'>
                                    <ButtonSection />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
};

export default AdminBlog;