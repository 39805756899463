import axios from "axios"
import { toast } from "react-toastify";

const CollectionService = {
    save: async (formData: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/collection`, formData, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to save collection", { type: 'error' });
            return {};
        }
    },

    getAll: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/collection`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to fetch collection", { type: 'error' });
            return [];
        }
    },

    getAllChartCategories: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/collection/categories`, config);
            return response.data;
        } catch (error) {
            toast("Failed to fetch collection categories", { type: 'error' });
            console.error(error);
            return [];
        }
    },

    getAllChartTopper: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/collection/chart-topper/CHART_TOPPER`, config);
            return response.data;
        } catch (error) {
            toast("Failed to fetch chart toppers", { type: 'error' });
            console.error(error);
            return [];
        }
    },

    addChartTopper: async (category: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/collection/chart-topper/CHART_TOPPER`, { category }, config);
            return response.data;
        } catch (error) {
            toast("Failed to add to chart toppers", { type: 'error' });
            console.error(error);
            return null;
        }
    },

    syncChartTopper: async () => {
        try {
            const response = await axios.get(`/api/v1/sync/chart-topper`);
            return response.data;
        } catch (error) {
            toast("Failed to sync chart toppers", { type: 'error' });
            console.error(error);
            return [];
        }
    },

    deleteChartTopper: async (id: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.delete(`/api/v1/collection/chart-topper/CHART_TOPPER/${id}`, config);
            return response.data;
        } catch (error) {
            toast("Failed to delete chart toppers", { type: 'error' });
            console.error(error);
            return null;
        }
    },

    deleteCollection: async (id: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.delete(`/api/v1/collection/${id}`, config);
            return response.data;
        } catch (error) {
            toast("Failed to delete collection", { type: 'error' });
            console.error(error);
            return null;
        }
    },

    deletePodcastFromChartTopper: async (categoryId: any, podcastId: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.delete(`/api/v1/collection/chart-topper/delete-item/${categoryId}/${podcastId}`, config);
            return response.data;
        } catch (error) {
            toast("Failed to delete podcast from chart toppers", { type: 'error' });
            console.error(error);
            return null;
        }
    },

    updateChartTopperOrder: async (orderList: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.put(`/api/v1/collection/chart-topper/update-order`, { orderList, flag: 'CHART_TOPPER' }, config);
            return response.data;
        } catch (error) {
            toast("Failed to update chart topper order", { type: 'error' });
            console.error(error);
            return null;
        }
    }
}

export default CollectionService;