import axios from "axios"

const ImageService = {
    getMatchedColors: async (url: string) => {
        try {
            const response = await axios.get<any>(`/api/v1/image/matched-color?url=${url}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to fetch matched color from URL");
        }
    },

    getSignedURL: async (key: string) => {
        try {
            const response = await axios.get<any>(`/api/v1/image/signed-url?key=${key}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to fetch signed URL");
        }
    }

}

export default ImageService;