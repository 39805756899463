import { useContext } from 'react';
import favourite from '../../../../assets/images/Heart.svg';
import favouriteFilled from '../../../../assets/images/Heart-filled.svg';

import playerFavourite from '../../../../assets/images/heart-bold.svg';
import playerFavouriteFilled from '../../../../assets/images/click-heart.svg';

import { AuthContext } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { GeneralActionTypes } from '../../../../store/actions/generalAction';
import { useStoreHook } from '../../../../store/hooks';

type FavouriteButtonProps = {
    episode: any,
    isPlayer?: boolean
}

const FavouriteButton = ({ episode, isPlayer }: FavouriteButtonProps) => {

    const dispatch = useDispatch();
    const { favouriteEpisodeList } = useStoreHook();
    const { authenticated } = useContext(AuthContext);

    const updateFavourite = async (episode: any) => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }
        dispatch({
            type: GeneralActionTypes.MAKE_FAVOURITE_EPISODE,
            payload: {
                episodeUid: episode.id,
                duration: episode.length,
                podcastUid: episode.podcast.id
            }
        });
    }

    return (
        <>
            <img src={favouriteEpisodeList[episode.id] ? (isPlayer ? playerFavouriteFilled : favouriteFilled) : (isPlayer ? playerFavourite : favourite)} className="cursor-pointer" onClick={(e: any) => {
                e.stopPropagation();
                updateFavourite(episode);
            }} />
        </>
    )
};

export default FavouriteButton;