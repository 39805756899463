import React from 'react';
import { setInterval } from 'timers/promises';
import { AuthContext, AuthModalViews } from '../contexts/AuthContext';
import { usePrevious } from '../hooks/usePrevious';
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import jwt_decode from "jwt-decode";
import { useSearchParams } from 'react-router-dom';

export type AuthProviderProps = {
    defaultAuthenticated?: boolean;
    defaultIsAdmin?: boolean;
    onLogin?: () => void;
    onLogout?: () => void;
    children?: any;
};

export const AuthProvider = ({
    defaultAuthenticated = false,
    defaultIsAdmin = false,
    onLogin,
    onLogout,
    children,
}: AuthProviderProps) => {
    const [authenticated, setAuthenticated] = React.useState(defaultAuthenticated);
    const [isAdmin, setIsAdmin] = React.useState(defaultIsAdmin);

    const [token, setToken] = React.useState<string | null>(null);
    const [refreshToken, setRefreshToken] = React.useState<string | null>(null);

    const [loggedUser, setLoggedUser] = React.useState<any>(null);

    const [authModalOpened, setAuthModalOpened] = React.useState(false);
    const [authModalView, setAuthModalView] = React.useState<AuthModalViews | null>(null);

    const previousAuthenticated = usePrevious(authenticated);

    const [searchParams, setSearchParams] = useSearchParams();

    const clearAuthentication = () => {
        setAuthenticated(false);
        setIsAdmin(false);
        setRefreshToken(null);
        setLoggedUser(null);
        clearTokenStorage();
    }

    const clearTokenStorage = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }

    // const refresh = async () => {
    //     const refreshToken = localStorage.getItem('refresh_token');
    //     const authorized = await AuthService.refreshToken(refreshToken);
    //     if (!authorized || (authorized && !authorized.idToken)) {
    //         clearTokenStorage();
    //         return 0;
    //     }

    //     return 0;
    // }

    // const processRefreshToken = async () => {
    //     const refreshToken = localStorage.getItem('refresh_token');
    //     let interval;
    //     if (refreshToken) {
    //         interval = window.setInterval(() => {
    //             refresh();
    //         }, 5000);
    //     } else {
    //         window.clearInterval(interval);
    //     }
    // }

    React.useEffect(() => {
        if (!previousAuthenticated && authenticated) {
            onLogin && onLogin();
        }
    }, [previousAuthenticated, authenticated, onLogin]);

    React.useEffect(() => {
        if (previousAuthenticated && !authenticated) {
            onLogout && onLogout();
        }
    }, [previousAuthenticated, authenticated, onLogout]);

    React.useEffect(() => {
        if (token === "") {
            clearAuthentication();
            return;
        }
        if (!token) {
            return;
        }

        const decoded: any = jwt_decode(token);
        localStorage.setItem('access_token', token);
        setAuthenticated(true);
        setIsAdmin(decoded && decoded.role && decoded.role === "admin");
    }, [token]);

    React.useEffect(() => {
        if (refreshToken) {
            localStorage.setItem('refresh_token', refreshToken);
        }
    }, [refreshToken]);

    // React.useEffect(() => {
    //     if (authenticated && !loggedUser) {
    //         checkAuth();
    //     }
    // }, [authenticated]);

    React.useEffect(() => {
        const accessTokenST = localStorage.getItem('access_token');
        const refreshTokenST = localStorage.getItem('refresh_token');
        if (accessTokenST) {
            setToken(accessTokenST);
            setRefreshToken(refreshTokenST);
        }
    }, []);

    React.useEffect(() => {
        const accessTokenST = localStorage.getItem('access_token');
        if (searchParams.get("resetToken") && !accessTokenST) {
            setAuthModalOpened(true);
            setAuthModalView(AuthModalViews.RESET_PASSWORD);
        }
    }, [searchParams, authenticated]);

    const contextValue = React.useMemo(
        () => ({
            authenticated,
            setAuthenticated,
            isAdmin,
            setIsAdmin,
            authModalOpened,
            setAuthModalOpened,
            authModalView,
            setAuthModalView,
            token,
            setToken,
            loggedUser,
            setLoggedUser,
            refreshToken,
            setRefreshToken,
        }),
        [authenticated, authModalOpened, authModalView, token, loggedUser, refreshToken, isAdmin]
    );

    return (
        <AuthContext.Provider value={contextValue} > {children} </AuthContext.Provider>
    );
};