import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

type CustomLinkProps = LinkProps & { activeClass?: string }

const CustomLink = ({ children, to, activeClass, ...props }: CustomLinkProps) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <Link
            to={to}
            className={`${props.className} ${match ? activeClass : null}`}
            {...props}
        >
            {children}
        </Link>
    )
};

export default CustomLink;