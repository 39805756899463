import { ReactElement, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import classes from './Player.module.scss';
import SeekBar from './SeekBar/SeekBar';
import back from './../../../../assets/images/Back.svg';
import ahead from './../../../../assets/images/Ahead.svg';
import play from './../../../../assets/images/play.svg';
import volumeUp from './../../../../assets/images/volume-up.svg';
import pause from './../../../../assets/images/pause.svg';
import { useContext } from 'react';
import { PlayerContext } from '../../../../contexts/PlayerContext';
import { useEffect } from 'react';
import EpisodeService from '../../../../services/episode.service';
import { AuthContext } from '../../../../contexts/AuthContext';
import playback from './../../../../assets/images/playback.svg';
import Slider from 'rc-slider';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import FavouriteButton from '../FavouriteButton/FavouriteButton';
import { registerHeightObserver } from '../../../../providers/ResponsiveProvider';
import { secondsToDuration } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useStoreHook } from '../../../../store/hooks';


type Props = {
}

const defaultBottomChangeHeight = 83;
const defaultBottomChangeHeightMob = 43;

const Player = (): ReactElement => {

    const player = useRef<any>();
    const outsideRef = useRef<any>(null);
    const playButton = useRef<any>(null);

    const navigate = useNavigate();
    const { loggedUser } = useStoreHook();

    const { currentEpisode, playing, setPlaying, clearPlayer, muted, setMuted } = useContext(PlayerContext);
    const { authenticated } = useContext(AuthContext);
    const { isMobileDevice } = useContext(ResponsiveContext);

    const [playerInitiated, setPlayerInitiated] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [duration, setSetDuration] = useState(0);

    const [openPlayBackSpeed, setOpenPlayBackSpeed] = useState(false);
    const [playBackSpeed, setPlayBackSpeed] = useState(1);

    const [openVolumeControl, setOpenVolumeControl] = useState(false);
    const [volume, setVolume] = useState<number>(1);

    const [episodeSelected, setEpisodeSelected] = useState(false);
    const [totalTitle, setTotalTime] = useState("00:00:00");

    const [bottomHeight, setBottomHeight] = useState(0);


    const togglePlay = () => {
        setPlaying(!playing);
    }

    const seekLight = (span: number) => {
        let newPlayTime: number = played + span;
        if (newPlayTime >= duration) {
            newPlayTime = duration;
        } else if (newPlayTime <= 0) {
            newPlayTime = 0;
        }
        setPlayed(newPlayTime);
        if (player.current) {
            player.current.seekTo(newPlayTime);
            if (newPlayTime === duration) {
                setPlaying(false);
            }
            if (span > 0 && authenticated) {
                EpisodeService.trackTime(currentEpisode.id, newPlayTime).then();
            }
        }
    }

    const handleSeekMouseDown = (e: any) => {
        setSeeking(true);
    }

    const handleSeekChange = (e: any) => {
        setPlayed(parseFloat(e))
    }

    const handleSeekMouseUp = (e: any) => {
        setSeeking(false);
        if (player.current) {
            player.current.seekTo(parseFloat(e));
            if (authenticated) {
                EpisodeService.trackTime(currentEpisode.id, parseFloat(e)).then();
            }
        }
    }

    const handleProgress = ({ played, playedSeconds, loaded, loadedSeconds }: any) => {
        setPlayed(playedSeconds);
        if (authenticated && currentEpisode && parseInt(playedSeconds) % 10 == 0) {
            EpisodeService.trackTime(currentEpisode.id, playedSeconds).then();
        }
    }

    const handlePlayerReady = (p: ReactPlayer) => {
        // if (isMobileDevice) {
        //     setMuted(false);
        //     setTimeout(() => {
        //         // const btn = document.getElementsByClassName('playButton') as HTMLCollectionOf<any>;
        //         // if (btn.length > 0) {
        //         //     btn[0].click();
        //         //     btn[0].click();
        //         // }
        //         setPlaying(true);
        //     }, 2000);
        // } else {
        //     setPlaying(true);
        //     setMuted(false);
        // }

        // setTimeout(() => {
        // }, 2000);

        // if (player.current) {
        //     player.current.seekTo(p.getCurrentTime());
        // }
        if (isMobileDevice) {
            setTimeout(() => {
                setPlaying(true);
                setMuted(false);
            }, 2000);
        }
        setMuted(false);
        setPlaying(true);
    }

    const handlePlay = () => {
    }

    const handleBuffer = () => {
    }

    const handleBufferEnd = () => {
    }

    const handleDuration = (duration: any) => {
        setSetDuration(duration);
    }

    const handleEnded = () => {
        setPlaying(false);
    }

    const changePlayBackRate = (rate: number) => {
        setPlayBackSpeed(rate);
        setOpenPlayBackSpeed(false);
    }

    const onChangeVolume = (vol: number) => {
        setVolume(vol)
    }

    const configurePlayerPosition = () => {
        const mHeight = isMobileDevice ? defaultBottomChangeHeightMob : defaultBottomChangeHeight;
        const cal = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        if (cal <= mHeight) {
            setBottomHeight(mHeight - cal);
        } else {
            setBottomHeight(0);
        }
    }

    useEffect(() => {
        if (currentEpisode) {
            setEpisodeSelected(true);
        }
    }, [currentEpisode]);

    useEffect(() => {
        window.addEventListener("scroll", configurePlayerPosition);
        return () => {
            window.removeEventListener("scroll", configurePlayerPosition);
        };
    });

    // On unmount player
    useEffect(() => {
        if (!playerInitiated) {
            registerHeightObserver(document.body, { direction: 'vertical' }, function () {
                configurePlayerPosition();
            })
            setPlayerInitiated(true);
            return;
        }
        return () => {
            clearPlayer();
        };
    }, [playerInitiated]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (outsideRef.current && !outsideRef.current.contains(event.target)) {
                setOpenPlayBackSpeed(false);
                setOpenVolumeControl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [outsideRef]);

    useEffect(() => {
        if (duration > 0) {
            setTotalTime(secondsToDuration(duration));
        }
    }, [duration]);

    useEffect(() => {
        if (authenticated && loggedUser && loggedUser.playbackSpeed) {
            setPlayBackSpeed(parseFloat(loggedUser.playbackSpeed));
        } else {
            setPlayBackSpeed(1);
        }
    }, [loggedUser]);

    return (
        <>
            <div className={`${classes.playerContainer}`} style={{ bottom: bottomHeight }}>
                <div className={`${classes.playerWrapper}`}>
                    <div className={`${classes.playerInner}`}>
                        {episodeSelected && (
                            <ReactPlayer
                                style={{ display: "none" }}
                                ref={player}
                                playing={playing}
                                controls={true}
                                url={currentEpisode.audioUrl}
                                playbackRate={playBackSpeed}
                                volume={volume}
                                muted={muted}
                                autoPlay={true}
                                onPlay={handlePlay}
                                onSeek={(e: any) => setPlayed(e)}
                                onEnded={handleEnded}
                                onProgress={handleProgress}
                                onDuration={handleDuration}
                                onReady={handlePlayerReady}
                                onBuffer={handleBuffer}
                                onBufferEnd={handleBufferEnd}
                                config={{
                                    file: {
                                        attributes: {
                                            preload: "auto",
                                        },
                                    },
                                }} />
                        )}

                        <div>
                            <div className={`${classes.timeBar} d-flex align-items-center`}>
                                <span className={classes.currentTime}>{secondsToDuration(played)}</span>
                                <span className={classes.totalTime}>{totalTitle}</span>
                            </div>
                            <SeekBar
                                currentTime={played}
                                duration={duration}
                                onMouseDown={handleSeekMouseDown}
                                onMouseUp={handleSeekMouseUp}
                                onChange={handleSeekChange} />
                        </div>


                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mt-2 playerItem'>
                            <div className="d-flex align-items-center flex-grow-1 mw-50 mw-50-mobile-reset my-2 mb-3 my-sm-0 ">
                                <div className="d-flex align-items-center flex-grow-1">
                                    <div className="playerItem--image">
                                        {episodeSelected && (
                                            <img src={currentEpisode.imageUrl || currentEpisode.podcast.imageUrl} width="50" loading="lazy" />
                                        )}
                                    </div>
                                    <div className="playerItem--desc text-white w-100">
                                        <h6 className="line-clamp-1 mb-0 cursor-pointer" onClick={() => {
                                            if (episodeSelected) {
                                                navigate(`/episode/${currentEpisode.id}`);
                                            }
                                        }}>
                                            {episodeSelected ? currentEpisode.title : 'No episode selected'}
                                        </h6>
                                        <p className="line-clamp-1 cursor-pointer" onClick={() => {
                                            if (episodeSelected && currentEpisode.podcast) {
                                                navigate(`/podcast/${currentEpisode.podcast.id}`);
                                            }
                                        }}>{episodeSelected ? currentEpisode.podcast?.title : ''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-grow-1 w-mobile-100 m-b-mobile">

                                <div className="d-flex align-items-center">
                                    <button className="playerBtn">
                                        <img src={back} alt="back" onClick={() => seekLight(-30)} />
                                    </button>
                                    <button className="playerBtn min-w-55">
                                        {!playing && (<img src={play} alt="play" className='playButton' onClick={togglePlay} />)}
                                        {playing && (<img src={pause} alt="pause" className='playButton' width="21" onClick={togglePlay} />)}
                                    </button>
                                    <button className="playerBtn">
                                        <img src={ahead} alt="forward" onClick={() => seekLight(+30)} />
                                    </button>
                                </div>

                                <div className="d-flex align-items-center">


                                    <div className="position-relative">
                                        {openPlayBackSpeed && (
                                            <div className={classes.playBackSpeedSelector} ref={outsideRef}>
                                                <div className="text-gray-400 cursor-pointer text-nowrap" onClick={() => changePlayBackRate(2)}>
                                                    <span>2x</span>
                                                </div>
                                                <div className="text-gray-400 cursor-pointer text-nowrap py-1" onClick={() => changePlayBackRate(1.5)}>
                                                    <span>1.5x</span>
                                                </div>
                                                <div className="text-gray-400 cursor-pointer text-nowrap py-1" onClick={() => changePlayBackRate(1.25)}>
                                                    <span>1.25x</span>
                                                </div>
                                                <div className="text-gray-400 cursor-pointer text-nowrap" onClick={() => changePlayBackRate(1)}>
                                                    <span>1x</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="text-gray-400 cursor-pointer text-nowrap min-w-45" onClick={() => setOpenPlayBackSpeed(!openPlayBackSpeed)}>
                                            <img className="me-2" src={playback} alt="playback speed" width="14" />
                                            <span>{playBackSpeed}x</span>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center align-item-center px-4">
                                        <FavouriteButton episode={currentEpisode} isPlayer={true} />
                                    </div>

                                    <div className="position-relative">
                                        {openVolumeControl && (
                                            <div className={classes.volumeControlContainer} ref={outsideRef}>
                                                <Slider
                                                    min={0}
                                                    max={1}
                                                    step={0.01}
                                                    value={volume}
                                                    onChange={(vol: any) => onChangeVolume(vol)}
                                                    vertical={true} />
                                            </div>
                                        )}
                                        <button className="playerBtn ps-0" onClick={() => setOpenVolumeControl(!openVolumeControl)}>
                                            <img src={volume !== 0 ? volumeUp : volumeUp} alt="volume" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
};

export default Player;