import { useContext, useEffect, useState } from 'react';
import classes from './AddDiscoveryCollection.module.scss';
import cover from './../../../../../assets/images/collection-box.jpg';
import AddDiscoverPodcast from '../AddDiscoverPodcast/AddDiscoverPodcast';
import CollectionService from '../../../../../services/collection.service';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { LoadingContext } from '../../../../../contexts/LoadingContext';

type Props = {
    featured: boolean,
    collection?: any,
    maxPodcastCount: number,

    cancel?: () => void,
    afterAdd?: (collection: any) => void
    afterDelete?: () => void
}

const AddDiscoveryCollection = (props: Props) => {

    const { maxPodcastCount } = props;

    const { showLoader } = useContext(LoadingContext);
    const [podcasts, setPodcasts] = useState<any[]>([]);
    const [title, setTitle] = useState<string>();

    const [confirmModal, showConfirmModal] = useState<any>(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const onHideAddModal = (podcast: any) => {
        setShowAddModal(false);
        if (podcast) {
            setPodcasts([...podcasts, podcast]);
        }
    }

    const onTitleChange = (event: any) => {
        setTitle(event.target.value)
    }

    const save = async () => {
        const response = await CollectionService.save({
            id: props.collection ? props.collection.id : null,
            title,
            isFeatured: props.featured,
            podcastList: podcasts.map(p => p.id)
        });
        if (props.afterAdd) {
            props.afterAdd(response);
        }
        if (props.cancel) {
            props.cancel();
        }

        toast("Collection created successfully", { type: 'success' });
    }


    const confirmDelete = async () => {
        showLoader(true);
        await CollectionService.deleteCollection(props.collection.id);
        showConfirmModal(false);
        if (props.afterDelete) {
            props.afterDelete();
        }
        showLoader(false);
    }

    const removeItem = (index: number) => {
        setPodcasts([
            ...podcasts.slice(0, index),
            ...podcasts.slice(index + 1, podcasts.length)
        ]);
    }

    useEffect(() => {
        if (props.collection) {
            setPodcasts(props.collection.podcastList);
            setTitle(props.collection.title);
        }
    }, [props.collection]);

    return (
        <>
            <div>
                <div className="d-flex align-items-center placeholder-green">
                    <h5 className="text-gray-100 text-nowrap mb-0">Collection Title: </h5>
                    <input type="text"
                        onChange={onTitleChange}
                        className="form-control ms-2 text-gray-100"
                        value={title} />
                </div>
            </div>
            <div className="d-flex flex-wrap mt-4">
                <>
                    {podcasts.map((p: any, i: number) => (
                        <div key={i} className="interbox">
                            <img src={p.imageUrl} width="120" height="120" title={p.title} />
                            <div className="interbox--remove" onClick={() => removeItem(i)}> - </div>
                        </div>
                    ))}
                    {Array.from(Array(maxPodcastCount - podcasts.length).keys()).map((v: any, i: number) => (
                        <div key={i} className="interbox"> <span className="interbox--plus" onClick={() => setShowAddModal(true)}>+</span> </div>
                    ))}
                </>
            </div>

            <div className='mb-3'>
                {!props.collection ? (
                    <>
                        <button disabled={podcasts.length < maxPodcastCount || !title} className="btn btn-success btn-success--dark btn-lg" onClick={save}>Save</button>
                        <button className="btn btn-danger btn-danger--dark ms-3 btn-lg" onClick={props.cancel}>Cancel</button>
                    </>
                ) : (
                    <>
                        <button disabled={podcasts.length < maxPodcastCount || !title} className="btn btn-success btn-success--dark btn-lg" onClick={save}>Update</button>
                        <button className="btn btn-danger btn-danger--dark ms-3 btn-lg" onClick={() => showConfirmModal(true)}>Delete</button>
                    </>
                )}

            </div>

            {showAddModal && <AddDiscoverPodcast onHide={onHideAddModal} />}

            <Modal show={confirmModal} >
                <Modal.Header >
                    <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this collection?</Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-light' onClick={(event) => {
                        showConfirmModal(false);
                    }}>
                        No
                    </button>
                    <button className='btn btn-danger' onClick={(event) => confirmDelete()}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default AddDiscoveryCollection;