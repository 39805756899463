import { useContext, useEffect, useState } from 'react';
import { TOP_PODCAST_PERIOD_FILTER } from '../../../../../const/adminFilters';
import Select from 'react-select';
import AnalyticsService from '../../../../../services/analytics.service';
import { LoadingContext } from '../../../../../contexts/LoadingContext';
import InteractiveFeed from '../../../../components/_shared/InteractiveFeed/InteractiveFeed';
import { float2DecimalPlaces, NumberFormatter } from '../../../../utils/utils';
import CustomLink from '../../../../components/_shared/CustomLink/CustomLink';
import classes from './AdminPodcastFeedAnalytics.module.scss';

type Props = {
    uid: string,
    data: any[]
}

const AdminPodcastFeedAnalytics = ({ uid, data }: Props) => {

    const dayFilterOptions = [
        { value: TOP_PODCAST_PERIOD_FILTER.WEEK, label: 'Week' },
        { value: TOP_PODCAST_PERIOD_FILTER.MONTH, label: 'Month' },
        { value: TOP_PODCAST_PERIOD_FILTER.YEAR, label: 'Year' },
        { value: TOP_PODCAST_PERIOD_FILTER.ALL, label: 'All Time' }
    ];

    const { showLoader } = useContext(LoadingContext);
    const [initiated, setInitiated] = useState(false);
    const [dateFilterValue, setDateFilterValue] = useState(TOP_PODCAST_PERIOD_FILTER.WEEK);
    const [feeds, setFeeds] = useState<any[]>([]);


    const onFilterChange = async () => {
        showLoader(true);
        const feedAnalyticsData = await AnalyticsService.getPoscastFeedAnalyticsData(uid!, dateFilterValue.toLowerCase());
        processData(feedAnalyticsData);
        showLoader(false);
    }

    const processData = (feedAnalyticsData: any[]) => {
        setFeeds(feedAnalyticsData);
    }

    useEffect(() => {
        if (initiated) { return; }
        processData(data);
        setInitiated(true);
    }, [data]);

    useEffect(() => {
        if (!initiated) { return; }
        onFilterChange();
    }, [dateFilterValue]);

    return (
        <>

            <div className="d-flex align-items-center mt-4 mb-4">
                <h5 className="text-gray-100 text-nowrap">Top interactions this </h5>

                <div className='w-25'>
                    <Select
                        placeholder="day"
                        defaultValue={dayFilterOptions.filter(o => o.value === dateFilterValue)}
                        onChange={(f: any) => setDateFilterValue(f.value)}
                        options={dayFilterOptions}
                        className="form-control--admin ms-2" />
                </div>
            </div>
            <div className='mt-4'></div>

            {feeds.map((feed: any, i: number) => (
                <div className="row" key={i}>
                    <div className="col-lg-9">
                        <InteractiveFeed feed={feed.feed} className={'bg-gray-100'} trackClick={false} />
                    </div>
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <h5 className="text-gray-100">Clicks: {NumberFormatter(feed.clickCount)}</h5>
                        <h5 className="text-gray-100">Views: {NumberFormatter(feed.viewCount)}</h5>
                        <p className={`text-green-400 mb-0 ${classes.conversionRateLabel}`}>Conversion Rate: {float2DecimalPlaces(feed.conversionRate)}%</p>
                        <CustomLink to={`/episode/${feed.episodeUId}`} target={"_blank"}>
                            <span className={`interText-link  ${classes.conversionRateLabel}`}>View Episode</span>
                        </CustomLink>
                    </div>
                </div>
            ))}


        </>
    )
}

export default AdminPodcastFeedAnalytics;
