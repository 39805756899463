import React, { ReactElement, useContext, useEffect, useState } from 'react';
import classes from './Settings.module.scss';
import UserService from './../../../services/user.service';
import ImageService from './../../../services/image.service';
import axios from 'axios';
import { toast } from 'react-toastify';
import AuthService from '../../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import UnauthorizedContent from '../../components/_shared/UnauthorizedContent/UnauthorizedContent';
import { LoadingContext } from '../../../contexts/LoadingContext';
import { useDispatch } from 'react-redux';
import { GeneralActionTypes } from '../../../store/actions/generalAction';
import BackButton from '../../components/_shared/BackButton/BackButton';
import Select from 'react-select';
import { COUNTRIES } from '../../../const/countries';
import { LANGUAGES } from '../../../const/languages';

const PLAYBACK_SPEED = [
    { label: '1x', value: 1 },
    { label: '1.25x', value: 1.25 },
    { label: '1.5x', value: 1.5 },
    { label: '2x', value: 2 },
]

type Props = {
}

const Settings = (): ReactElement => {

    const { authenticated, setToken } = useContext(AuthContext);
    const { showLoader } = useContext(LoadingContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loggedInUser, setLoggedInUser] = useState<any>(null);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [country, setCountry] = useState<string>('US');
    const [language, setLanguage] = useState<string>('en');
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [playback, setPlayback] = useState<number>(1);

    const [profileImageURL, setProfileImageURL] = useState<string>('');

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [subscribeToNotifications, setSubscribeToNotifications] = useState(false);
    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);

    const fetchUser = () => {
        showLoader(true);
        UserService.getLoggedInUser().then(user => {
            if (user) {
                setLoggedInUser(user);
                setFirstName(user.firstName);
                setLastName(user.lastName);
                setEmail(user.email);
                setCountry(user.country || 'US');
                setLanguage(user.language || 'en');
                setPlayback(user.playbackSpeed ? parseFloat(user.playbackSpeed) : 1);
                setSubscribeToNotifications(user.isSubscribeToEmail);
                if (user.profileImage) {
                    setProfileImageURL(`${process.env.REACT_APP_PROFILE_IMAGE_URL}${user.profileImage}`);
                }
                dispatch({ type: GeneralActionTypes.SET_LOGGED_USER, payload: user });
            }
            showLoader(false);
        });
    }

    useEffect(() => {
        fetchUser();
    }, []);

    const onUserDetailUpdate = async (event: any) => {
        try {
            event.preventDefault();
            showLoader(true);
            let profileImageKey = null;
            const userDetails: any = {
                firstName, lastName, email, country, language
            }
            if (profileImage) {
                profileImageKey = await uploadProfileImage();
                userDetails.profileImage = profileImageKey;
            }
            await UserService.update(userDetails);
            fetchUser();

            toast("User details updated successfully", { type: 'success' });
            showLoader(false);
        } catch (error) {
            console.error(error);
            toast("Failed to update user details", { type: 'error' });
            showLoader(false);
        }

    }

    const uploadProfileImage = async () => {
        if (profileImage) {
            const profileImageKey = `${loggedInUser.email.split("@")[0]}-${new Date().getTime()}.${profileImage.name.split('.')[1]}`
            const { signedURL } = await ImageService.getSignedURL(`upload/${profileImageKey}`);

            await axios.put(signedURL, profileImage, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            return profileImageKey;
        }
    }

    const resetPasswordFields = () => {
        setNewPassword("");
        setConfirmPassword("");
        setCurrentPassword("");
    }

    const handlePasswordUpdate = async (event: any) => {
        try {
            event.preventDefault();
            if (newPassword !== confirmPassword) {
                return toast("New passwords do not match", { type: "error" });
            }
            showLoader(true);
            await AuthService.update({ currentPassword, newPassword });
            toast("Password updated successfully", { type: "success" });
            resetPasswordFields();
            showLoader(false);
        } catch (error: any) {
            console.error(error);
            toast(error?.message || "Failed to update the password", { type: "error" });
            showLoader(false);
        }
    }

    const handleSubscribeToNotifications = async (event: any) => {
        try {
            event.preventDefault();
            showLoader(true);
            await UserService.update({ isSubscribeToEmail: subscribeToNotifications });
            fetchUser();

            toast("Subscribed to notifications successfully", { type: 'success' });
            showLoader(false);
        } catch (error) {
            console.error(error);
            toast("Failed to subscribe to notifications", { type: 'error' });
            showLoader(false);
        }
    }

    const handlePlaybackSpeedUpdate = async (event: any) => {
        try {
            event.preventDefault();
            showLoader(true);
            await UserService.update({ playbackSpeed: playback });
            fetchUser();

            toast("Playback speed updated successfully", { type: 'success' });
            showLoader(false);
        } catch (error) {
            console.error(error);
            toast("Failed to update playback speed", { type: 'error' });
            showLoader(false);
        }
    }

    const handleDeactivation = async (event: any) => {
        try {
            event.preventDefault();
            showLoader(true);
            await UserService.deactivate();

            toast("Account deactivated. Signing out...", { type: 'success' });
            navigate("/");
            logout();
            showLoader(false);
        } catch (error) {
            console.error(error);
            toast("Failed to deactivate user account", { type: 'error' });
            showLoader(false);
        }
    }

    const logout = async () => {
        setToken("");
    }


    return (
        <>
            {authenticated ? (
                <div className={classes.settings}>
                    <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">
                        <BackButton to='/' className='dark' />
                        <h3 className="interBodyTitle mb-4">Settings</h3>

                        <h4 className="fw-500 text-white mb-3">Profile</h4>
                        <h5 className="text-white fw-normal">First Name, Last Name, Email address</h5>

                        <div className="row flex-column-reverse flex-md-row">
                            <div className="col-md-8 col-lg-9">
                                <div className="mb-3">
                                    <input className="form-control" type="text" placeholder="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />

                                </div>
                                <div className="mb-3">
                                    <input className="form-control" type="text" placeholder="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <input className="form-control" type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="interImage-uploader mb-3 mt-3 mt-md-0">
                                    {profileImage ? <>
                                        <input type="file" name="file" onChange={(event) => setProfileImage(event.target?.files?.[0] || null)} />
                                        <img src={URL.createObjectURL(profileImage)} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Profile" />
                                    </> : (profileImageURL ? <>
                                        <input type="file" name="file" onChange={(event) => setProfileImage(event.target?.files?.[0] || null)} />
                                        <img src={profileImageURL} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Profile" />
                                    </> : <>
                                        <p className="mb-0">Add<br /> Photo</p>
                                        <p className="mb-0">+</p>
                                        <input type="file" name="file" onChange={(event) => setProfileImage(event.target?.files?.[0] || null)} />
                                    </>)
                                    }
                                </div>
                            </div>


                        </div>
                        <div className="row mb-5">
                            <div className="col-md-8 col-lg-9 d-flex justify-content-between align-items-center">
                                <button className="btn btn-secondary btn-sm" onClick={(event) => { onUserDetailUpdate(event) }}>Save</button>
                                {/* <div>
                                <a className="fw-400 interText-link">Forgot Password?</a>
                            </div> */}
                            </div>
                        </div>

                        <div>
                            <h4 className="fw-500 text-white mb-3">Password</h4>
                            <h5 className="text-white fw-normal">Your email address is {loggedInUser?.email}</h5>

                            <div className="row mb-5">
                                <form onSubmit={(event) => handlePasswordUpdate(event)}>
                                    <div className="col-md-8 col-lg-9 placeholder-green">
                                        <div className="mb-3">
                                            <input className="form-control form-control-green" type="password" placeholder="Current Password" required value={currentPassword} onChange={(event) => setCurrentPassword(event.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <input className="form-control form-control-green" type="password" placeholder="New Password" required value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <input className="form-control form-control-green" type="password" placeholder="Confirm Password" required value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-secondary btn-sm" >Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h4 className="fw-500 text-white mb-3">Podcast Settings</h4>
                            <h5 className="text-white fw-normal">Playback Speed</h5>
                            <div className="row">
                                <div className="col-md-8 col-lg-9 placeholder-green">
                                    <Select
                                        placeholder="Playback Speed"
                                        value={PLAYBACK_SPEED.filter((o: any) => o.value === playback)}
                                        onChange={(f: any) => setPlayback(f.value)}
                                        options={PLAYBACK_SPEED}
                                        className={`form-control--admin countrySelector`} />
                                </div>
                                <div className="col-12 mt-3">
                                    <button className="btn btn-secondary btn-sm" onClick={handlePlaybackSpeedUpdate}>Save</button>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h4 className="fw-500 text-white mb-3">Notifications</h4>
                            <h5 className="text-white fw-normal">Intercast.fm will send you notifications</h5>

                            <div>
                                <div className="form-check">
                                    <label className="form-check-label text-white"><input type="checkbox" className='form-check-input' checked={subscribeToNotifications} onClick={(event: any) => setSubscribeToNotifications(event.target.checked)} /> Receive emails about feature updates</label>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <button className="btn btn-secondary btn-sm" onClick={(event) => { handleSubscribeToNotifications(event) }}>Save</button>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h4 className="fw-500 text-white mb-3">Deactive Account</h4>
                            <div className="row">
                                <div className="col-lg-9">
                                    <h5 className="text-white fw-normal">I want to permanently delete my account and all information associated with it.  </h5>
                                </div>
                            </div>


                            <div className="col-12 mt-3">
                                <button className="btn btn-danger btn-sm" onClick={(event) => setShowDeactivateDialog(true)}>Deactivate</button>
                            </div>
                        </div>

                        <Modal show={showDeactivateDialog} >
                            <Modal.Header >
                                <Modal.Title>Are You Sure?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete your account?</Modal.Body>
                            <Modal.Footer>
                                <button className='btn btn-light' onClick={(event) => setShowDeactivateDialog(false)}>
                                    No
                                </button>
                                <button className='btn btn-danger' onClick={(event) => handleDeactivation(event)}>
                                    Yes! Delete My account.
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            ) : (
                <UnauthorizedContent />
            )}

        </>
    )
};

export default Settings;