import React from 'react';

export type IResponsiveContext = {
    isMobileDevice: boolean;
    isTabDevice: boolean;
    menuExpanded: boolean;
    setMenuExpanded: (expanded: boolean) => void;
};

export const ResponsiveContext = React.createContext<IResponsiveContext>({
    isMobileDevice: false,
    isTabDevice: false,

    menuExpanded: false,
    setMenuExpanded: () => { },

});