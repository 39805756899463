export const ACTIVE_USER_FILTER = {
    DAILY: "Daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    YEARLY: "Yearly"
}

export const TOP_PODCAST_TYPE_FILTER = {
    LISTENS: "listens",
    SHARES: "shares",
    SUBSCRIBERS: "subscribers",
    INTERACTION: "interactions"
}

export const TOP_PODCAST_PERIOD_FILTER = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    YEAR: "year",
    ALL: ""
}