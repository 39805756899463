import axios from "axios"
import { toast } from "react-toastify";
import { ANALYTICS_ACTIONS } from "../const/analytics";

const AnalyticsService = {

    create: async (action: ANALYTICS_ACTIONS, episodeUId: string | undefined = undefined, podcastUId: string | undefined = undefined) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/analytics`, { action, episodeUId, podcastUId }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    getActiveUserChartData: async (flag: any) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/analytics/active-user?flag=${flag}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to load podcast active user data", { type: 'error' });
            return [];
        }
    },

    getTopPoscastData: async (type: string, period: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/analytics/top-podcast?flag=${period}&action=${type}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to load top podcast analytics data", { type: 'error' });
            return [];
        }
    },

    getPoscastAnalyticsData: async (uid: string, action: string, period: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/analytics/top-podcast/${uid}?flag=${period}&action=${action}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to load podcast analytics data", { type: 'error' });
            return [];
        }
    },

    getPoscastFeedAnalyticsData: async (uid: string, period: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/analytics/feed/${uid}?flag=${period}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to load podcast intereaction feed analytics data", { type: 'error' });
            return [];
        }
    },

    feedClickEvent: async (metaId: number) => {
        try {
            const response = await axios.post(`/api/v1/analytics/feed/click/event`, { metaId });
            return response.data;
        } catch (error) {
            console.error(error);
            toast("Failed to load podcast intereaction feed analytics data", { type: 'error' });
            return [];
        }
    }
}

export default AnalyticsService;