import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import logo from './../../assets/logo.svg';
import classes from './MainNavBar.module.scss';
import { useLocation, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { AuthContext } from '../../../../../contexts/AuthContext';
import menuHome from './../../../../../assets/images/home2.svg';
import menuPodcast from './../../../../../assets/images/menu/my-podcasts.png';
import menuDiscover from './../../../../../assets/images/menu/discover.png';
import menuSettings from './../../../../../assets/images/menu/settings.png';
import menutoggle from './../../../../../assets/images/menu.svg';
import CustomLink from '../../../_shared/CustomLink/CustomLink';
import { ResponsiveContext } from '../../../../../contexts/ResponsiveContext';
import LoginButton from '../../../_shared/LoginButton/LoginButton';


type Props = {
}

const MainNavBar = ({ children }: PropsWithChildren<Props>) => {

  const location = useLocation();

  const { authenticated } = React.useContext(AuthContext);
  const { menuExpanded, setMenuExpanded } = React.useContext(ResponsiveContext);

  return (
    <>
      <div className={`${classes.leftNavBar} ${menuExpanded ? 'menu-expanded' : ''}`} >
        <h6 className="text-uppercase">Menu</h6>
        <Nav defaultActiveKey="/home" className="flex-column flex-grow-1">
          {/* <CustomLink to="/" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuHome} loading="lazy" width="18" />
            </span>Home</CustomLink> */}
          {authenticated && (
            <CustomLink to="/my-podcasts" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuPodcast} loading="lazy" width="18" />
              </span>My Podcasts</CustomLink>
          )}

          <CustomLink to="/discover" activeClass={classes.leftNavItemActive}>
            <span className={classes.leftNavBarIcon}>
              <img src={menuDiscover} loading="lazy" width="18" />
            </span>Discover</CustomLink>

          {authenticated && (
            <CustomLink to="/settings" activeClass={classes.leftNavItemActive}>
              <span className={classes.leftNavBarIcon}>
                <img src={menuSettings} loading="lazy" width="18" />
              </span>Settings</CustomLink>
          )}

          <div className="mobile-only-menu">
            <CustomLink to="/about-faq" activeClass={classes.leftNavItemActive}>
              About/FAQ</CustomLink>
            <CustomLink to="/for-podcasters" activeClass={classes.leftNavItemActive}>
              For Podcasters</CustomLink>
            <CustomLink to="/contact" activeClass={classes.leftNavItemActive}>
              Contact</CustomLink>
            <CustomLink to="/blogs" activeClass={classes.leftNavItemActive}>
              Blogs</CustomLink>
            <CustomLink to="/legal" activeClass={classes.leftNavItemActive}>
              Legal</CustomLink>

            <LoginButton />

          </div>
        </Nav>

        <div className={`${classes.leftNavBarExpandIcon} mobile-menu-icon`}>
          <img src={menutoggle} loading="lazy" width="36" onClick={() => setMenuExpanded(!menuExpanded)} />
        </div>
      </div>
    </>
  );
}

export default MainNavBar;
