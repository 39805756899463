import { format, parse, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears } from "date-fns";

export const NumberFormatter = (number: number) => {
    if (number > 1000000000) {
        return (number / 1000000000).toString() + 'B';
    } else if (number > 1000000) {
        return (number / 1000000).toString() + 'M';
    } else if (number > 1000) {
        return (number / 1000).toString() + 'K';
    } else {
        return number.toString();
    }
}

export const getLastDays = (noOfDays: number) => {
    return [...Array(noOfDays)].map((_, i) => {
        return subDays(new Date(), i);
    }).reverse();
}

export const getLast5Weeks = () => {
    const startDateOfWeek = startOfWeek(new Date(), {
        weekStartsOn: 1
    });
    return [...Array(5)].map((_, i) => {
        return subWeeks(startDateOfWeek, i);
    }).reverse();
}

export const getLastMonths = (noOfMonths: number) => {
    const startDateOfMonth = startOfMonth(new Date());
    return [...Array(noOfMonths)].map((_, i) => {
        return subMonths(startDateOfMonth, i);
    }).reverse();
}

export const getLast11Years = () => {
    const startDateOfYear = startOfYear(new Date());
    return [...Array(11)].map((_, i) => {
        return subYears(startDateOfYear, i);
    }).reverse();
}

export const secondsToDuration = (sec: number) => {
    return new Date(sec * 1000).toISOString().substring(11, 19)
    // return sec < 3600 ? d.substring(11, 19) : d.substring(11, 19)
}

export const secondsToHms = (d: number) => {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? "min " : "min ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return hDisplay + mDisplay + ((hDisplay === "" && mDisplay === "") ? sDisplay : "");
}

export const formatDate = (dateString: string, inputDateFormat: string, outputDateFormat: string) => {
    // "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    // 'yyyy-MM-dd HH:mm:ss'
    const parsedDate = parse(dateString, inputDateFormat, new Date());
    return format(parsedDate, outputDateFormat);
}

export const float2DecimalPlaces = (num: number) => {
    return (Math.round(num * 100) / 100).toFixed(2)
}