import React from 'react';

export type seoMetaTypes = {
    title: string,
    description: string,
    image: string
}

export type ILoadingContext = {
    loader: boolean;
    showLoader: (show: boolean) => void;

    seoMeta: seoMetaTypes | undefined,
    setSeoMeta: (meta: seoMetaTypes | undefined) => void
};

export const LoadingContext = React.createContext<ILoadingContext>({
    loader: false,
    showLoader: () => { },

    seoMeta: { title: '', description: "", image: '' },
    setSeoMeta: () => { },
});