import { useCallback, useEffect, useState } from 'react';
import classes from './AdminPodcasts.module.scss';
import DataTable, { TableColumn } from 'react-data-table-component';
import PodcastService from '../../../../services/podcast.service';
import CustomLink from '../../../components/_shared/CustomLink/CustomLink';
import { debounce } from 'lodash';


const AdminPodcasts = () => {

    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const ImageComponent = (row: any) => (
        <>
            <div className='d-flex flex-row w-100'>
                <CustomLink to={`/admin/podcast/${row.id}`}>
                    <img src={row.imageUrl} width={80} loading="lazy" />
                </CustomLink>
            </div>
        </>
    );

    const columns: TableColumn<any>[] = [
        {
            name: '',
            cell: ImageComponent,
            width: 'auto',
            wrap: true
        },
        {
            name: 'Title',
            selector: (row: any) => `${row.title}`,
            width: 'auto',
            wrap: true
        },
        {
            name: 'Description',
            selector: (row: any) => `${row.description}`,
            width: 'auto',
            wrap: true
        }
    ]

    const fetchPodcasts = async (page: number, searchString?: string) => {
        setLoading(true);
        const response = await PodcastService.getAllPodcasts(page, searchString);
        setData(response.podcasts);
        setTotalRows(response.total);
        setLoading(false);
    };

    const handlePageChange = (page: number) => {
        fetchPodcasts(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        const response = await PodcastService.getAllPodcasts(page);
        setData(response.podcasts);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const changeHandler = (event: any) => {
        setQuery(event.target.value);
    };
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    useEffect(() => {
        fetchPodcasts(1, query);
    }, [query]);

    return (
        <>
            <div className={`interPageWhite ps-md-0 podcasts ${classes.podcasts}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column flex-grow-1">
                        <div className='d-flex mt-3 mt-md-5 mb-4'>
                            <h3 className="interBodyTitle Inactive">Podcasts</h3>
                            <input type="text"
                                onChange={debouncedChangeHandler}
                                className='form-control form-control--admin default w-50 ml-auto mr-2'
                                placeholder='Search Podcasts'
                            />
                        </div>
                        <div className="d-flex flex-column admin-table-podcast">
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                progressComponent={<>Loading...</>}
                                pagination
                                paginationServer
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AdminPodcasts;