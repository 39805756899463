import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import logo from './../../assets/logo.svg';
import classes from './MainLayout.module.scss';
import { AuthContext } from '../../../../contexts/AuthContext';
import Header from '../../_shared/Header/Header';
import Footer from '../../_shared/Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import MainNavBar from './MainNavBar/MainNavBar';
import Player from '../../_shared/Player/Player';
import { PlayerContext } from '../../../../contexts/PlayerContext';
import ReactPlayer from 'react-player';
import { debounce } from 'lodash';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LoadingContext } from '../../../../contexts/LoadingContext';

type Props = {
}

let prevScrollY = 0;

const MainLayout = () => {

  const location = useLocation();
  const [featuredLayout, setFeaturedLayout] = useState(false);

  const { isMobileDevice } = React.useContext(ResponsiveContext);
  const { isPlayerOpen } = React.useContext(PlayerContext);
  const { seoMeta } = React.useContext(LoadingContext);

  useEffect(() => {
    setFeaturedLayout(location.pathname !== '/');
  }, [location])

  return (
    <>
      <HelmetProvider>
        <>
          <Helmet>
            <title>{seoMeta && seoMeta.title}</title>
            <meta name="description" content={seoMeta && seoMeta.description} />

            <meta property="og:title" content={seoMeta && seoMeta.title} />
            <meta property="og:url" content={seoMeta && 'https://www.intercast.fm'} />
            <meta property="og:image" content={seoMeta && seoMeta.image} />
            <meta property="og:description" content={seoMeta && seoMeta.description} />

            <meta name="twitter:title" content={seoMeta && seoMeta.title} />
            <meta name="twitter:description" content={seoMeta && seoMeta.description} />
            <meta name="twitter:image" content={seoMeta && seoMeta.image} />
            <meta name="twitter:card" content={seoMeta && seoMeta.image} />

          </Helmet>
        </>
        <Header featuredHeader={featuredLayout} />
        <div className={classes.interMain}>
          {(featuredLayout || isMobileDevice) && (
            <MainNavBar />
          )}
          <div className={`${classes.interBody} MainWrapper`}>
            <Outlet />
          </div>
        </div>

        {isPlayerOpen && (<Player />)}
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default MainLayout;
