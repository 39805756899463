import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LoadingContext, seoMetaTypes } from '../contexts/LoadingContext';
import logo from '../assets/images/logo-sm.svg';

export type LoadingProviderProps = {
    showLoaderDefault?: boolean;
    children?: any;
    defaultSeoMeta?: seoMetaTypes | undefined
};

export const LoadingProvider = ({
    showLoaderDefault = false,
    defaultSeoMeta = {
        title: '',
        description: "",
        image: logo
    },
    children
}: LoadingProviderProps) => {

    const [loader, showLoader] = React.useState(showLoaderDefault);
    const [seoMeta, setSeoMeta] = React.useState<seoMetaTypes | undefined>(defaultSeoMeta);

    useEffect(() => {
        // if (seoMeta === null) {
        //     setSeoMeta(defaultSeoMeta)
        // }
    }, [seoMeta]);

    const contextValue = React.useMemo(
        () => ({
            loader, showLoader,
            seoMeta, setSeoMeta
        }),
        [loader, seoMeta]
    );

    return (
        <LoadingContext.Provider value={contextValue}> {children} </LoadingContext.Provider>
    );
};