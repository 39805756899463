import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import './index.scss';
import { LoadingProvider } from './providers/LoadingProvider';
import { ResponsiveProvider } from './providers/ResponsiveProvider';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import { ConnectedRouter } from "react-router-redux";
import { configureAppStore, history } from './store/store';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configureAppStore();

root.render(
  <>
    {/* <React.StrictMode>
      <PerfectScrollbar style={{ height: "100vh" }}> */}
    <Provider store={store}>
      <ConnectedRouter history={history} store={store}>
        <ResponsiveProvider>
          <LoadingProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LoadingProvider>
        </ResponsiveProvider>
      </ConnectedRouter>
    </Provider>
    {/* </PerfectScrollbar>
    </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
