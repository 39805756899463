import classes from './AdminDashboard.module.scss';
import sort from './../../../../assets/images/sort-icon.svg';
import ActiveUserChart from './ActiveUserChart/ActiveUserChart';
import { forwardRef, useContext, useEffect, useState } from 'react';
import AnalyticsService from '../../../../services/analytics.service';
import { Dropdown } from 'react-bootstrap';
import { ChartDataType } from '../../../../common/commonTypes';
import ChartDescription from '../../../components/_shared/ChartDescription/ChartDescription';
import AdminTopPodcast from './AdminTopPodcast/AdminTopPodcast';
import { ACTIVE_USER_FILTER } from '../../../../const/adminFilters';
import { LoadingContext } from '../../../../contexts/LoadingContext';

const AdminDashboard = () => {

    const [activeUserFilters] = useState([
        ACTIVE_USER_FILTER.DAILY,
        ACTIVE_USER_FILTER.WEEKLY,
        ACTIVE_USER_FILTER.MONTHLY,
        ACTIVE_USER_FILTER.YEARLY
    ]);
    const { showLoader } = useContext(LoadingContext);
    const [activeUserFilter, setActiveUserFilter] = useState(ACTIVE_USER_FILTER.DAILY);
    const [activeUserData, setActiveUserData] = useState([]);
    const [descriptionData, setDescriptionData] = useState<ChartDataType[]>([]);

    const fetchActiveUserData = async () => {
        showLoader(true);
        const data = await AnalyticsService.getActiveUserChartData(activeUserFilter.toLowerCase());
        setActiveUserData(data);
        showLoader(false);
    }

    const filterToggle = forwardRef(({ children, onClick }: any, ref: any) => (
        <>
            <div className='alignFlexCenter cursor-pointer' onClick={(e: any) => {
                e.preventDefault();
                onClick(e);
            }}>
                <img src={sort} className="me-2" />
                <span style={{ width: "75px", display: 'block' }}>{activeUserFilter}</span>
            </div>
        </>
    ));

    const afterDataSet = (chartData: ChartDataType[]) => {
        setDescriptionData(chartData);
    }

    useEffect(() => {
        fetchActiveUserData();
    }, [activeUserFilter]);

    return (
        <>
            <div className={`interPageWhite ps-md-0 ${classes.dashboard}`}>
                <div className="mx-w-80">
                    <h3 className="interBodyTitle Inactive mt-3 mt-md-5 mb-4">Dashboard</h3>
                    <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-gray-100">User Count</h5>
                        <div className="text-gray-100">
                            <Dropdown onSelect={(e: any) => setActiveUserFilter(e)}>
                                <Dropdown.Toggle as={filterToggle}></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {activeUserFilters.map((filter: string, index: number) => (
                                        <Dropdown.Item key={index} eventKey={filter}>
                                            <span className='uppercase'>{filter}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={classes.chartContainer}>
                        <ActiveUserChart flag={activeUserFilter} data={activeUserData} afterDataSet={afterDataSet} />
                    </div>

                    {descriptionData && descriptionData.length > 0 && <ChartDescription flag={activeUserFilter} list={descriptionData} />}

                    <AdminTopPodcast />
                </div>
            </div>
        </>
    )
};

export default AdminDashboard;