import React, { ReactElement, useContext, useEffect, useState } from 'react';
import classes from './Discover.module.scss';
import 'keen-slider/keen-slider.min.css';
import CollectionService from '../../../services/collection.service';
import CustomLink from './../../components/_shared/CustomLink/CustomLink';
import { LoadingContext } from '../../../contexts/LoadingContext';
import DiscoverCollection from './DiscoverCollection/DiscoverCollection';
import { useStoreHook } from '../../../store/hooks';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { GeneralActionTypes } from '../../../store/actions/generalAction';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../utils/utils';
import BackButton from '../../components/_shared/BackButton/BackButton';

type Props = {
}

const Discover = (): ReactElement => {

    const dispatch = useDispatch();
    const { authenticated } = useContext(AuthContext);
    const { showLoader, setSeoMeta } = useContext(LoadingContext);
    const [chartToppers, setChartToppers] = useState<any[]>([]);

    const { subscribedPodcastList } = useStoreHook();

    const fetchChartToppers = async () => {
        showLoader(true);
        const contents = await CollectionService.getAllChartTopper();
        setChartToppers(contents);
        showLoader(false);
    }

    const toggleSubscribe = async (podcast: any) => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }
        dispatch({ type: GeneralActionTypes.SUBSCRIBE_PODCAST, payload: podcast.id });
    }

    useEffect(() => {
        fetchChartToppers();
        // setSeoMeta({
        //     title: "Discovery",
        //     description: "",
        //     image: ""
        // });
    }, []);

    return (
        <>
            <div className={classes.discover}>
                <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">

                    <BackButton to='/' />
                    <DiscoverCollection />

                    {/* Popular Podcasts */}
                    {chartToppers.map((category: any, index: number) => (
                        <div key={index}>
                            {category.podcastList.length > 0 && (
                                <>
                                    <h3 className="interBodyTitle mb-4">Popular In {category.categoryName}</h3>
                                    <div className="row">
                                        {category.podcastList.map((podcast: any, j: number) => (
                                            <div className="col-lg-4 col-6" key={j}>
                                                <div className="interPodcastThumbnail">
                                                    <div>
                                                        <CustomLink to={`/podcast/${podcast.id}`}>
                                                            <div className='bg-white' style={{ borderRadius: "10px 10px 0 0" }}>
                                                                <img className="img-fluid" src={podcast.imageUrl} title={podcast.title} />
                                                            </div>
                                                        </CustomLink>
                                                        <div className={`interPodcastThumbnail--status ${!subscribedPodcastList[podcast.id] ? 'success' : null}`} onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            toggleSubscribe(podcast);
                                                        }}>{subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}</div>
                                                    </div>
                                                    <div className="interPodcastThumbnail--details">
                                                        <div className="d-flex mb-0 line-clamp-1">
                                                            <div className="interTextGray me-1">Last Episode: </div>
                                                            <div className="interTextSecondary">{formatDate(podcast.latestEpisodeDate, 'yyyy-MM-dd HH:mm:ss', 'MMM dd, yy')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>

            </div>
        </>
    )
};

export default Discover;