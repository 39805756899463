import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import classes from './Podcast.module.scss';
import EpisodeSection from '../../components/EpisodeSection/EpisodeSection';
import { useParams } from 'react-router-dom';
import PodcastStoreService from '../../../services/podcast-store.service';
// import { IPodcast } from '../../../interface/listen-notes';
import PodcastDetailSection from '../../components/PodcastDetailSection/PodcastDetailSection';
import PodcastService from '../../../services/podcast.service';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { debounce } from 'lodash';
import { LoadingContext } from '../../../contexts/LoadingContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { isAfter, parse } from 'date-fns';

const Podcast = (): ReactElement => {

    let { uid } = useParams();
    const prevIdRef = useRef<string>();
    const { authenticated } = useContext(AuthContext);
    const { isMobileDevice, isTabDevice } = useContext(ResponsiveContext);
    const { showLoader, setSeoMeta } = useContext(LoadingContext);

    const [podcast, setPodcast] = useState<any>({} as any);
    const [loading, setLoading] = useState(true);
    const [paginating, setPaginating] = useState(false);
    const [paginateData, setPaginateData] = useState<any>();
    const [episodes, setEpisodes] = useState<any[]>([]);
    const [isCompleted, setIsCompleted] = useState(false);

    const [currentTab, setCurrentTab] = useState(1);

    const [podcastInfo, setPodcastInfo] = useState<any>(null);
    const [bookmarkedEpisodes, setBookmarkedEpisodes] = useState<any[]>([]);

    const fetchPodcastUserInfo = async () => {
        if (authenticated) {
            const podcastUserInfo = await PodcastService.info(uid!);
            if (podcastUserInfo?.id) {
                setPodcastInfo(podcastUserInfo);
                processBookmarkedEpisodes(podcastUserInfo);
            }
        }
    }

    const fetchPodcast = async (paging?: boolean) => {
        if (paging) {
            showLoader(true);
            setPaginating(true);
        } else {
            await fetchPodcastUserInfo();
        }

        const podcastResponse = await PodcastStoreService.fetchPodcast(uid!, paginateData ? paginateData.currentPage + 1 : undefined);
        if (podcastResponse) {
            setPodcast(podcastResponse.data.podcast);
            // setSeoMeta({
            //     title: podcastResponse.data.podcast.title,
            //     description: podcastResponse.data.podcast.description,
            //     image: podcastResponse.data.podcast.imageUrl
            // });
            setPaginateData(podcastResponse.data.podcast.episodes.paginatorInfo);
            setEpisodes(paging ? [...episodes, ...podcastResponse.data.podcast.episodes.data] : podcastResponse.data.podcast.episodes.data);

            setLoading(false);
            setPaginating(false);
        }
        showLoader(false);
    }

    const processBookmarkedEpisodes = (podcastUserInfo: any) => {
        const bookmarkedEpisodes = podcastUserInfo.episodes.filter((e: any) => e.bookMarked).map((e: any) => e.episodeUId);
        setBookmarkedEpisodes(bookmarkedEpisodes);
    }

    const visitWebSite = (url: string) => {
        window.open(url);
    };

    const EpisodeListSection = () => {
        return (
            <>
                {episodes.map((episode: any, i: number) => (
                    <EpisodeSection key={episode.id}
                        episode={episode}
                        podcastEnrollment={podcastInfo}
                        podcastStoreData={podcast}
                        bookmarked={bookmarkedEpisodes.indexOf(episode.id) !== -1}
                        enrollment={(podcastInfo?.episodes.find((e: any) => e.episodeUId === episode.id)) || null}
                    />
                ))}
            </>
        )
    };

    const AboutSection = () => {
        return (
            <>
                <p className="interTextSecondary">
                    {podcast.description}
                </p>
                {/* <div className='d-flex flex-wrap'>
                    {podcast.categories.map((category: any, i: number) => <div key={i} className={`${classes.categoryBtn}`}>{category.title}</div>)}
                </div> */}
                {podcast.webUrl && (<button type="button" onClick={() => visitWebSite(podcast.webUrl)} className="btn btn-rounded w-xl-100 mt-4 btn-info">Visit Website</button>)}

            </>
        );
    };

    const checkMarkedAll = () => {
        const episodePublishedParsedDate = parse(podcast.latestEpisodeDate, 'yyyy-MM-dd HH:mm:ss', new Date());
        const markReadAllOnParsedDate = parse(podcastInfo.markReadAllOn, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
        const isAllMarked = isAfter(markReadAllOnParsedDate, episodePublishedParsedDate);
        setIsCompleted(isAllMarked);
        return isAllMarked;
    }

    useEffect(() => {
        if (podcastInfo && podcast) {
            checkMarkedAll();
        }
    }, [podcastInfo, podcast]);

    useEffect(() => {
        if (prevIdRef.current === uid) {
            return;
        }
        showLoader(true);
        setLoading(true);
        setPaginateData(undefined);
        fetchPodcast();
        prevIdRef.current = uid;
    }, [uid]);

    const onScrollCheck = () => {
        const cal = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        const d = debounce(async () => {
            if (parseInt(cal.toString()) === 0 && !loading && !paginating && paginateData && paginateData.hasMorePages) {
                await fetchPodcast(true);
            }
        }, 500);
        d();
    }

    const markAllAsPlayed = async () => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }

        showLoader(true);
        await PodcastService.markAsRead(uid!);
        await fetchPodcastUserInfo();
        showLoader(false);
    }

    useEffect(() => {
        if (authenticated) {
            fetchPodcastUserInfo();
        }
    }, [authenticated]);

    useEffect(() => {
        window.addEventListener("scroll", onScrollCheck);
        return () => {
            window.removeEventListener("scroll", onScrollCheck);
        };
    });

    return (
        <>
            {loading ? (
                <h5>Loading Podcast...</h5>
            ) : (
                <div className={classes.interPodcast}>
                    <div className="mx-w-80 mb-5">
                        <PodcastDetailSection podcast={podcast} />
                    </div>

                    {
                        ((!podcastInfo && podcast) || !isCompleted) && (
                            <div className={`d-flex flex-row-reverse mx-w-80 flex-grow-1 pe-xl-3`}>
                                <button className={`btn-rounded btn-h-32 mb-2 mb-md-0 ${classes.markAllPlayedButton}`} onClick={markAllAsPlayed}>
                                    Mark All As Played
                                </button>
                            </div>
                        )}

                    {(isMobileDevice || isTabDevice) && (
                        <>
                            <div className="d-flex mb-4">
                                <h3 className={`interBodyTitle mb-0 ${currentTab == 2 ? 'Inactive cursor-pointer' : 'cursor-pointercurrentTab'}`} onClick={() => setCurrentTab(1)}>Episodes</h3>
                                <div className="interSeparator mx-28"></div>
                                <h3 className={`interBodyTitle mb-0 ${currentTab == 1 ? 'Inactive cursor-pointer' : 'cursor-pointercurrentTab'}`} onClick={() => setCurrentTab(2)}>About</h3>
                            </div>
                        </>
                    )}

                    <div className={`d-flex ${!(isMobileDevice || isTabDevice) && 'flex-column-reverse'} flex-xl-row justify-content-between`}>
                        <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-3">
                            {!(isMobileDevice || isTabDevice) && <h3 className="interBodyTitle mb-4">Episodes</h3>}
                            {(!(isMobileDevice || isTabDevice) || currentTab == 1) && <EpisodeListSection />}
                        </div>
                        <div className={`mx-w-20 text-white ps-xl-3 mb-5 mb-lg-0 ${!(isMobileDevice || isTabDevice) && 'interStickySsidebar'}`}>
                            {!(isMobileDevice || isTabDevice) && <h3 className="interBodyTitle interBodyTitleSecondary mb-4">About</h3>}
                            {(!(isMobileDevice || isTabDevice) || currentTab == 2) && <AboutSection />}
                        </div>
                    </div>

                </div>
            )}
        </>
    )
};

export default Podcast;