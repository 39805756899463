import React, { useEffect } from 'react';
import { PlayerContext } from '../contexts/PlayerContext';

export type PlayerProviderProps = {
    playingDefault?: boolean;
    mutedDefault?: boolean;
    isPlayerOpenDefault?: boolean;
    currentPlayListDefault?: any[];
    currentEpisodeDefault?: any;
    children?: any;
};

export const PlayerProvider = ({
    playingDefault = true,
    mutedDefault = true,
    isPlayerOpenDefault = false,
    currentPlayListDefault = [],
    currentEpisodeDefault = null,
    children,
}: PlayerProviderProps) => {

    const [playing, setPlaying] = React.useState(playingDefault);
    const [muted, setMuted] = React.useState(mutedDefault);
    const [isPlayerOpen, setIsPlayerOpen] = React.useState(isPlayerOpenDefault);
    const [currentPlayList, setCurrentPlayList] = React.useState(currentPlayListDefault);
    const [currentEpisode, setCurrentEpisode] = React.useState(currentEpisodeDefault);

    const clearPlayer = () => {
        setPlaying(playingDefault);
        setIsPlayerOpen(isPlayerOpenDefault);
        setCurrentPlayList(currentPlayListDefault);
        setCurrentEpisode(currentEpisodeDefault);
    }

    React.useEffect(() => {
        if (currentPlayList.length > 0) {
            const ep = currentPlayList[0];
            setCurrentEpisode(ep);
            setIsPlayerOpen(true);
        }
    }, [currentPlayList]);

    const contextValue = React.useMemo(
        () => ({
            playing,
            setPlaying,
            muted,
            setMuted,
            isPlayerOpen,
            setIsPlayerOpen,
            currentPlayList,
            setCurrentPlayList,
            currentEpisode,
            setCurrentEpisode,
            clearPlayer
        }),
        [isPlayerOpen, currentPlayList, currentEpisode, playing, muted]
    );

    return (
        <PlayerContext.Provider value={contextValue}> {children} </PlayerContext.Provider>
    );
};