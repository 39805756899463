import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const { menuExpanded, setMenuExpanded } = useContext(ResponsiveContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        if(menuExpanded){
            setMenuExpanded(false);
        }

    }, [pathname]);

    return null;
}