import { applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware as createRouterMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { rootReducer, RootState } from "./reducers";

export const history: any = createBrowserHistory();
export const routerMiddleware = createRouterMiddleware(history);

export function configureAppStore(initialState?: RootState) {
    const middlewares = [routerMiddleware];
    const enhancer = compose(applyMiddleware(...middlewares));

    // create store
    return configureStore({
        reducer: rootReducer,
        enhancers: [enhancer]
    });
}