import { ReactElement, useState } from 'react';
import { NoticeProps, OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import classes from './SearchPanel.module.scss';
import { useNavigate } from 'react-router-dom';
import PodcastStoreService from '../../../../services/podcast-store.service';
// import { IListenNoteSearchResponse, ISearchedPodcast } from '../../../../interface/listen-notes';
import LABELS from '../../../../const/labels';
import { debounce } from 'lodash';

type Props = {
    defaultValue?: any,
    onSelectPodcast: (podcast: any) => void
}

const SearchPanel = (props: Props): ReactElement => {
    const { defaultValue, onSelectPodcast } = props;

    const [loadingValues, setLoadingValues] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const fetchSearchResults = (strSearch: string, callback: any) => {
        setLoadingValues(true);
        PodcastStoreService.searchPodcasts(strSearch).then((data: any) => {
            if (data) {
                callback(data.data.podcasts.data);
            }
            setLoadingValues(false);
        });
    }

    const loadSuggestions = debounce(fetchSearchResults, 500);


    const handleInputChange = (newValue: string) => {
        const inputValue = newValue?.trim();
        setMenuOpen(!!inputValue);
        return inputValue;
    };

    const select = (podcast: any) => {
        setMenuOpen(false);
        onSelectPodcast(podcast)
    }

    const Option = (props: OptionProps<any>) => {
        const { isDisabled, data, innerProps, selectProps } = props;
        if (selectProps.inputValue.length === 0) return null;

        return (
            !isDisabled ? (
                <div className="interSearch-item d-flex" {...innerProps} onClick={() => select(data)}>
                    <div>
                        <img className="mw-100 align-self-center" src={data.imageUrl} loading="lazy" width="90" height="90" />
                    </div>
                    <div className="ms-sm-4">
                        <h5>{data.title}</h5>
                        <p className="mb-0">{data.description}</p>
                    </div>
                </div>
            ) : null
        )
    }

    const NoOptionsMessage = (props: NoticeProps<any>) => {

        return (
            <components.NoOptionsMessage {...props} >
                {props.selectProps.inputValue.length === 0 ? LABELS.TYPE_TO_LOOK_UP_A_PODCAST : LABELS.NO_PODCAST_FOUND}
            </components.NoOptionsMessage>
        );
    };

    return (
        <>
            <div className={classes.interSearchPanel}>
                <AsyncSelect cacheOptions
                    isLoading={loadingValues}
                    loadOptions={loadSuggestions}
                    onInputChange={handleInputChange}
                    components={{
                        Option,
                        NoOptionsMessage
                    }}
                    defaultInputValue={defaultValue?.title_original}
                    defaultValue={defaultValue}
                    blurInputOnSelect={true}
                    autoFocus={false}
                    closeMenuOnSelect={true}
                    menuIsOpen={menuOpen}

                    placeholder={<div>{LABELS.LOOK_UP_A_PODCAST}</div>}
                    className='react-select-container'
                    classNamePrefix="react-select"
                    menuPlacement="auto" />
            </div>
        </>
    )
};

export default SearchPanel;