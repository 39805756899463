import classes from './Contact.module.scss';
import backIcon from './../../../assets/images/Expand_left_double.svg';
import BackButton from '../../components/_shared/BackButton/BackButton';

const Contact = () => {
    return (
        <>
            <div className={classes.contact}>
                <div className="d-flex flex-column flex-grow-1">
                    <BackButton to={`/`}  className="dark"/>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <h3 className="interBodyTitle mb-4">Contact Intercast.fm</h3>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-8 col-lg-6 text-white">
                            <p>Hi, I’m <a href="#" className="text-white">Dustin Steeve</a>. I’m the founder of Intercast.fm. I built this platform because I love podcasts and believe there’s so much more that podcasters and listeners (like me) can get from them. This platform is my way to test some ideas I have about that.  More on that over at the blog.</p>
                            <p>I’m a non-technical founder. Everything you see here was built by an incredible team I hired and paid for out of my own pocket.  As a result, I’m not able to provide technical support. </p>
                            <p>I am, however, interested to hear your feedback.</p>
                            <p>Please use the form below to send me a note. This is a side/passion project for me, but I’ll do my best to read and respond to your notes in a timely fashion.</p>
                            <p>Thank you for using Intercast.fm - I appreciate you!</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-8 col-lg-6">
                            <h4 className="text-white">Contact Form</h4>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-8 col-lg-6 placeholder-green">
                            <div className="mb-3">
                                <input className="form-control" type="text" placeholder="First Name" />
                            </div>

                            <div className="mb-3">
                                <input className="form-control" type="email" placeholder="Email (so I can respond)" />
                            </div>

                            <div className="mb-3">
                                <textarea className="form-control" placeholder="Your Feedback" rows={5}></textarea>
                            </div>

                            <div className="mb-3">
                                <button className="btn btn-secondary btn-sm">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Contact;