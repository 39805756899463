import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ResponsiveContext } from '../contexts/ResponsiveContext';

export type ResponsiveProviderProps = {
    scrollHeightDefault?: number;
    isMobileDeviceDefault?: boolean;
    isMenuExpandedDefault?: boolean;
    children?: any;
};

export interface IHeightObserverOptions {
    direction?: 'both' | 'horizontal' | 'vertical';
}

export function registerHeightObserver(element: HTMLElement, options: IHeightObserverOptions | (() => void), callback?: () => void) {
    let opts: IHeightObserverOptions;
    let cbk: () => void;
    if (!callback) {
        cbk = options as () => void;
        opts = {};
    } else {
        opts = options as IHeightObserverOptions;
        cbk = callback;
    }
    let iframe: HTMLIFrameElement = document.createElement('IFRAME') as HTMLIFrameElement;
    iframe.style.pointerEvents = 'none';
    // iframe.style.opacity = '0';
    iframe.style.position = 'absolute';
    iframe.style.display = 'block';
    iframe.style.overflow = 'auto';
    if (!opts || opts.direction === 'vertical') {
        iframe.style.height = '100%';
        iframe.style.width = '0';
    } else if (opts.direction === 'horizontal') {
        iframe.style.height = '0';
        iframe.style.width = '100%';
    } else {
        // both
        iframe.style.height = '100%';
        iframe.style.width = '100%';
    }
    iframe.style.top = '0';
    iframe.style.bottom = '0';
    iframe.style.left = '0';
    iframe.style.border = '0';
    iframe.style.backgroundColor = 'transparent';

    iframe.className = 'element-height-observer-iframe';
    iframe.onload = () => {
        iframe?.contentWindow?.addEventListener('resize', () => {
            cbk();
        });
    };
    element.appendChild(iframe);

    if (element.style.position === 'static' || element.style.position === '') {
        element.style.position = 'relative';
    }
}

export const ResponsiveProvider = ({
    scrollHeightDefault = 0,
    isMobileDeviceDefault = false,
    isMenuExpandedDefault = false,
    children
}: ResponsiveProviderProps) => {
    const isMobileDevice = useMediaQuery({
        query: "(max-width: 766px)",
    });
    const isTabDevice = useMediaQuery({
        query: "(max-width: 1199px)",
    });
    const [menuExpanded, setMenuExpanded] = React.useState(isMenuExpandedDefault);

    const contextValue = React.useMemo(
        () => ({
            isMobileDevice,
            isTabDevice,

            menuExpanded, setMenuExpanded
        }),
        [isMobileDevice, isTabDevice, menuExpanded]
    );

    return (
        <ResponsiveContext.Provider value={contextValue}> {children} </ResponsiveContext.Provider>
    );
};