import classes from './ForPodcasters.module.scss';
import backIcon from './../../../assets/images/Expand_left_double.svg';
import { useContext, useEffect, useState } from 'react';
import ContentService from '../../../services/content.service';
import CONTENT_TYPES from '../../../const/contentTypes';
import { LoadingContext } from '../../../contexts/LoadingContext';
import ReactHtmlParser from 'react-html-parser';
import BackButton from '../../components/_shared/BackButton/BackButton';

const ForPodcasters = () => {


    const { showLoader } = useContext(LoadingContext);
    const [loaded, setLoaded] = useState(false);

    const [forPodcaster, setForPodcaster] = useState("");

    const fetchContent = async () => {
        showLoader(true);
        const forPodcasterContent = await ContentService.getByType(CONTENT_TYPES.FOR_PODCASTER);

        setForPodcaster(forPodcasterContent ? forPodcasterContent.content : "")
        showLoader(false);
        setLoaded(true);
    }

    useEffect(() => {
        if (loaded) { return; }
        fetchContent()
    }, []);

    return (
        <>
            <div className={`interPageWhite ${classes.forPodcasters}`}>
                <div className="interPageInner">
                    <div className="d-flex flex-column mx-w-80 flex-grow-1 pe-xl-5">
                        <BackButton to='/'  className="dark"/>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="interBodyTitle active">For Podcasters</h3>
                            </div>
                            <div className="col-md-8 offset-lg-4">
                                <div className="row pt-4 dynamicContent">
                                    {ReactHtmlParser(forPodcaster)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ForPodcasters;