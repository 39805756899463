import axios from "axios"

const PodcastService = {

    // Get podcast info
    info: async (podcastUId: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/podcast/${podcastUId}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },


    // subscribe and un-subscribe podcast
    subscribe: async (podcastUId: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/podcast/subscribe`, { podcastUId }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    // get all podcasts
    getAllPodcasts: async (page: number, query?: string) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        };
        const response = await axios.get(`/api/v1/podcast?page=${page}` + (query ? `&query=${query}` : ''), config);
        return response.data;
    },

    // get subscribed podcasts
    getSubscribedPodcasts: async (page: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/podcast/subscribe?page=${page}`, config);
            return response.data;
        } catch (error) {
            return [];
        }
    },

    // get unplayed subscribed podcasts
    getSubscribedUnplayedPodcasts: async (page: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/podcast/unplayed?page=${page}`, config);
            return response.data;
        } catch (error) {
            return [];
        }
    },

    // Get logged in user enrollments
    getLoggedUserPodcastEnrollments: async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/podcast/sub-pod-details`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return {
                favouriteEpisodeList: [],
                subscribedPodcastList: [],
            };
        }
    },

    markAsRead: async (podcastUid: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/podcast/mark-as-read/${podcastUid}`, {}, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

}

export default PodcastService;