import { handleActions, Action } from "redux-actions";
import EpisodeService from "../../services/episode.service";
import PodcastService from "../../services/podcast.service";
import * as GeneralStoreActions from "../actions/generalAction";
import { MakeFavouriteEpisodePayload } from "./payloadTypes";

export type State = {
    subscribedPodcasts: { [key: string]: boolean },
    favouriteEpisodes: { [key: string]: boolean },
    loggedUser: any
}

const initialState: State = {
    subscribedPodcasts: {},
    favouriteEpisodes: {},
    loggedUser: null
}

// export const generalReducer = () => {

// }

export const generalReducer = handleActions<State, any>(
    {
        [GeneralStoreActions.GeneralActionTypes.SET_SUBSCRIBED_PODCASTS]: (state: State, action: Action<{ [key: string]: boolean }>) => (
            {
                ...state, subscribedPodcasts: action.payload
            }
        ),
        [GeneralStoreActions.GeneralActionTypes.SET_FAVOURITE_EPISODES]: (state: State, action: Action<{ [key: string]: boolean }>) => (
            {
                ...state, favouriteEpisodes: action.payload
            }
        ),
        [GeneralStoreActions.GeneralActionTypes.MAKE_FAVOURITE_EPISODE]: (state: State, action: Action<MakeFavouriteEpisodePayload>) => {
            const favouriteEpisodeList = { ...state.favouriteEpisodes };
            EpisodeService.favourite(action.payload).then();
            if (action.payload.episodeUid in favouriteEpisodeList) {
                favouriteEpisodeList[action.payload.episodeUid] = !favouriteEpisodeList[action.payload.episodeUid];
            } else {
                favouriteEpisodeList[action.payload.episodeUid] = true;
            }
            return {
                ...state, favouriteEpisodes: favouriteEpisodeList
            }
        },
        [GeneralStoreActions.GeneralActionTypes.SUBSCRIBE_PODCAST]: (state: State, action: Action<string>) => {
            const subscribedPodcastList = { ...state.subscribedPodcasts };
            PodcastService.subscribe(action.payload).then();
            if (action.payload in subscribedPodcastList) {
                subscribedPodcastList[action.payload] = !subscribedPodcastList[action.payload];
            } else {
                subscribedPodcastList[action.payload] = true;
            }
            return {
                ...state, subscribedPodcasts: subscribedPodcastList
            }
        },
        [GeneralStoreActions.GeneralActionTypes.SET_LOGGED_USER]: (state: State, action: Action<any>) => {
            return {
                ...state, loggedUser: action.payload
            }
        }
    },
    initialState
);