import axios from "axios";

let loadingFeeds = false;

const EpisodeService = {

    // favourite and un-favourite episode
    favourite: async (data: { episodeUid: string, duration: number, podcastUid: string }) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/episode/bookmark/${data.episodeUid}`, {
                episodeUid: data.episodeUid,
                duration: data.duration,
                podcastUid: data.podcastUid
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },


    // Fetch episode data
    fetchEpisodeData: async (uid: string) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.get(`/api/v1/episode/${uid}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    // Fetch interactive feeds
    fetchFeedData: async (urls: string[], podcastId: string, episodeId: string) => {
        try {
            if (loadingFeeds) { return; }
            loadingFeeds = true;
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/episode/feed`, {
                urls,
                podcastId,
                episodeId
            }, config);
            loadingFeeds = false;
            return response.data;
        } catch (error) {
            console.error(error);
            loadingFeeds = false;
            return false;
        }
    },

    // Enroll
    enroll: async (episodeData: { episodeUId: string, episodeLength: number, podcastUId: string }) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/episode/enroll/${episodeData.episodeUId}`, {
                duration: episodeData.episodeLength, podcastUId: episodeData.podcastUId
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    trackTime: async (episodeUId: string, duration: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.put(`/api/v1/episode/play/${episodeUId}`, {
                duration: parseInt(duration.toString())
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    markAsRead: async (episodeUId: string, duration: number) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };
            const response = await axios.post(`/api/v1/episode/mark-as-read/${episodeUId}`, {
                duration: parseInt(duration.toString())
            }, config);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
}

export default EpisodeService;