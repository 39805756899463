import { ReactElement, useContext, useEffect, useState } from 'react';
import classes from './DiscoverCollection.module.scss';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import CollectionService from '../../../../services/collection.service';
import CustomLink from './../../../components/_shared/CustomLink/CustomLink';
import { LoadingContext } from '../../../../contexts/LoadingContext';
import { useStoreHook } from '../../../../store/hooks';
import { AuthContext } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { GeneralActionTypes } from '../../../../store/actions/generalAction';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../utils/utils';

type Props = {
}

const DiscoverCollection = (): ReactElement => {
    const dispatch = useDispatch();
    const { showLoader } = useContext(LoadingContext);
    const { authenticated } = useContext(AuthContext);
    const { subscribedPodcastList } = useStoreHook();

    const [currentSlide, setCurrentSlide] = useState(0);
    const [collections, setCollections] = useState<any[]>([]);
    const [otherCollections, setOtherCollections] = useState<any[]>([]);
    const [collectionsLoading, setCollectionsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        loop: true
    }, [
        (slider) => {
            let timeout: any;
            let mouseOver = false
            function clearNextTimeout() {
                clearTimeout(timeout)
            }
            function nextTimeout() {
                clearTimeout(timeout)
                if (mouseOver || (slider && slider.slides.length < 2)) return
                timeout = setTimeout(() => {
                    slider.next()
                }, 4000)
            }
            slider.on("created", () => {
                slider.container.addEventListener("mouseover", () => {
                    mouseOver = true
                    clearNextTimeout()
                })
                slider.container.addEventListener("mouseout", () => {
                    mouseOver = false
                    nextTimeout()
                })
                nextTimeout()
            })
            slider.on("dragStarted", clearNextTimeout)
            slider.on("animationEnded", nextTimeout)
            slider.on("updated", nextTimeout)
        }
    ])

    const fetchCollections = async () => {
        const res: any[] = await CollectionService.getAll();
        setOtherCollections(res.filter(c => !c.isFeatured));
        setCollections(res.filter(c => c.isFeatured));
        setCollectionsLoading(false);
        showLoader(false);
    }

    const toggleSubscribe = async (podcast: any) => {
        if (!authenticated) {
            toast("Please login to perform this action!", { type: 'error' });
            return;
        }
        dispatch({ type: GeneralActionTypes.SUBSCRIBE_PODCAST, payload: podcast.id });
    }

    useEffect(() => {
        showLoader(true);
        fetchCollections();
    }, []);

    return (
        <>
            {!collectionsLoading && (
                <div ref={sliderRef} className="keen-slider">
                    {collections.map((collection: any, index: number) => (
                        <div className="keen-slider__slide" key={index}>
                            <div className='interSlider'>
                                <div className="row">
                                    <div className="col-6 col-lg-4  mb-2 mb-md-0">
                                        <div className="interSlider--item">
                                            <h3>{collection.title}</h3>
                                        </div>
                                    </div>
                                    {collection.podcastList.map((podcast: any, i: number) => (
                                        <div key={i} className="col-6 col-lg-4">
                                            <div className="interPodcastThumbnail mb-12">
                                                <CustomLink to={`/podcast/${podcast.id}`}>
                                                    <div className='bg-white' style={{ borderRadius: "10px" }}>
                                                        <img className="img-fluid rounded" src={podcast.imageUrl} loading="lazy" title={podcast.title} />
                                                    </div>
                                                </CustomLink>
                                                <div className={`interPodcastThumbnail--status ${!subscribedPodcastList[podcast.id] ? 'success' : null}`} onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    toggleSubscribe(podcast);
                                                }}>{subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            )}
            <div className='mb-5'>
                {!collectionsLoading && loaded && collections.length > 0 && instanceRef.current && (
                    <div className={classes.dots}>
                        {[...Array(instanceRef.current.track.details.slides.length).keys(),].map((idx) => {
                            return (
                                <button
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx)
                                    }}
                                    className={"dot" + (currentSlide === idx ? " active" : "")}
                                ></button>
                            )
                        })}
                    </div>
                )}
            </div>

            {otherCollections.map((collection: any, index: number) => (
                <div key={index}>
                    {collection.podcastList.length > 0 && (
                        <>
                            <h3 className="interBodyTitle mb-4">{collection.title}</h3>
                            <div className="row">
                                {collection.podcastList.map((podcast: any, j: number) => (
                                    <div className="col-lg-4 col-6" key={j}>
                                        <div className="interPodcastThumbnail">
                                            <div>
                                                <CustomLink to={`/podcast/${podcast.id}`}>
                                                    <div className='bg-white' style={{ borderRadius: "10px 10px 0 0" }}>
                                                        <img className="img-fluid" src={podcast.imageUrl} title={podcast.title} />
                                                    </div>
                                                </CustomLink>
                                                <div className={`interPodcastThumbnail--status ${!subscribedPodcastList[podcast.id] ? 'success' : null}`} onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    toggleSubscribe(podcast);
                                                }}>{subscribedPodcastList[podcast.id] ? 'Unsubscribe' : 'Subscribe'}</div>
                                            </div>
                                            <div className="interPodcastThumbnail--details">
                                                <div className="d-flex mb-0 line-clamp-1">
                                                    <div className="interTextGray me-1">Last Episode: </div>
                                                    <div className="interTextSecondary">{formatDate(podcast.latestEpisodeDate, 'yyyy-MM-dd HH:mm:ss', 'MMM dd, yy')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ))}

        </>
    )
};

export default DiscoverCollection;